import imageCompression from 'browser-image-compression';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { useEffect, useRef } from 'react';
import { storage } from '../firebase/firebase';

export function getUUID(myStrong?: any) {
    let strong = 1000;
    if (myStrong) strong = myStrong;
    return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
}

export function getBrowserUid(): string {
    const strong = 10000;
    return (
        Math.floor(strong * Math.random()).toString(16) +
        '-' +
        new Date().getTime().toString(16) +
        '-' +
        Math.floor(strong * Math.random()).toString(16)
    );
}

export function usePrevious(value: any) {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function formatDate(date: any, format: string) {
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
}

export function getCurrentTabNameFromLocation(value: string) {
    const dir = value.split('/');
    const dir0 = dir[1];
    return dir0;
}

// 画像のアップロード関数
export async function uploadImageToFirebaseStorage(file: any, areaId: number, type: 'contests' | 'posts') {
    const filename = getUUID();

    // オリジナル画像のアップロード
    const optionsOriginal = {
        maxSizeMB: 5, // 最大ファイルサイズ
        maxWidthOrHeight: 4000 // 最大画像幅もしくは高さ
    };
    const fileOriginal = await imageCompression(file, optionsOriginal);
    const storageRefOriginal = ref(storage, areaId + '/' + type + '/images/' + filename + '__original');
    await uploadBytes(storageRefOriginal, fileOriginal).then((snapshot) => {
        console.log('Uploaded a original image!');
    });

    // 圧縮画像のアップロード
    const optionsCompressed = {
        maxSizeMB: 0.1, // 最大ファイルサイズ
        maxWidthOrHeight: 2000 // 最大画像幅もしくは高さ
    };
    const fileCompressed = await imageCompression(file, optionsCompressed);
    const storageRefCompressed = ref(storage, areaId + '/' + type + '/images/' + filename + '__compressed');
    await uploadBytes(storageRefCompressed, fileCompressed).then((snapshot) => {
        console.log('Uploaded a compressed image!');
    });

    // 圧縮の画像urlをDBに格納する
    const fileUrl = await getDownloadURL(storageRefCompressed);

    return fileUrl;
}

// 動画のアップロード関数
export async function uploadVideoToFirebaseStorage(file: any, areaId: number, type: 'contests' | 'posts') {
    const filename = getUUID();

    const storageRefOriginal = ref(storage, areaId + '/' + type + '/videos/' + filename);
    // 300MB近くの大きいサイズのアップロードがローカルで413 too largeのエラーが出るのでuploadBytesResumableを使用する
    // https://stackoverflow.com/questions/70748674/firebase-admin-upload-payloadtoolargeerror-request-entity-too-large
    await uploadBytesResumable(storageRefOriginal, file).then((snapshot) => {
        console.log('Uploaded a original video!');
    });

    // 動画urlをDBに格納する（圧縮はfunctionsで行う）
    const fileUrl = await getDownloadURL(storageRefOriginal);

    return fileUrl;
}

export function isValidFileExtension(filename: string, allowedExtensions: string): boolean {
    const extensions = allowedExtensions.split(', ').map((ext) => ext.trim());
    const fileExtension = getFileExtension(filename.toLowerCase());
    return extensions.includes('.' + fileExtension);
}

export function getFileExtension(filename: string): string {
    const parts = filename.split('.');
    if (parts.length > 1) {
        return parts[parts.length - 1];
    }
    return ''; // 拡張子がない場合は空文字列を返す
}
