import React, { useEffect, useState } from 'react';
import {
    VStack,
    FormControl,
    Input,
    Center,
    Button,
    Heading,
    ScrollView,
    Select,
    CheckIcon,
    WarningOutlineIcon,
    Box
} from 'native-base';
import { uploadMediaToYouTube } from '../api/uploadAPI';
import { db, storage } from '../firebase/firebase';
import { collection, getDocs } from 'firebase/firestore/lite';
import imageCompression from 'browser-image-compression';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function BuildingAFormExample() {
    useEffect(() => {
        (async () => {
            // Get a list of cities from your database
            const citiesCol = collection(db, 'cities');
            const citySnapshot = await getDocs(citiesCol);
            const cityList = citySnapshot.docs.map((doc) => doc.data());
            console.log(cityList);
        })();
    });

    const [formData, setData] = useState({ name: '', business_type: '' } as any);
    const [errors, setErrors] = useState({} as any);
    const [upFile, setUPFile] = useState<File>();

    const validate = () => {
        let errorObject = {};

        if (formData.name === undefined) {
            errorObject = { ...errorObject, name: 'Name is required' };
        } else if (formData.name.length < 3) {
            errorObject = { ...errorObject, name: 'Name is too short' };
        }

        if (formData.business_type === '') {
            errorObject = { ...errorObject, business_type: 'business_type is required' };
        }

        // console.log(errorObject);
        if (Object.keys(errorObject).length) {
            setErrors({ ...errorObject });
            return false;
        }

        setErrors({});
        return true;
    };

    const onSubmit = async () => {
        validate() ? console.log('Submitted') : console.log('Validation Failed');
        //uploadMediaToYouTube('ya29.A0ARrdaM8j8apcO6MqgNY1aW5rUtSRNezfmqUotBuWsUsVRW1MLLquyGVOVaoRHijfIDYw7w31JD1UGDg9tTyNhNUDrifYrwhpSYGItUm1yQIsAQJzB8PmLsojnWFnrOJVq-B5SrOjQ0q8CoO1wjkC1NsbTAXe', '../assets/video.mp4',)

        if (upFile !== undefined) {
            const options = {
                maxSizeMB: 0.1, // 最大ファイルサイズ
                maxWidthOrHeight: 2000 // 最大画像幅もしくは高さ
            };

            const file = await imageCompression(upFile, options);

            const storageRef = ref(storage, 'image/test/' + upFile.name);

            await uploadBytes(storageRef, file).then((snapshot) => {
                console.log('Uploaded a blob or file!');
            });

            const aaa = getDownloadURL(storageRef);
            console.log(aaa);
        }
    };

    const handleImage = (event: any) => {
        setUPFile(event.target.files?.[0]);
    };

    return (
        <Box padding={5} maxW="600">
            <Heading size="2xl" padding={10}>
                {'2022年度上期POP・メニュー部門　5/31㈫まで応募受付'}
            </Heading>
            <FormControl isRequired isInvalid={'name' in errors}>
                <FormControl.Label _text={{ bold: true }}>店名</FormControl.Label>
                <Input placeholder="John" onChangeText={(value) => setData({ ...formData, name: value })} />
                {'name' in errors ? (
                    <FormControl.ErrorMessage>{errors.name}</FormControl.ErrorMessage>
                ) : (
                    <FormControl.HelperText>Name should contain atleast 3 character.</FormControl.HelperText>
                )}
            </FormControl>

            <FormControl w="3/4" maxW="300" isRequired isInvalid={'business_type' in errors}>
                <FormControl.Label _text={{ bold: true }}>業種</FormControl.Label>
                <Select
                    minWidth="200"
                    accessibilityLabel="Choose Service"
                    placeholder="Choose Service"
                    _selectedItem={{
                        bg: 'teal.600',
                        endIcon: <CheckIcon size={5} />
                    }}
                    mt="1"
                    onValueChange={(value) => setData({ ...formData, business_type: value })}
                >
                    <Select.Item label="UX Research" value="ux" />
                    <Select.Item label="Web Development" value="web" />
                    <Select.Item label="Cross Platform Development" value="cross" />
                    <Select.Item label="UI Designing" value="ui" />
                    <Select.Item label="Backend Development" value="backend" />
                </Select>
                {'business_type' in errors ? (
                    <FormControl.ErrorMessage>{errors.business_type}</FormControl.ErrorMessage>
                ) : (
                    <FormControl.HelperText>Please make a selection!</FormControl.HelperText>
                )}
            </FormControl>

            <FormControl isRequired isInvalid={'name' in errors}>
                <FormControl.Label _text={{ bold: true }}>ファイル</FormControl.Label>

                <input type="file" onChange={handleImage} />
            </FormControl>

            <Button onPress={onSubmit} mt="5" colorScheme="cyan">
                Submit
            </Button>
        </Box>
    );
}

export default function TabTwoScreen() {
    return (
        <>
            <Center flex={1}>
                <BuildingAFormExample />
            </Center>
        </>
    );
}
