import { Center, Heading, Box, AspectRatio, Image, Stack, Text, HStack, ScrollView } from 'native-base';
import { Pressable } from 'react-native';
import { useNavigate } from '../router/react-router';
import Card from '../stories/components/Cards/Card';

export default function Screen(props: any) {
    const navigate = useNavigate();
    const goToDetail = () => navigate('/form');

    return (
        <Center flex={1}>
            <ScrollView padding={2}>
                <Heading size="2xl" padding={5}>
                    開催中のコンテスト
                </Heading>
                <Pressable onPress={goToDetail} style={{ marginBottom: 20 }}>
                    <Card
                        title="2022年度VPコンテスト　2022年度上期POP・メニュー部門"
                        peoriod="5/31㈫まで応募受付"
                        description="受付締切：2022年05月31日（火）23:59"
                    />
                </Pressable>
                <Pressable onPress={goToDetail}>
                    <Card
                        title="2022年度VPコンテスト　2022年度上期ディスプレイ部門"
                        peoriod="5/31㈫まで応募受付"
                        description="受付締切：2022年05月31日（火）23:59"
                    />
                </Pressable>
            </ScrollView>
        </Center>
    );
}
