/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Link, useNavigate, useLocation } from '../router/react-router';

import { StyleSheet, StyleProp, Image } from 'react-native';
import { Center, Box, HStack, Pressable, ScrollView } from 'native-base';
import _ from 'lodash';
import { GlobalContext } from '../contexts/Global';
import Zindex from '../constants/Zindex';
import { getCurrentTabNameFromLocation } from '../utils/utils';
import PostModals from '../components/PostModals';
import { Platform } from 'react-native';
import { useThemeColors, useThemeImages } from '../components/Themed';
import { ColorNames, ImageNames } from '../types';

export default function Footer() {
    const Colors: ColorNames = useThemeColors();
    const Images: ImageNames = useThemeImages();

    const footerHeight = '60px';

    const { setKeyword, setInputWord } = useContext(GlobalContext);

    const image: StyleProp<any> = {
        width: 60,
        height: 60,
        resizeMode: 'cover'
    };

    return (
        <>
            <Pressable
                {...Platform.select({
                    web: {
                        cursor: 'pointer'
                    }
                })}
                py="1"
                flex={1}
                onPress={() => {
                    setKeyword('');
                    setInputWord('');
                }}
            >
                <Link to="/posts" style={{ textDecorationLine: 'none' } as any}>
                    <Center>
                        <Image
                            style={image}
                            source={
                                getCurrentTabNameFromLocation(location.pathname) === 'posts'
                                    ? Images.homeIconSelected.uri
                                    : Images.homeIcon.uri
                            }
                        />
                    </Center>
                </Link>
            </Pressable>
            <Pressable
                {...Platform.select({
                    web: {
                        cursor: 'pointer'
                    }
                })}
                py="2"
                flex={1}
            >
                <Link to="/rank" style={{ textDecorationLine: 'none' } as any}>
                    <Center>
                        <Image
                            style={image}
                            source={
                                getCurrentTabNameFromLocation(location.pathname) === 'rank'
                                    ? Images.rankingIconSelected.uri
                                    : Images.rankingIcon.uri
                            }
                        />
                    </Center>
                </Link>
            </Pressable>
            <Pressable
                {...Platform.select({
                    web: {
                        cursor: 'pointer'
                    }
                })}
                py="2"
                flex={1}
            >
                <Link to="/results" style={{ textDecorationLine: 'none' } as any}>
                    <Center>
                        <Image
                            style={image}
                            source={
                                getCurrentTabNameFromLocation(location.pathname) === 'results'
                                    ? Images.resultIconSelected.uri
                                    : Images.resultIcon.uri
                            }
                        />
                    </Center>
                </Link>
            </Pressable>
            <Pressable
                {...Platform.select({
                    web: {
                        cursor: 'pointer'
                    }
                })}
                py="2"
                flex={1}
            >
                <Link to="/recommend" style={{ textDecorationLine: 'none' } as any}>
                    <Center>
                        <Image
                            style={image}
                            source={
                                getCurrentTabNameFromLocation(location.pathname) === 'recommend'
                                    ? Images.recommendIconSelected.uri
                                    : Images.recommendIcon.uri
                            }
                        />
                    </Center>
                </Link>
            </Pressable>
            <PostModals />
        </>
    );
}
