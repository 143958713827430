import { useMediaQuery } from 'native-base';
import React, { Component } from 'react';
import { useEffect } from 'react';
import { TouchableOpacity, StyleSheet, View, Text, Image } from 'react-native';
import { useNavigate, useLocation } from '../router/react-router';

export default function ImageMain(props: any) {
    const [isTabScreen, isPcScreen] = useMediaQuery([
        {
            minWidth: 700,
            maxWidth: 999
        },
        {
            minWidth: 1000
        }
    ]);
    const imageInfo = props.imageInfo;
    const navigate = useNavigate();
    const goToDetail = () => navigate(`/posts/${imageInfo.id}`);

    return (
        <TouchableOpacity
            style={{
                padding: 1,
                paddingTop: 0,
                height: isPcScreen ? '250px' : isTabScreen ? '200px' : '187px',
                width: isPcScreen ? '25%' : isTabScreen ? '33.33%' : '50%'
            }}
            onPress={goToDetail}
        >
            <Image
                // [TODO] 画像がない時の表示
                source={{
                    uri: imageInfo.files[0]
                        ? imageInfo.files[0].fileType === 'images'
                            ? imageInfo.files[0].url
                            : imageInfo.files[0].url.split('?')[0] + '__thumbnail.jpg?alt=media'
                        : 'https://placehold.jp/e3e3e3/ffffff/300x300.png?text=no%20image'
                }}
                style={styles.imageStyle}
            />
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    imageStyle: {
        flex: 1
    }
});
