import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Text, Pressable } from 'native-base';
import Modal from 'react-native-modal';
import _ from 'lodash';
import PostCompleted from '../stories/components/Modal/PostCompleted';
import ProgressBar from '../stories/components/Bar/ProgressBar';
import Title from '../stories/components/ButtonNative/Title';
import FontSize from '../constants/FontSize';
import { Link, useNavigate } from '../router/react-router';
import { useThemeColors, useThemeImages } from './Themed';
import { ColorNames, ImageNames } from '../types';

const Colors: ColorNames = useThemeColors();
const Images: ImageNames = useThemeImages();

export default function PostDeleteResultModals(props: any) {
    const navigate = useNavigate();

    const onPressDetelePost = () => {
        props.setEditModal(false);
        props.setModalError(false);
        props.setDeleteModal(false);
        props.setDeleteModalComplete(false);
        navigate('/posts');
    };

    return (
        <>
            <Modal
                isVisible={props.deleteModalComplete}
                animationIn="zoomIn"
                animationOut="slideOutDown"
                style={{ margin: 0, flex: 1, width: '100%', height: '100%' }}
            >
                <Pressable flex={1} onPress={() => onPressDetelePost()}>
                    <View style={styles.modalPost}>
                        {ProgressBar({ progressWidth: 100 })}
                        {Title({
                            text: '削除完了しました！',
                            fontSize: FontSize.large,
                            fontWeight: '600'
                        })}
                        {PostCompleted({ source: Images.postCompleted.uri, text: '', button: true })}
                    </View>
                </Pressable>
            </Modal>
            <Modal
                isVisible={props.modalError}
                animationIn="slideInUp"
                animationOut="slideOutDown"
                style={{
                    justifyContent: 'flex-end',
                    margin: 0
                }}
                onBackdropPress={() => props.setModalError(false)}
            >
                <View
                    style={{
                        backgroundColor: Colors.white,
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20
                    }}
                >
                    <Text style={{ margin: 20, paddingHorizontal: 10 }}>{props.postErrorMessage}</Text>
                </View>
            </Modal>
        </>
    );
}

const styles = StyleSheet.create({
    modalPost: {
        flex: 1,
        backgroundColor: Colors.white,
        marginTop: 59,
        paddingTop: 47,
        paddingHorizontal: '5%',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    modalComplete: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});
