import { HStack } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const ProgressBar = (props: any) => {
    const { progressWidth } = props;
    const Colors: ColorNames = useThemeColors();

    return (
        <HStack bg={Colors.lightGray} position="relative" width="100%" height="6px" borderRadius="40px">
            <HStack
                bg={Colors.primaryGradientStart}
                position="relative"
                width={`${progressWidth}%`}
                height="100%"
                borderRadius="40px"
            >
                <LinearGradient
                    colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                    start={[0, 0]}
                    end={[1, 0]}
                    style={{
                        width: '100%',
                        borderRadius: 40
                    }}
                />
            </HStack>
        </HStack>
    );
};

export default ProgressBar;
