import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, View, Text, Image, Button } from 'react-native';
import { HStack, Pressable, Center, Input } from 'native-base';
import { Link, useNavigate } from '../router/react-router';
import { GlobalContext } from '../contexts/Global';
import SearchArea from '../stories/components/Drawer/SearchArea';
import Zindex from '../constants/Zindex';
import DrawerMenu from '../stories/components/Drawer/DrawerMenu';
import { AuthContext } from '../contexts/Auth';
import { useThemeColors, useThemedAjusts } from './Themed';
import { ColorNames, ThemedAjustType } from '../types';

const Colors: ColorNames = useThemeColors();
const Ajusts: ThemedAjustType = useThemedAjusts();

export default function CustomMenuModal({ menuModalVisible, setMenuModalVisible, currentTabName }: any) {
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);

    return (
        <>
            {menuModalVisible ? (
                <View style={styles.modalWapper}>
                    {/* backdrop */}
                    <Pressable onPress={() => setMenuModalVisible(false)}>
                        <View style={styles.backdrop} />
                    </Pressable>
                    {/* modal contents */}
                    <DrawerMenu
                        menus={[
                            { title: 'ホーム', path: 'posts' },
                            { title: 'ランキング', path: 'rank', style: Ajusts.Display },
                            { title: '結果発表', path: 'results' },
                            { title: Ajusts.MenuTxt, path: 'recommend', style: Ajusts.RecommendDisplay }
                        ]}
                        menuModalVisible={menuModalVisible}
                        setMenuModalVisible={setMenuModalVisible}
                        logout={logout}
                        currentTabName={currentTabName}
                        onPress={navigate}
                    />
                </View>
            ) : null}
        </>
    );
}

const styles = StyleSheet.create({
    modalWapper: {
        top: Ajusts.DrawerTop,
        zIndex: Zindex.searchModal,
        position: 'absolute',
        flex: 1,
        width: '100%'
    },
    backdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: Colors.darkGray,
        opacity: 0.5,
        flex: 1,
        width: '100vw',
        height: '100vh',
        zindex: Zindex.searchModalBackDrop
    }
});
