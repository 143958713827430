import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Pressable, Image, HStack, Center, Box, ScrollView, useMediaQuery, Spinner } from 'native-base';
import Swiper from '../components/customSwiper';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from '../router/react-router';
import { useNavigate } from '../router/react-router';
import axios from '../axios/axios';
import Label from '../stories/components/Label/Label';
import ImageMain from '../components/ImageMain';
import FontSize from '../constants/FontSize';
import LikeButton from '../stories/components/ButtonNative/LikeButton';
import { GlobalContext } from '../contexts/Global';
import { formatDate } from '../utils/utils';
import { AuthContext } from '../contexts/Auth';
import { useThemeColors } from '../components/Themed';
import { ColorNames } from '../types';
import VideoViewer from '../components/VideoViewer';
import PostEditModals from '../components/PostEditModals';
import Modal from 'react-native-modal';

const Colors: ColorNames = useThemeColors();

const retrieveUrlLastSection = (url: string): string => {
    const result = url.match(/([^/.]+)/g);
    return result && result[result.length - 1] ? result[result.length - 1] : '';
};

const dateFormat = (date: any) => {
    return `${formatDate(new Date(date), 'yyyy.MM.dd')}`;
};

export default function PostsDetailModal(props: any) {
    const detailScrollViewRef = useRef<any>(null);
    const location = useLocation();
    const postId = retrieveUrlLastSection(location.pathname);
    const { setKeyword, setInputWord, setSearchModalVisible, setSearchModalActive } = useContext(GlobalContext);
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/posts');
    };
    const [post, setPost] = useState<any>();
    const [contest, setContest] = useState<any>();
    const [postRelated, setPostRelated] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isContestLoading, setIsContestLoading] = useState<boolean>(false);
    const [modalEnd, setModalEnd] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [isDetailModalDisplay, setIsDetailModalDisplay] = useState<boolean>(false);
    const browserId = localStorage.getItem('browserId');

    const maxContainerWidth = '1000px';
    const targetPostApiUrl = `/posts/${postId}`;
    const targetPostLikesApiUrl = `/likes/${postId}`;

    const { currentUser } = useContext(AuthContext);

    // トップへスクロール
    const goToDetailTop = () => {
        if (detailScrollViewRef.current) {
            detailScrollViewRef.current.scrollTo({ x: 0, y: 0, animated: false });
        }
    };

    const getAllPostDetailInfo = async () => {
        setIsLoading(true);
        const targetPost = await getPost();
        setIsLoading(false);
        getContest(targetPost.contestId);
        getPostRelated(targetPost.mallId, targetPost.id);
        setIsLiked(targetPost.isLiked);
    };

    //クリックした投稿を取得する
    const getPost = async () => {
        return axios.get(`${targetPostApiUrl}?noEditKey=1&browserId=${browserId}`).then((res) => {
            console.log('res =>', res, 'res.data =>', res.data);
            setPost(res.data);
            return res.data;
        });
    };

    //クリックした投稿のコンテストを取得する
    const getContest = async (contestId: any) => {
        setIsContestLoading(true);
        const apiUrl_Related = `/contests/${contestId}`;
        await axios
            .get(apiUrl_Related)
            .then((res) => {
                console.log(res);
                setContest(res.data);
            })
            .catch((error) => {
                setContest(null);
            });
        setIsContestLoading(false);
    };

    //関連する投稿取得
    const getPostRelated = async (mallId: any, id: any) => {
        const apiUrl_Related = `/posts?_start=0&_end=12&mallId=${mallId}&postId_ne=${id}&noEditKey=1`;
        axios.get(apiUrl_Related).then((res) => {
            console.log(res);
            setPostRelated(res.data);
        });
    };

    //いいね機能
    const sendLike = () => {
        axios
            .post(targetPostLikesApiUrl, {
                browserId: browserId
            })
            .then((res) => {
                if (res.data.isRefused) {
                    return alert(
                        '現在、ご使用中ブラウザでは、本機能はご利用できません。\n30分ほど時間を置いてから再度お試しください。\nまたシークレットモードをご利用の場合も本機能がお使いいただけない場合がございます。'
                    );
                }

                post.likes = res.data.isLiked ? post.likes + 1 : post.likes - 1;
                setPost(post);
                setIsLiked(res.data.isLiked);
            })
            .catch((error) => {
                console.error(error.response.data.message);
            });
    };

    //コメント＆応援メッセージ送信
    const [comment, setComment] = useState('');
    const sendMessage = () => {
        axios
            .patch(targetPostApiUrl, {
                comments: [
                    {
                        text: comment
                    }
                ]
            })
            .then((res) => {
                console.log(res);
                setPost(res.data);
                setComment('');
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (isNaN(Number(postId))) return;

        goToDetailTop();
        getAllPostDetailInfo();
        setModalEnd(false);
    }, [location, modalEnd]);

    useEffect(() => {
        !isNaN(Number(postId)) ? setIsDetailModalDisplay(true) : setIsDetailModalDisplay(false);
    }, [postId]);

    if (!postId || !post || !currentUser) return null;

    return (
        <Modal
            propagateSwipe={true}
            isVisible={isDetailModalDisplay}
            animationInTiming={-1}
            animationOutTiming={-1}
            style={{ margin: 0 }}
        >
            {isLoading ? (
                <HStack bgColor={Colors.lightestGray} height={'100vh'} justifyContent="center" alignItems="center">
                    <Spinner color={Colors.primary} size="lg" />
                </HStack>
            ) : (
                <View
                    style={{
                        flex: 1,
                        backgroundColor: Colors.white
                    }}
                >
                    {/* Tenant Name */}
                    <HStack
                        height={'60px'}
                        width={'100%'}
                        alignItems="center"
                        borderBottomColor={Colors.lighterGray}
                        borderBottomWidth={1}
                    >
                        <HStack width="100%" position="absolute" flexDirection="column" justifyContent="center">
                            <Center>
                                <Text style={styles.mallName} selectable={true}>
                                    {post.tenantName}
                                </Text>
                                <Text style={{ color: Colors.mediumGray }} selectable={true}>
                                    {post.mall.mallName}
                                </Text>
                            </Center>
                        </HStack>
                        <Pressable px="20px" alignItems={'center'} justifyContent="center" onPress={goBack}>
                            <Image
                                style={{ width: 18, height: 12, position: 'absolute' }}
                                source={require('../assets/images/back.svg')}
                                alt="戻る"
                            />
                        </Pressable>
                    </HStack>
                    <ScrollView ref={detailScrollViewRef}>
                        {/* 
                            Scrollable Modalを問題なく動作させるためにTouchableOpacityでスクロールするコンテンツをwrapする必要あり
                            React-native-modal with nested ScrollView
                            https://stackoverflow.com/questions/48466314/swipeable-react-native-modal-with-nested-scrollview
                        */}
                        <TouchableOpacity activeOpacity={1} style={{ cursor: 'auto' } as any}>
                            {post.files[0].fileType === 'images' ? (
                                <View style={styles.block}>
                                    <Swiper
                                        vertical={false}
                                        controlsProps={{
                                            dotsTouchable: true,
                                            prevPos: false,
                                            nextPos: false,
                                            dotActiveStyle: { backgroundColor: Colors.primary }
                                        }}
                                    >
                                        {post.files.map((file: any, index: number) => {
                                            return (
                                                <View
                                                    key={index}
                                                    style={[styles.slideContainer]}
                                                    // Can't scroll when swiper is wrapped with touchable #1149
                                                    // https://github.com/leecade/react-native-swiper/issues/1149
                                                    onStartShouldSetResponder={() => true}
                                                >
                                                    <Image
                                                        resizeMode={'contain'}
                                                        size="full"
                                                        source={{ uri: file.url }}
                                                        alt="test"
                                                    />
                                                </View>
                                            );
                                        })}
                                    </Swiper>
                                </View>
                            ) : (
                                <VideoViewer
                                    isAutoPlay={true}
                                    videoUrl={post.files[0].url.split('?')[0] + '__compressed.mp4?alt=media'}
                                />
                            )}
                            {/* Post Informations */}
                            <Box borderColor={Colors.lightGray} borderBottomWidth={1} alignItems="center">
                                <HStack
                                    height={'20px'}
                                    alignItems="center"
                                    justifyContent={'space-between'}
                                    paddingLeft="15px"
                                    paddingRight="15px"
                                    marginTop="10px"
                                    marginBottom="15px"
                                    maxWidth={maxContainerWidth}
                                    width="100%"
                                >
                                    <Box flexDirection="row" alignItems="center">
                                        <LikeButton marginRight={'5px'} onPress={sendLike} isLiked={isLiked} />
                                        <Text>いいね</Text>
                                        <Text style={styles.likes}>{post.likes}</Text>
                                    </Box>
                                    <Text style={styles.date}>{dateFormat(post.createdAt)}</Text>
                                </HStack>
                                {!isContestLoading ? (
                                    contest != null ? (
                                        <HStack
                                            paddingLeft="15px"
                                            paddingRight="15px"
                                            marginBottom="15px"
                                            maxWidth={maxContainerWidth}
                                            width="100%"
                                            flexDirection={'column'}
                                        >
                                            {contest.questions.map((question: any, index: any) => {
                                                return (
                                                    <Box key={index} marginBottom={'10px'}>
                                                        <Box>
                                                            <Text
                                                                style={{
                                                                    color: Colors.darkGray,
                                                                    fontSize: FontSize.medium,
                                                                    marginBottom: '2px'
                                                                }}
                                                                selectable={true}
                                                            >
                                                                Q{index + 1}. {question.text.replace(/\r?\n/g, ' ')}
                                                            </Text>
                                                        </Box>
                                                        <Box>
                                                            <Text style={styles.textbox} selectable={true}>
                                                                {displayAnswers(question, post, index)}
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </HStack>
                                    ) : null
                                ) : (
                                    <HStack height={100} justifyContent="center" alignItems="center">
                                        <Spinner color={Colors.primary} size="lg" />
                                    </HStack>
                                )}

                                <HStack
                                    paddingLeft="15px"
                                    paddingRight="15px"
                                    marginBottom="15px"
                                    maxWidth={maxContainerWidth}
                                    width="100%"
                                >
                                    <Box flexDirection="row" flexWrap="wrap" width="100%" justifyContent="flex-start">
                                        {/* tagIdsでtagsの名前も表示する */}
                                        {post.tags.map((tag: any, index: number) => {
                                            return (
                                                <Pressable
                                                    key={index}
                                                    onPress={() => {
                                                        setKeyword(`#${tag.name}`);
                                                        setInputWord(`#${tag.name}`);
                                                        setSearchModalVisible(true);
                                                        setSearchModalActive(false);
                                                        navigate('/posts');
                                                    }}
                                                >
                                                    <Text style={styles.tag}>#{tag.name}</Text>
                                                </Pressable>
                                            );
                                        })}
                                    </Box>
                                </HStack>
                                <HStack
                                    alignItems="center"
                                    paddingLeft="0px"
                                    paddingRight="0px"
                                    marginBottom="15px"
                                    maxWidth={maxContainerWidth}
                                    width="100%"
                                >
                                    <ScrollView
                                        horizontal={true}
                                        showsVerticalScrollIndicator={false}
                                        paddingLeft="15px"
                                        paddingRight="15px"
                                    >
                                        {/* [TODO] ボタンを押せないよう一時的に対応*/}
                                        {/* <Pressable
                                            onPress={() => {
                                            setKeyword(`${post.areaName}`);
                                            setInputWord(`${post.areaName}`);
                                            setSearchModalVisible(true);
                                            setSearchModalActive(false);
                                            navigate('/posts');
                                            }}
                                        > */}
                                        <Label
                                            width="13.33px"
                                            height="16.67px"
                                            source={require('../assets/images/area-gray.svg')}
                                            text={post.areaName + '地区'}
                                        />
                                        {/* </Pressable> */}
                                        {contest != null ? (
                                            // <Pressable
                                            //     onPress={() => {
                                            //         setKeyword(`${contest.title}`);
                                            //         setInputWord(`${contest.title}`);
                                            //         setSearchModalVisible(true);
                                            //         setSearchModalActive(false);
                                            //         navigate('/posts');
                                            //     }}
                                            // >
                                            <Label
                                                width="16.67px"
                                                height="16.67px"
                                                source={require('../assets/images/label.svg')}
                                                text={contest.title}
                                            />
                                        ) : // </Pressable>
                                        null}
                                    </ScrollView>
                                </HStack>
                                {/* 編集・削除 */}
                                {isContestLoading || contest == null ? null : (post.editKey != '' || null) &&
                                  currentUser.id == post.mallId &&
                                  new Date() <= new Date(contest.applicationEnd) ? (
                                    <PostEditModals contest={contest} post={post} setModalEnd={setModalEnd} />
                                ) : null}
                            </Box>
                            {/* Mall Info */}
                            <Box
                                borderColor={Colors.lightGray}
                                borderBottomWidth={1}
                                alignItems="center"
                                paddingTop="13px"
                                paddingBottom="13px"
                            >
                                <HStack
                                    alignItems="center"
                                    justifyContent={'space-between'}
                                    paddingLeft="15px"
                                    paddingRight="15px"
                                    maxWidth={maxContainerWidth}
                                    width="100%"
                                >
                                    <Pressable
                                        flex={1}
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent={'space-between'}
                                        width="100%"
                                        onPress={() => {
                                            setKeyword(`${post.tenantName}`);
                                            setInputWord(`${post.tenantName}`);
                                            setSearchModalVisible(true);
                                            setSearchModalActive(false);
                                            navigate('/posts');
                                        }}
                                    >
                                        <Box flexDirection={'row'} alignItems={'center'}>
                                            <Box>
                                                <Text style={styles.mallName}>{post.tenantName}</Text>
                                                <Text style={{ color: Colors.mediumGray }}>{post.mall.mallName}</Text>
                                            </Box>
                                        </Box>
                                        <Image
                                            height={'16.67px'}
                                            width={'9.81px'}
                                            marginLeft={'15px'}
                                            source={require('../assets/images/detail.svg')}
                                            alt=">"
                                        />
                                    </Pressable>
                                </HStack>
                            </Box>
                            {/* Comments */}
                            {/* [TODO] 初回は、トルツメ */}
                            {/* <Box
                                alignItems="center"
                                paddingTop="15px"
                                paddingBottom="15px"
                                width="100%"
                            >
                            { post.comments && post.comments.length > 0 ?
                                <HStack
                                alignItems="center"
                                justifyContent={"space-between"}
                                flexDirection="column"
                                paddingLeft="15px"
                                paddingRight="15px"
                                maxWidth={maxContainerWidth}
                                width="100%"
                                marginBottom="32px"
                                >
                                {post.comments.map((comment: any, index: number) => {
                                    return (
                                    <Box
                                        key={index}
                                        width="100%"
                                        borderBottomWidth={1}
                                        borderBottomColor={Colors.lightGray}
                                        borderBottomStyle="dashed"
                                        paddingTop={"10px"}
                                        paddingBottom={"10px"}
                                    >
                                        <Text style={{ fontSize: FontSize.small }}>
                                        {comment.text}
                                        </Text>
                                    </Box>
                                    );
                                })}
                                </HStack> : null
                            }
                            <HStack
                                alignItems="center"
                                justifyContent={"space-between"}
                                paddingLeft="15px"
                                paddingRight="15px"
                                maxWidth={maxContainerWidth}
                                width="100%"
                            >
                                <Box alignItems="center" flexDirection="row" marginBottom="14px">
                                <Image
                                    height="20px"
                                    width="20px"
                                    marginRight="5px"
                                    source={require("../assets/images/comment.svg")}
                                    alt="コメント"
                                />
                                <Text style={{ color: Colors.mediumGray, fontWeight: "700" }}>
                                    コメント & 応援メッセージ
                                </Text>
                                </Box>
                            </HStack>
                            <HStack
                                alignItems="center"
                                justifyContent={"space-between"}
                                paddingLeft="15px"
                                paddingRight="15px"
                                maxWidth={maxContainerWidth}
                                width="100%"
                            >
                                <TextInput
                                placeholder="気軽にコメントが投稿できます。"
                                multiline
                                style={styles.inputArea}
                                value={comment}
                                onChangeText={(comment) => setComment(comment)}
                                />
                            </HStack>
                            <HStack
                                alignItems="center"
                                justifyContent={"flex-end"}
                                paddingLeft="15px"
                                paddingRight="15px"
                                maxWidth={maxContainerWidth}
                                width="100%"
                            >
                                {comment === "" ? (
                                <TouchableOpacity
                                    onPress={() => {
                                    sendMessage();
                                    }}
                                    style={styles.sendButton}
                                    disabled
                                >
                                    <Text style={styles.sendButtonText}>送信する</Text>
                                </TouchableOpacity>
                                ) : (
                                <TouchableOpacity
                                    onPress={() => {
                                    sendMessage();
                                    }}
                                    style={styles.sendButtonSelected}
                                >
                                    <LinearGradient
                                    colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                                    start={[0, 0]}
                                    end={[1, 0]}
                                    style={{
                                        width: "100%",
                                        borderRadius: 50,
                                        height: 50,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    >
                                    <Text style={styles.sendButtonText}>送信する</Text>
                                    </LinearGradient>
                                </TouchableOpacity>
                                )}
                            </HStack>
                            </Box> */}
                            <Box
                                textAlign="center"
                                justifyContent="center"
                                height="80px"
                                background={Colors.lightestGray}
                            >
                                <Text style={styles.mallName}>関連する投稿</Text>
                            </Box>
                            <View style={styles.testList}>
                                {postRelated ? (
                                    postRelated.map((data: any, index: any) => (
                                        <ImageMain key={index} imageInfo={data} />
                                    ))
                                ) : (
                                    <HStack height={100} justifyContent="center" alignItems="center">
                                        <Spinner color={Colors.primary} size="lg" />
                                    </HStack>
                                )}
                            </View>
                        </TouchableOpacity>
                    </ScrollView>
                </View>
            )}
        </Modal>
    );
}

const displayAnswers = function (question: any, post: any, questionIndex: number) {
    try {
        return [2, 4].includes(question.type)
            ? question.options[post.answers[questionIndex].answer].optionText
            : [3].includes(question.type)
            ? post.answers[questionIndex].answer.map((ans: any, i: number) => {
                  return post.answers[questionIndex].answer.length !== i + 1
                      ? question.options[ans].optionText + ', '
                      : question.options[ans].optionText;
              })
            : post.answers[questionIndex].answer;
    } catch (error) {
        return null;
    }
};

const styles = StyleSheet.create({
    slideContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    block: {
        height: '375px',
        backgroundColor: Colors.darkerGray
    },
    textbox: {
        fontSize: 16,
        fontWeight: '300',
        fontFamily: 'Noto Sans JP'
    },
    grid: {
        borderColor: Colors.lightGray,
        borderBottomWidth: 1,
        padding: '15px'
    },
    inputArea: {
        minHeight: '84px',
        backgroundColor: Colors.lightestGray,
        borderColor: Colors.lightGray,
        borderRadius: 4,
        borderWidth: 1,
        textAlignVertical: 'top',
        padding: 10,
        width: '100%'
    },
    mallName: {
        fontSize: FontSize.medium,
        fontWeight: '700'
    },
    tag: {
        fontSize: FontSize.small,
        fontWeight: '300',
        fontFamily: 'Noto Sans JP',
        color: Colors.primary,
        paddingRight: '11px'
    },
    wrapperStyle: {
        padding: 1,
        paddingTop: 0,
        width: '50%',
        height: '187px'
    },
    testList: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    },
    imageStyle: {
        flex: 1
    },
    likes: {
        marginLeft: '7px',
        color: Colors.mediumGray
    },
    date: {
        color: Colors.mediumGray,
        fontSize: 14
    },
    sendButtonText: {
        color: Colors.white,
        fontWeight: 'bold'
    },
    sendButton: {
        maxWidth: '112px',
        width: '100%',
        marginTop: '10px',
        alignSelf: 'flex-end',
        borderRadius: 50,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 13,
        backgroundColor: Colors.mediumGray
    },
    sendButtonSelected: {
        maxWidth: '112px',
        width: '100%',
        marginTop: '10px',
        alignSelf: 'flex-end',
        borderRadius: 50,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 13
    }
});
