import React from 'react';
import { Center, Heading, Box, AspectRatio, Stack, Text, HStack, View, Image } from 'native-base';
import { ImageBackground, StyleSheet } from 'react-native';
import { useThemeColors, useThemeImages, useThemedAjusts } from '../../../components/Themed';
import { ColorNames, ImageNames, ThemedAjustType } from '../../../types';

const Colors: ColorNames = useThemeColors();
const Images: ImageNames = useThemeImages();
const Ajusts: ThemedAjustType = useThemedAjusts();

export default function NewDetailCard(props: any) {
    return (
        <Box height="250px">
            <View style={styles.container}>
                <ImageBackground
                    style={styles.image}
                    resizeMode={'contain'}
                    source={{ uri: props.imageUrl ? props.imageUrl : require('../../../assets/images/topResult.svg') }}
                >
                    <Image height={70} width={70} source={Images.newLabel.uri} alt={Images.newLabel.imgName} />
                    <Box overflow="hidden" position="absolute" bottom={0}>
                        <Stack
                            p="4"
                            margin="10px"
                            padding="10px"
                            flexDirection="column"
                            style={{
                                backgroundColor: Colors.transparentBlack
                            }}
                        >
                            <Heading fontSize="20px" color={Colors.white}>
                                {props.title}
                            </Heading>
                            <Box flexDirection="row" alignItems="center">
                                <Image
                                    height={'20px'}
                                    width={'16px'}
                                    source={require('../../../assets/images/area.svg')}
                                    alt="地域"
                                    style={styles.display}
                                />
                                <Text
                                    style={styles.display}
                                    fontWeight="700"
                                    fontSize="12px"
                                    color={Colors.white}
                                    marginLeft="3px"
                                >
                                    {props.area}
                                </Text>
                                <Text fontWeight="700" fontSize="12px" color={Colors.white} marginLeft="17px">
                                    {props.description}
                                </Text>
                            </Box>
                        </Stack>
                    </Box>
                </ImageBackground>
            </View>
        </Box>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: Colors.black
    },
    image: {
        flex: 1,
        resizeMode: 'cover'
    },
    display: {
        display: Ajusts.Display
    }
});
