import React, { useContext } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Text, Pressable } from 'native-base';
import Modal from 'react-native-modal';
import _ from 'lodash';
import PostCompleted from '../stories/components/Modal/PostCompleted';
import ProgressBar from '../stories/components/Bar/ProgressBar';
import Title from '../stories/components/ButtonNative/Title';
import FontSize from '../constants/FontSize';
import { GlobalContext } from '../contexts/Global';
import { useThemeColors, useThemeImages } from './Themed';
import { ColorNames, ImageNames } from '../types';

export default function PostResultModals(props: any) {
    const Colors: ColorNames = useThemeColors();
    const Images: ImageNames = useThemeImages();

    const styles = StyleSheet.create({
        modalPost: {
            flex: 1,
            backgroundColor: Colors.white,
            marginTop: 59,
            paddingTop: 47,
            paddingHorizontal: '5%',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20
        },
        modalComplete: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        }
    });

    const { setKeyword, setInputWord, setPostComplete } = useContext(GlobalContext);
    return (
        <>
            <Modal
                isVisible={props.modalComplete}
                animationIn="zoomIn"
                animationOut="slideOutDown"
                style={{ margin: 0, flex: 1, width: '100%', height: '100%' }}
            >
                <Pressable
                    flex={1}
                    onPress={() => {
                        props.setModalPost(false);
                        props.setModalPicPhoto(false);
                        props.setModalDetail(false);
                        props.setModalComplete(false);
                        props.resetFormState();
                        setKeyword('');
                        setInputWord('');
                        setPostComplete(true);
                    }}
                >
                    <View style={styles.modalPost}>
                        {ProgressBar({ progressWidth: 100 })}
                        {Title({
                            text: '投稿ありがとう！',
                            fontSize: FontSize.large,
                            fontWeight: '600'
                        })}
                        {PostCompleted({
                            source: Images.postCompleted.uri,
                            text: '他の投稿も参考にしながら、\nまた投稿してね！',
                            button: true
                        })}
                    </View>
                </Pressable>
            </Modal>
            <Modal
                isVisible={props.modalError}
                animationIn="slideInUp"
                animationOut="slideOutDown"
                style={{
                    justifyContent: 'flex-end',
                    margin: 0
                }}
                onBackdropPress={() => props.setModalError(false)}
            >
                <View
                    style={{
                        backgroundColor: Colors.white,
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20
                    }}
                >
                    <Text style={{ margin: 20, paddingHorizontal: 10 }}>{props.postErrorMessage}</Text>
                </View>
            </Modal>
        </>
    );
}
