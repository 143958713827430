import React, { useState } from 'react';
import { StyleSheet, View, Image, Button, TextInput } from 'react-native';
import { Box, Text, Pressable, ScrollView, Checkbox, Spinner, Radio } from 'native-base';
import Modal from 'react-native-modal';
import axios from '../axios/axios';
import { SubmitHandler, Controller } from 'react-hook-form';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import _ from 'lodash';
import Title from '../stories/components/ButtonNative/Title';
import { LinearGradient } from 'expo-linear-gradient';
import ProgressBar from '../stories/components/Bar/ProgressBar';
import TextArea from '../stories/components/Drawer/TextArea';
import FontSize from '../constants/FontSize';
import { uploadImageToFirebaseStorage, uploadVideoToFirebaseStorage } from '../utils/utils';
import { ErrorMessage } from '@hookform/error-message';
import { useThemeColors, useThemedAjusts } from './Themed';
import { ColorNames, ThemedAjustType } from '../types';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../firebase/firebase';

export default function FormDetailModal(props: any) {
    const Colors: ColorNames = useThemeColors();
    const Ajusts: ThemedAjustType = useThemedAjusts();

    const styles = StyleSheet.create({
        modalPost: {
            flex: 1,
            backgroundColor: Colors.white,
            marginTop: 59,
            paddingTop: 47,
            paddingLeft: 28,
            paddingRight: 28,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20
        },
        modalComplete: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        },
        modalTitle: {
            textAlign: 'center',
            fontSize: 20,
            fontWeight: '700'
        },
        selectedContestTitle: {
            textAlign: 'center',
            fontSize: 13,
            marginVertical: 10
        },
        cross: {
            height: 24,
            width: 24,
            marginTop: 17,
            position: 'absolute',
            right: 10
        },
        modalDetail: {
            height: 10,
            width: 6.17
        },
        backButton: {
            width: 'fit-content',
            borderRadius: 50,
            height: 48,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors.mediumGray,
            marginHorizontal: 5
        },
        PostButton: {
            position: 'absolute',
            textAlign: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            alignSelf: 'center',
            width: '100%',
            paddingVertical: 20,
            bottom: 0,
            borderTopWidth: 1,
            borderStyle: 'solid',
            borderColor: Colors.lighterGray
        },
        buttonText: {
            color: Colors.white,
            fontWeight: '700',
            paddingHorizontal: 25
        },
        nextButton: {
            width: 'fit-content',
            borderRadius: 50,
            height: 48,
            alignItems: 'center',
            justifyContent: 'center',
            marginHorizontal: 5
        },
        inputSpace: {
            marginTop: '15px'
        },
        questionTitle: {
            fontSize: 14,
            fontWeight: '700',
            marginBottom: '10px'
        }
    });

    const questionList = props.selectedContest?.questions;
    const tagGetUrl = `/tags`; //タグ取得API
    const answerPostUrl = `/posts`; //投稿API
    const [selectInputValue, setSelectInputValue] = useState('');

    function handleCheckList(selectedValue: number, checked: boolean, checkList: Array<number>, i: number) {
        const setList = [];
        if (checkList == undefined) {
            setList.push(selectedValue);
            props.setValue(`answerList.answers.${i}.answer`, setList);
            return;
        }
        const index = checkList.indexOf(selectedValue);
        console.log('index:' + index);
        if (index == -1 && checked == true) {
            checkList.push(selectedValue);
            props.setValue(`answerList.answers.${i}.answer`, checkList);
            console.log('push ' + selectedValue);
        } else if (index != -1 && checked == false) {
            const newCheckList = [...checkList];
            newCheckList.splice(index, 1);
            props.setValue(`answerList.answers.${i}.answer`, newCheckList);
            console.log('splice ' + selectedValue);
        }
    }

    function createPostForm() {
        const questionFormlist: Array<JSX.Element> = [];
        questionList?.map((ques: any, i: number) => {
            switch (ques.type) {
                case 0: //text
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text fontWeight={'700'}>{ques.text}</Text>
                            <Controller
                                render={({ field }: any) =>
                                    TextArea({
                                        placeholder: '入力してください',
                                        multiline: false,
                                        value: props.getValues(`answerList.answers.${i}.answer`),
                                        onChange: (e: any) => {
                                            props.setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                            field.onChange(e);
                                        }
                                    })
                                }
                                name={`answerList.answers.${i}.answer`}
                                control={props.control}
                                rules={{ required: true }}
                            />
                            <ErrorMessage
                                errors={props.errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
                case 1: //textarea
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text style={styles.questionTitle}>{ques.text}</Text>
                            <Controller
                                render={({ field }: any) =>
                                    TextArea({
                                        placeholder: '入力してください',
                                        multiline: true,
                                        value: props.getValues(`answerList.answers.${i}.answer`),
                                        onChange: (e: any) => {
                                            field.onChange(e);
                                            props.setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                        }
                                    })
                                }
                                name={`answerList.answers.${i}.answer`}
                                control={props.control}
                                rules={{ required: true }}
                            />
                            <ErrorMessage
                                errors={props.errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
                case 2: {
                    //select
                    const optionsList: Array<{ value: number; label: string }> = [];
                    for (const opt of ques.options) {
                        optionsList.push({ value: ques.options.indexOf(opt), label: opt.optionText });
                    }
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text style={styles.questionTitle}>{ques.text}</Text>
                            <Controller
                                render={({ field }: any) => (
                                    <Select
                                        styles={questionSelectStyles}
                                        menuPortalTarget={document.body}
                                        ref={field.ref}
                                        placeholder="選択してください"
                                        options={optionsList}
                                        value={optionsList.find(
                                            (option) =>
                                                option.value == props.getValues(`answerList.answers.${i}.answer`)
                                        )}
                                        onChange={(e: any) => {
                                            props.setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                            field.onChange(e?.value);
                                        }}
                                    />
                                )}
                                name={`answerList.answers.${i}.answer`}
                                control={props.control}
                                rules={{ required: true }}
                            />
                            <ErrorMessage
                                errors={props.errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
                }
                case 3: //checkbox
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text style={styles.questionTitle}>{ques.text}</Text>
                            <Box flexDirection="row" justifyContent="space-between" flexWrap="wrap">
                                {ques.options.map((opt: any, index: number) => {
                                    return (
                                        <Box
                                            key={index}
                                            style={{ minWidth: '33%', marginTop: 10, flexDirection: 'row' }}
                                        >
                                            <Controller
                                                render={() => (
                                                    <Checkbox
                                                        _light={{
                                                            bg: Colors.lightGray,
                                                            _text: {
                                                                marginLeft: '0',
                                                                fontSize: 16
                                                            },
                                                            _icon: {
                                                                color: Colors.white
                                                            },
                                                            _checked: {
                                                                bg: Colors.primary,
                                                                _hover: {
                                                                    bg: Colors.primary
                                                                },
                                                                _text: {
                                                                    color: Colors.primary,
                                                                    fontWeight: '700'
                                                                }
                                                            }
                                                        }}
                                                        value={index.toString()}
                                                        borderWidth={0}
                                                        size={'md'}
                                                        isChecked={props
                                                            .getValues(`answerList.answers.${i}.answer`)
                                                            ?.includes(index)}
                                                        onChange={(e) => {
                                                            handleCheckList(
                                                                index,
                                                                e.valueOf(),
                                                                props.getValues(`answerList.answers.${i}.answer`),
                                                                i
                                                            );
                                                            props.setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                                        }}
                                                    >
                                                        {opt.optionText}
                                                    </Checkbox>
                                                )}
                                                name={`answerList.answers.${i}.answer`}
                                                control={props.control}
                                                rules={{ required: true, minLength: 1 }}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                            <ErrorMessage
                                errors={props.errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
                case 4: //radio
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text style={styles.questionTitle}>{ques.text}</Text>
                            <Controller
                                render={({ field }: any) => (
                                    <Radio.Group
                                        name={ques.text}
                                        accessibilityLabel={ques.text}
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        flexWrap="wrap"
                                        value={String(props.getValues(`answerList.answers.${i}.answer`))}
                                        onChange={(e) => {
                                            props.setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                            field.onChange(Number(e));
                                        }}
                                    >
                                        {ques.options.map((opt: any, i: number) => {
                                            return (
                                                <Box
                                                    key={i}
                                                    style={{ minWidth: '33%', marginTop: 10, flexDirection: 'row' }}
                                                >
                                                    <Radio
                                                        _light={{
                                                            bg: Colors.lightGray,
                                                            borderWidth: 0,
                                                            _text: {
                                                                fontSize: 16
                                                            },
                                                            _checked: {
                                                                _icon: {
                                                                    color: Colors.primary
                                                                },
                                                                _text: {
                                                                    color: Colors.primary,
                                                                    fontWeight: '700'
                                                                }
                                                            }
                                                        }}
                                                        value={i.toString()}
                                                        size={'md'}
                                                    >
                                                        {opt.optionText}
                                                    </Radio>
                                                </Box>
                                            );
                                        })}
                                    </Radio.Group>
                                )}
                                name={`answerList.answers.${i}.answer`}
                                control={props.control}
                                rules={{ required: true }}
                            />
                            <ErrorMessage
                                errors={props.errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
            }
        });
        return questionFormlist;
    }

    const loadOptions = (input: string) => {
        console.log('on load options function');
        return axios.get(tagGetUrl + `?name_like=${input.replace(/#|＃/g, '')}`).then((response) => {
            const options: Array<{ label: string; value: string | number }> = [];
            response.data.forEach((data: any) => {
                options.push({
                    label: data.name,
                    value: data.id
                });
            });
            return options;
        });
    };

    const onSubmit: SubmitHandler<{ answerList: any }> = async (data: { answerList: any }) => {
        props.setIsSending(true);

        //画像・動画のurl取得
        const fileList: Array<any> = [];

        try {
            // [TODO] サードバーティCookie問題があるため以下一旦コメントアウト
            // アップロードの前にFirebase AuthenticationにログインしてWebアプリ内のFirebase SDKに認証状態を保存する
            // await axios.post(`/checkAuth`, {isGettingToken: true}).then(async (response: any) => {
            //     if (response.data.token) {
            //         // カスタムトークンで認証
            //         await signInWithCustomToken(auth, response.data.token)
            //             .then(async (userCredential) => {
            //                 if (response.data.isNotExtended) {
            //                     return;
            //                 }

            //                 const { data } = await axios.get(`/csrf-token`);
            //                 const token = await userCredential.user.getIdToken();
            //                 const result = await axios.post(`/sessionLogin`, {
            //                     idToken: token,
            //                     csrfToken: data.csrfToken,
            //                 });

            //                 if (result.status == 200) {
            //                     return;
            //                 }

            //                 throw Error();
            //             });
            //     } else {
            //         if (response.data.status == 'error') {
            //             throw Error();
            //         }
            //     }
            // });

            if (props.images.length > 0) {
                for (let index = 0; index < props.images.length; index++) {
                    const image = props.images[index];
                    const imageUrl = await uploadImageToFirebaseStorage(image, props.areaId, 'posts');
                    fileList.push({ url: imageUrl, fileType: 'images' });
                }
            } else if (props.video.length > 0) {
                for (let index = 0; index < props.video.length; index++) {
                    const video = props.video[index];
                    const videoUrl = await uploadVideoToFirebaseStorage(video, props.areaId, 'posts');
                    fileList.push({ url: videoUrl, fileType: 'video' });
                }
            }

            data.answerList.files = fileList;
            props.setValue(`answerList.files`, fileList);

            //投稿処理
            axios.post(answerPostUrl, data.answerList).then(() => {
                props.setModalComplete(true);
                props.setModalDetail(false);
                props.resetFormState();
                props.setIsSending(false);
                props.clearErrors();
            });
            console.log('onSubmit:', data);
        } catch (error) {
            props.setPostErrorMessage('投稿に失敗しました。時間をあけて再度お試しください。');
            props.setModalError(true);
            setTimeout(() => props.setModalError(false), 5000);
            props.setIsSending(false);
        }
    };

    const jobTypesStyles = {
        container: () => ({
            borderColor: Colors.lightGray,
            height: 40,
            width: '100%',
            fontSize: 14,
            borderRadius: 4,
            zIndex: 5
        }),
        indicatorSeparator: () => ({
            isVisible: false
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999
        })
    };

    const questionSelectStyles = {
        container: () => ({
            borderColor: Colors.lightGray,
            height: 40,
            width: '100%',
            fontSize: 14,
            borderRadius: 4
        }),
        indicatorSeparator: () => ({
            isVisible: false
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999
        })
    };

    const tagsStyles = {
        container: () => ({
            borderColor: Colors.lightGray,
            height: 40,
            width: '100%',
            fontSize: 14,
            borderRadius: 4,
            zIndex: 5
        }),
        indicatorSeparator: () => ({
            isVisible: false
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999
        })
    };

    return (
        <Modal
            isVisible={props.modalDetail}
            animationIn="slideInRight"
            animationOut="slideOutRight"
            // backdropOpacity={0}
            style={{ margin: 0, flex: 1 }}
        >
            <Pressable
                px="10px"
                onPress={() => {
                    props.setModalDetail(false);
                }}
            >
                <Image style={styles.cross} source={require('../assets/images/cross.svg')} />
            </Pressable>
            <View style={styles.modalPost}>
                {ProgressBar({ progressWidth: 75 })}
                {Title({
                    text: '詳細設定(STEP3/3)',
                    fontSize: FontSize.large,
                    fontWeight: '600'
                })}
                <Text style={styles.selectedContestTitle}>{props.selectedContest?.title}</Text>
                <ScrollView marginBottom="90px">
                    <Controller
                        render={({ field }: any) =>
                            TextArea({
                                placeholder: Ajusts.TenantNamePlaceholder,
                                multiline: false,
                                ref: field.ref,
                                value: props.getValues(`answerList.tenantName`),
                                onChange: (e: any) => props.setValue(`answerList.tenantName`, e.nativeEvent.text)
                            })
                        }
                        name={`answerList.tenantName`}
                        control={props.control}
                        rules={{ required: true }}
                    />
                    <ErrorMessage
                        errors={props.errors}
                        name={`answerList.tenantName`}
                        render={() => <Text color={'red.500'}>入力は必須です</Text>}
                    />

                    <Box style={styles.inputSpace}>
                        <Box flexDirection="row" alignItems="center" width={'100%'}>
                            <Text width={50} color={Colors.mediumGray}>
                                業種
                            </Text>
                            <Controller
                                render={({ field }: any) => (
                                    <Select
                                        styles={jobTypesStyles}
                                        menuPortalTarget={document.body}
                                        options={props.selectJobTypesList}
                                        placeholder="選択してください"
                                        value={props.selectJobTypesList.find(
                                            (option: { value: number; label: string }) =>
                                                option.value == props.getValues(`answerList.jobTypeId`)
                                        )}
                                        onChange={(e: any) => {
                                            field.onChange(e?.value);
                                        }}
                                    />
                                )}
                                name={`answerList.jobTypeId`}
                                control={props.control}
                                rules={{ required: true }}
                            />
                        </Box>
                        <ErrorMessage
                            errors={props.errors}
                            name={`answerList.jobTypeId`}
                            render={() => <Text color={'red.500'}>入力は必須です</Text>}
                        />
                    </Box>

                    <>{createPostForm()}</>

                    <Box flexDirection={'column'} style={styles.inputSpace}>
                        <Text style={styles.questionTitle}>ハッシュタグ</Text>
                        <Controller
                            render={() => (
                                <AsyncCreatableSelect
                                    styles={tagsStyles}
                                    menuPortalTarget={document.body}
                                    loadOptions={loadOptions}
                                    placeholder="入力してください"
                                    value={props.viewTagList}
                                    isMulti
                                    cacheOptions
                                    loadingMessage={() => 'ハッシュタグを検索中...'}
                                    formatCreateLabel={(input: string) =>
                                        '新規ハッシュタグ「' + input.replace(/#|＃/g, '') + '」を作成'
                                    }
                                    noOptionsMessage={() => '入力すると候補が表示されます'}
                                    inputValue={selectInputValue}
                                    onInputChange={(newInputValue) =>
                                        setSelectInputValue(newInputValue.replace(/#|＃/g, ''))
                                    }
                                    onChange={(e: any) => {
                                        props.setViewTagList(
                                            e.map((item: any) => {
                                                return {
                                                    label: '#' + item.label.replace(/#|＃/g, ''),
                                                    value:
                                                        typeof item.value == 'number'
                                                            ? item.value
                                                            : item.value.replace(/#|＃/g, '')
                                                };
                                            })
                                        );
                                        const tagIdList: Array<number> = [];
                                        const newTagList: Array<string> = [];
                                        for (const selected of e) {
                                            if (typeof selected.value == 'number') tagIdList.push(selected.value);
                                            else if (typeof selected.value == 'string')
                                                newTagList.push(selected.value.replace(/#|＃/g, ''));
                                        }
                                        props.setValue(`answerList.tagIds`, tagIdList);
                                        props.setValue(`answerList.newTags`, newTagList);
                                    }}
                                />
                            )}
                            name={`answerList.tagIds`}
                            control={props.control}
                        />
                    </Box>
                    <Box flexDirection={'column'} style={styles.inputSpace}>
                        <Text style={styles.questionTitle}>編集・削除キー</Text>
                        <Text>
                            この投稿を編集・削除したい場合は、投稿時に設定した編集・削除キー（数字4桁）を入れてください。
                        </Text>
                        <Controller
                            render={() =>
                                TextArea({
                                    placeholder: '半角数字4桁で入力してください',
                                    multilline: false,
                                    maxlength: 4,
                                    value: props.getValues(`answerList.editKey`),
                                    keyboardType: 'numeric',
                                    onChange: (e: any) => {
                                        props.setValue(`answerList.editKey`, e.nativeEvent.text);
                                    }
                                })
                            }
                            name={`answerList.editKey`}
                            control={props.control}
                            rules={{
                                validate: (value) => {
                                    if ((!isNaN(value) && value.length === 4) || value === '') {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            }}
                        />
                        <ErrorMessage
                            errors={props.errors}
                            name={`answerList.editKey`}
                            render={() => <Text color={'red.500'}>半角数字4桁で入力してください</Text>}
                        />
                    </Box>
                </ScrollView>
                <Box style={styles.PostButton}>
                    <Pressable
                        style={styles.backButton}
                        onPress={() => {
                            props.setModalPicPhoto(true);
                            props.setModalDetail(false);
                        }}
                    >
                        <Text style={styles.buttonText}>戻る</Text>
                    </Pressable>
                    <Pressable
                        disabled={props.isSending}
                        onPress={() => {
                            props.handleSubmit(onSubmit)();
                        }}
                    >
                        <LinearGradient
                            colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                            start={[0, 0]}
                            end={[1, 0]}
                            style={styles.nextButton}
                        >
                            {props.isSending ? (
                                <Spinner
                                    color={Colors.white}
                                    size="sm"
                                    width={'fit-content'}
                                    paddingRight={50}
                                    paddingLeft={50}
                                />
                            ) : (
                                <Text style={styles.buttonText}>投稿する！</Text>
                            )}
                        </LinearGradient>
                    </Pressable>
                </Box>
            </View>
        </Modal>
    );
}
