import { HStack } from 'native-base';
import React from 'react';
import { Text } from 'react-native';
import FontSize from '../../../constants/FontSize';

const Title = (props: any) => {
    const { text, color, fontWeight, fontSize, ...rest } = props;
    return (
        <HStack
            style={{
                height: 55,
                marginRight: 20,
                marginLeft: 20,
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Text
                style={{
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    color: color
                }}
            >
                {text}
            </Text>
        </HStack>
    );
};
export default Title;
