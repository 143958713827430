import React, { useContext, useEffect, useState, useRef } from 'react';
import { Text, Pressable, IconButton, HStack, Spinner } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { AuthContext } from '../contexts/Auth';
import axios from '../axios/axios';
import { useForm, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import { LinearGradient } from 'expo-linear-gradient';
import SelectContestModal from './SelectContestModal';
import PicPhotoModal from './PicPhotoModal';
import FormDetailModal from './FormDetailModal';
import PostResultModals from './PostResultModals';
import { formatDate } from '../utils/utils';
import List from '../stories/components/Drawer/List';
import { Platform } from 'react-native';
import TalkBubble from '../components/TalkBubble';
import { useThemeColors } from './Themed';
import { ColorNames } from '../types';
import { Consts } from '../constants/Consts';

export default function PostModals() {
    const Colors: ColorNames = useThemeColors();

    const [modalPost, setModalPost] = useState(false);
    const [modalPicPhoto, setModalPicPhoto] = useState(false);
    const [modalDetail, setModalDetail] = useState(false);
    const [modalComplete, setModalComplete] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [postErrorMessage, setPostErrorMessage] = useState('');
    const { currentUser } = useContext(AuthContext);
    const [viewTagList, setViewTagList] = useState();
    const [areaId, setAreaId] = useState<number>();

    interface scoring {
        text: string;
        description: string;
        allocation: number;
    }
    interface question {
        text: string;
        type: number;
        options: {
            optionText: string;
        }[];
    }

    interface contests {
        id: number; // contestId
        title: string; //contest name
        subtitle: string;
        contestType: number; // コンテストの種類
        description: string; //contest description
        imageUrl: string; //contest image
        publishStart: string;
        publishEnd: string;
        applicationStart: string;
        applicationEnd: string;
        areaId: number; // 所属地区
        maxSelectionNumber: number; // 最大審査次数  2 or 3
        selectionStatus: number; // 審査状況
        questions: Array<question>; // 設問配列
        scoringItems: Array<scoring>; // 採点項目配列
        createdAt: Date;
        updatedAt: Date;
        deletedAt: Date;
        delFlag: number;
    }

    interface file {
        url: string;
        fileType: string;
    }

    interface posts {
        mallId: number; // mallId
        contestId: number; //contestId
        tenantName: string; //店名
        jobTypeId: number | undefined; //業種ID
        files: Array<file>; //複数ファイルurl配列
        answers: Array<Answers>; //回答
        tagIds: Array<number>;
        newTags: Array<string>; //新しく追加するタグ
        editKey: string; //faker.internet.password(6, false, /[0-9a-z]/)
    }

    const [boxList, setBoxList] = useState<Array<JSX.Element | { text: string; date: string }> | JSX.Element>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [returnArray, setReturnArray] = useState<any[] | undefined>([]);
    const [selectedContest, setSelectedContest] = useState<contests>();
    const [fileAccept, setFileAccept] = useState<string>();
    const [images, setImages] = useState<File[]>([]); //選択画像リスト
    const [video, setVideo] = useState<File[]>([]); //選択動画
    const [selectJobTypesList, setJobTypeList] = useState<Array<{ value: number; label: string }>>([]);
    const [isSending, setIsSending] = useState(false);

    function viewContestList() {
        if (returnArray == undefined) {
            setBoxList(<Text>コンテスト一覧の取得に失敗しました。時間をおいて再度アクセスしてください。</Text>);
        } else if (returnArray.length > 0) {
            const contestList: Array<contests> = returnArray;
            const onPress = (contest: contests) => {
                setModalPicPhoto(true);
                setModalPost(false);
                setSelectedContest(contest);
                if (contest.contestType == 0) {
                    setFileAccept(Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION);
                } else {
                    setFileAccept(Consts.ACCEPTABLE_VIDEO_FILE_EXTENSION);
                }
            };
            setBoxList(List({ data: contestList, onPress: onPress }));
        } else {
            setBoxList(<Text>開催中のコンテストはありません</Text>);
        }
    }

    interface Answers {
        questionNo: number;
        answer: any;
    }

    const [inputs, setInputs] = useState<posts>({
        mallId: 0,
        contestId: 0,
        tenantName: '',
        jobTypeId: undefined,
        files: [],
        answers: [],
        tagIds: [],
        newTags: [],
        editKey: ''
    });

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
        setValue,
        getValues,
        clearErrors
    } = useForm({
        defaultValues: { answerList: inputs },
        mode: 'onChange'
    });

    const { fields, update, append } = useFieldArray({
        control,
        name: `answerList.answers`
    });

    function resetFormState() {
        setBoxList([]);
        setSelectedContest(undefined);
        setFileAccept(undefined);
        setImages([]);
        setVideo([]);
        setJobTypeList([]);
        resetAnswerList();
    }

    function resetAnswerList() {
        setValue(`answerList.contestId`, 0);
        setValue(`answerList.tenantName`, '');
        setValue(`answerList.jobTypeId`, undefined);
        setValue(`answerList.files`, []);
        setValue(`answerList.answers`, []);
        setValue(`answerList.tagIds`, []);
        setValue(`answerList.newTags`, []);
        setValue(`answerList.editKey`, '');
        setImages([]);
        setVideo([]);
        setViewTagList(undefined);
    }

    useEffect(() => {
        if (!currentUser) return;

        setValue(`answerList.mallId`, currentUser.id);
        setAreaId(currentUser.areaId);
    }, [currentUser]);

    useEffect(() => {
        if (!modalPost) return;
        setIsLoading(true);
        const sort = '_sort=applicationEnd';
        const nowDateString: string = new Date().toISOString();
        const now: string = '&applicationEnd_gte=' + nowDateString;
        const abortController = new AbortController();
        axios
            .get(`/contests?` + '&areaId=' + areaId + '&mallId=' + currentUser?.id + now)
            .then((result) => {
                console.log(`/contests?` + sort + '&areaId=' + areaId + '&mallId=' + currentUser?.id + now);
                console.log('returnArrayListlength:' + result.data.length);
                setIsLoading(false);
                setReturnArray(result.data);
            })
            .catch((error) => {
                console.log('通信失敗');
                console.log(error);
                setIsLoading(false);
                setReturnArray(undefined);
            });
        abortController.abort();
    }, [modalPost]);

    useEffect(() => {
        if (!returnArray) return;
        viewContestList();
    }, [returnArray]);

    useEffect(() => {
        if (selectedContest != undefined && selectedContest.id != getValues(`answerList.contestId`)) {
            resetAnswerList();
            setValue(`answerList.contestId`, selectedContest.id);
            clearErrors();
        }
    }, [selectedContest]);

    return (
        <>
            <Pressable
                {...Platform.select({
                    web: {
                        cursor: 'pointer'
                    }
                })}
                position="absolute"
                right="23px"
                bottom="125px"
            >
                <TalkBubble />
            </Pressable>
            <Pressable
                {...Platform.select({
                    web: {
                        cursor: 'pointer'
                    }
                })}
                position="absolute"
                right="20px"
                bottom="70px"
            >
                <LinearGradient
                    colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                    start={[0, 0]}
                    end={[1, 0]}
                    style={{ borderRadius: 50 }}
                >
                    <IconButton
                        borderRadius="full"
                        size={'lg'}
                        variant="ghost"
                        _hover={{ backgroundColor: Colors.black, opacity: 0.2 }}
                        _icon={{
                            as: MaterialIcons,
                            name: 'add',
                            color: Colors.white
                        }}
                        onPress={() => {
                            if (getValues(`answerList.contestId`) == 0) {
                                setModalPost(true);
                            } else {
                                setModalPicPhoto(true);
                                setModalPost(false);
                            }
                        }}
                    />
                </LinearGradient>
                <SelectContestModal
                    setModalPost={setModalPost}
                    modalPost={modalPost}
                    boxList={boxList}
                    isLoading={isLoading}
                />
                <PicPhotoModal
                    setModalPost={setModalPost}
                    setModalDetail={setModalDetail}
                    modalPicPhoto={modalPicPhoto}
                    setModalPicPhoto={setModalPicPhoto}
                    selectedContest={selectedContest}
                    images={images}
                    setImages={setImages}
                    video={video}
                    setVideo={setVideo}
                    fileAccept={fileAccept}
                    setJobTypeList={setJobTypeList}
                    setValue={setValue}
                    areaId={areaId}
                />
                <FormDetailModal
                    modalDetail={modalDetail}
                    setModalDetail={setModalDetail}
                    selectedContest={selectedContest}
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    selectJobTypesList={selectJobTypesList}
                    viewTagList={viewTagList}
                    setViewTagList={setViewTagList}
                    setModalPicPhoto={setModalPicPhoto}
                    isSending={isSending}
                    setIsSending={setIsSending}
                    handleSubmit={handleSubmit}
                    images={images}
                    video={video}
                    setModalComplete={setModalComplete}
                    resetFormState={resetFormState}
                    setPostErrorMessage={setPostErrorMessage}
                    modalError={modalError}
                    setModalError={setModalError}
                    watch={watch}
                    clearErrors={clearErrors}
                    areaId={areaId}
                />

                <PostResultModals
                    modalComplete={modalComplete}
                    setModalPost={setModalPost}
                    setModalPicPhoto={setModalPicPhoto}
                    setModalDetail={setModalDetail}
                    setModalComplete={setModalComplete}
                    resetFormState={resetFormState}
                    modalError={modalError}
                    setModalError={setModalError}
                    postErrorMessage={postErrorMessage}
                />
            </Pressable>
        </>
    );
}
