import React, { useEffect, useState, useRef } from 'react';
import { Pressable, Image, Text, ScrollView, Box, Checkbox, Spinner, Radio } from 'native-base';
import { View, StyleSheet } from 'react-native';
import Modal from 'react-native-modal/dist/modal';
import { SubmitHandler, Controller, useFieldArray, useForm } from 'react-hook-form';
import TextArea from '../stories/components/Drawer/TextArea';
import { ErrorMessage } from '@hookform/error-message';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Select from 'react-select';
import axios from '../axios/axios';
import { LinearGradient } from 'expo-linear-gradient';
import FontSize from '../constants/FontSize';
import { uploadImageToFirebaseStorage, uploadVideoToFirebaseStorage } from '../utils/utils';
import { useThemeColors, useThemedAjusts } from './Themed';
import { ColorNames, ThemedAjustType } from '../types';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../firebase/firebase';
import { Consts } from '../constants/Consts';

const Colors: ColorNames = useThemeColors();
const Ajusts: ThemedAjustType = useThemedAjusts();

export default function EditScreen(props: any) {
    const tagGetUrl = `/tags`; //タグ取得API
    const [selectJobTypesList, setJobTypeList] = useState<Array<{ value: number; label: string }>>([]);
    const [viewTagList, setViewTagList] = useState();
    const [isSending, setIsSending] = useState(false);
    const [removalNum, setRemovalNum] = useState<any[]>([]);
    const [selectInputValue, setSelectInputValue] = useState('');

    function handleCheckList(selectedValue: number, checked: boolean, checkList: Array<number>, i: number) {
        const setList = [];
        if (checkList == undefined) {
            setList.push(selectedValue);
            setValue(`answerList.answers.${i}.answer`, setList);
            return;
        }
        const index = checkList.indexOf(selectedValue);
        console.log('index:' + index);
        if (index == -1 && checked == true) {
            checkList.push(selectedValue);
            setValue(`answerList.answers.${i}.answer`, checkList);
            console.log('push ' + selectedValue);
        } else if (index != -1 && checked == false) {
            const newCheckList = [...checkList];
            newCheckList.splice(index, 1);
            setValue(`answerList.answers.${i}.answer`, newCheckList);
            console.log('splice ' + selectedValue);
        }
    }

    const loadOptions = (input: string) => {
        console.log('on load options function');
        return axios.get(tagGetUrl + `?name_like=${input.replace(/#|＃/g, '')}`).then((response) => {
            const options: Array<{ label: string; value: string | number }> = [];
            response.data.forEach((data: any) => {
                options.push({
                    label: data.name,
                    value: data.id
                });
            });
            return options;
        });
    };

    const jobTypesStyles = {
        container: () => ({
            borderColor: Colors.lightGray,
            height: 40,
            width: '100%',
            fontSize: 14,
            borderRadius: 4,
            zIndex: 5
        }),
        indicatorSeparator: () => ({
            isVisible: false
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999
        })
    };

    const tagsStyles = {
        container: () => ({
            borderColor: Colors.lightGray,
            height: 40,
            width: '100%',
            fontSize: 14,
            borderRadius: 4,
            zIndex: 5
        }),
        indicatorSeparator: () => ({
            isVisible: false
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999
        })
    };

    const questionSelectStyles = {
        container: () => ({
            borderColor: Colors.lightGray,
            height: 40,
            width: '100%',
            fontSize: 14,
            borderRadius: 4
        }),
        indicatorSeparator: () => ({
            isVisible: false
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999
        })
    };

    const { post, apiUrl, contest, editKey, areaId, ...rest } = props;
    const questionList = contest.questions;
    const [inputs, setInputs] = useState({
        mallId: post.mallId,
        contestId: post.contestId,
        tenantName: post.tenantName,
        jobTypeId: post.jobTypeId,
        files: post.files,
        answers: post.answers,
        tagIds: post.tagIds,
        newTags: post.newTags,
        editKey: post.editKey
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues
    } = useForm({
        defaultValues: { answerList: inputs },
        mode: 'onChange'
    });

    //業種取得
    const getJobTypes = async () => {
        const jobTypeGetUrl = `/jobtypes?_sort=id`; //業種取得API
        const returnList: Array<any> = [];

        await axios
            .get(jobTypeGetUrl)
            .then((result) => {
                const jobTypesList: Array<any> | undefined = result.data;
                if (jobTypesList != undefined) {
                    for (const jobtype of jobTypesList) {
                        returnList.push({ value: jobtype.id, label: jobtype.name });
                    }
                } else {
                    console.log('jobTypeList Undefined');
                }
                setJobTypeList(returnList);
            })
            .catch((error) => {
                console.log('通信失敗');
                console.log(error);
            });
    };

    function createPostForm() {
        const questionFormlist: Array<JSX.Element> = [];
        questionList?.map((ques: any, i: number) => {
            switch (ques.type) {
                case 0: //text
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text fontWeight={'700'}>{ques.text}</Text>
                            <Controller
                                render={({ field }: any) =>
                                    TextArea({
                                        placeholder: '入力してください',
                                        multiline: false,
                                        value: getValues(`answerList.answers.${i}.answer`),
                                        onChange: (e: any) => {
                                            setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                            field.onChange(e);
                                        }
                                    })
                                }
                                name={`answerList.answers.${i}.answer`}
                                control={control}
                                rules={{ required: true }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
                case 1: //textarea
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text style={styles.questionTitle}>{ques.text}</Text>
                            <Controller
                                render={({ field }: any) =>
                                    TextArea({
                                        placeholder: '入力してください',
                                        multiline: true,
                                        value: getValues(`answerList.answers.${i}.answer`),
                                        onChange: (e: any) => {
                                            field.onChange(e);
                                            setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                        }
                                    })
                                }
                                name={`answerList.answers.${i}.answer`}
                                control={control}
                                rules={{ required: true }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
                case 2: {
                    //select
                    const optionsList: Array<{ value: number; label: string }> = [];
                    for (const opt of ques.options) {
                        optionsList.push({ value: ques.options.indexOf(opt), label: opt.optionText });
                    }
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text style={styles.questionTitle}>{ques.text}</Text>
                            <Controller
                                render={({ field }: any) => (
                                    <Select
                                        styles={questionSelectStyles}
                                        menuPortalTarget={document.body}
                                        ref={field.ref}
                                        placeholder="選択してください"
                                        options={optionsList}
                                        value={optionsList.find(
                                            (option) => option.value == getValues(`answerList.answers.${i}.answer`)
                                        )}
                                        onChange={(e: any) => {
                                            setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                            field.onChange(e?.value);
                                        }}
                                    />
                                )}
                                name={`answerList.answers.${i}.answer`}
                                control={control}
                                rules={{ required: true }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
                }
                case 3: //checkbox
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text style={styles.questionTitle}>{ques.text}</Text>
                            <Box flexDirection="row" justifyContent="space-between" flexWrap="wrap">
                                {ques.options.map((opt: any, index: number) => {
                                    return (
                                        <Box
                                            key={index}
                                            style={{ minWidth: '33%', marginTop: 10, flexDirection: 'row' }}
                                        >
                                            <Controller
                                                render={() => (
                                                    <Checkbox
                                                        _light={{
                                                            bg: Colors.lightGray,
                                                            _text: {
                                                                marginLeft: '0',
                                                                fontSize: 16
                                                            },
                                                            _icon: {
                                                                color: Colors.white
                                                            },
                                                            _checked: {
                                                                bg: Colors.primary,
                                                                _hover: {
                                                                    bg: Colors.primary
                                                                },
                                                                _text: {
                                                                    color: Colors.primary,
                                                                    fontWeight: '700'
                                                                }
                                                            }
                                                        }}
                                                        value={index.toString()}
                                                        borderWidth={0}
                                                        size={'md'}
                                                        isChecked={getValues(
                                                            `answerList.answers.${i}.answer`
                                                        )?.includes(index)}
                                                        onChange={(e) => {
                                                            handleCheckList(
                                                                index,
                                                                e.valueOf(),
                                                                getValues(`answerList.answers.${i}.answer`),
                                                                i
                                                            );
                                                            setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                                        }}
                                                    >
                                                        {opt.optionText}
                                                    </Checkbox>
                                                )}
                                                name={`answerList.answers.${i}.answer`}
                                                control={control}
                                                rules={{ required: true, minLength: 1 }}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                            <ErrorMessage
                                errors={errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
                case 4: //radio
                    questionFormlist.push(
                        <View key={i} style={styles.inputSpace}>
                            <Text style={styles.questionTitle}>{ques.text}</Text>
                            <Controller
                                render={({ field }: any) => (
                                    <Radio.Group
                                        name={ques.text}
                                        accessibilityLabel={ques.text}
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        flexWrap="wrap"
                                        value={String(getValues(`answerList.answers.${i}.answer`))}
                                        onChange={(e) => {
                                            setValue(`answerList.answers.${i}.questionNo`, i + 1);
                                            field.onChange(Number(e));
                                        }}
                                    >
                                        {ques.options.map((opt: any, i: number) => {
                                            return (
                                                <Box
                                                    key={i}
                                                    style={{ minWidth: '33%', marginTop: 10, flexDirection: 'row' }}
                                                >
                                                    <Radio
                                                        _light={{
                                                            bg: Colors.lightGray,
                                                            borderWidth: 0,
                                                            _text: {
                                                                fontSize: 16
                                                            },
                                                            _checked: {
                                                                _icon: {
                                                                    color: Colors.primary
                                                                },
                                                                _text: {
                                                                    color: Colors.primary,
                                                                    fontWeight: '700'
                                                                }
                                                            }
                                                        }}
                                                        value={i.toString()}
                                                        size={'md'}
                                                    >
                                                        {opt.optionText}
                                                    </Radio>
                                                </Box>
                                            );
                                        })}
                                    </Radio.Group>
                                )}
                                name={`answerList.answers.${i}.answer`}
                                control={control}
                                rules={{ required: true }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`answerList.answers.${i}.answer`}
                                render={() => <Text color={'red.500'}>入力は必須です</Text>}
                            />
                        </View>
                    );
                    break;
            }
        });
        return questionFormlist;
    }

    const onSubmit: SubmitHandler<{ answerList: any }> = async (data: { answerList: any }) => {
        setIsSending(true);
        data.answerList.editKey = props.editKey;
        data.answerList.tagIds = data.answerList.tagIds.filter((e: any) => {
            return !removalNum.includes(e);
        });

        //画像・動画のurl取得
        const fileList: Array<File | { url: string; fileType: string }> = [];

        try {
            // [TODO] サードバーティCookie問題があるため以下一旦コメントアウト
            // アップロードの前にFirebase AuthenticationにログインしてWebアプリ内のFirebase SDKに認証状態を保存する
            // await axios.post(`/checkAuth`, {isGettingToken: true}).then(async (response: any) => {
            //     if (response.data.token) {
            //         // カスタムトークンで認証
            //         await signInWithCustomToken(auth, response.data.token)
            //             .then(async (userCredential) => {
            //                 if (response.data.isNotExtended) {
            //                     return;
            //                 }

            //                 const { data } = await axios.get(`/csrf-token`);
            //                 const token = await userCredential.user.getIdToken();
            //                 const result = await axios.post(`/sessionLogin`, {
            //                     idToken: token,
            //                     csrfToken: data.csrfToken,
            //                 });

            //                 if (result.status == 200) {
            //                     return;
            //                 }

            //                 throw Error();
            //             });
            //     } else {
            //         if (response.data.status == 'error') {
            //             throw Error();
            //         }
            //     }
            // });

            if (contest.contestType == Consts.CONTEST_TYPES.IMAGE) {
                for (let index = 0; index < props.images.length; index++) {
                    const image = props.images[index];
                    let imageUrl;
                    if ('url' in image) {
                        imageUrl = image.url;
                    } else {
                        imageUrl = await uploadImageToFirebaseStorage(image, props.areaId, 'posts');
                    }
                    fileList.push({ url: imageUrl, fileType: 'images' });
                }
            } else if (contest.contestType == Consts.CONTEST_TYPES.VIDEO) {
                for (let index = 0; index < props.video.length; index++) {
                    const video = props.video[index];
                    let videoUrl;
                    if ('url' in video) {
                        videoUrl = video.url;
                    } else {
                        videoUrl = await uploadVideoToFirebaseStorage(video, props.areaId, 'posts');
                    }
                    fileList.push({ url: videoUrl, fileType: 'video' });
                }
            }

            data.answerList.files = fileList;
            setValue(`answerList.files`, fileList);

            //投稿処理
            axios.patch(props.apiUrl, data.answerList).then(() => {
                props.setModalComplete(true);
                props.setModalError(false);
                props.setPostErrorMessage('');
                props.setImages([]);
                props.setVideo([]);
                setIsSending(false);
            });
            console.log('onSubmit:', data);
        } catch (error) {
            props.setPostErrorMessage('投稿に失敗しました。時間をあけて再度お試しください。');
            props.setModalError(true);
            setTimeout(() => props.setModalError(false), 5000);
            setIsSending(false);
        }
    };

    useEffect(() => {
        getJobTypes();

        return () => {
            setJobTypeList([]);
        };
    }, []);

    return (
        <Modal isVisible={props.editModal} style={{ margin: 0 }}>
            <Pressable
                px="10px"
                onPress={() => {
                    props.setEditModal(false);
                }}
            >
                <Image style={styles.cross} source={require('../assets/images/cross.svg')} />
            </Pressable>
            <View style={styles.modalPost}>
                <ScrollView marginBottom="90px">
                    <Controller
                        render={({ field }: any) =>
                            TextArea({
                                placeholder: Ajusts.TenantNamePlaceholder,
                                multiline: false,
                                ref: field.ref,
                                value: getValues(`answerList.tenantName`),
                                onChange: (e: any) => setValue(`answerList.tenantName`, e.nativeEvent.text)
                            })
                        }
                        name={`answerList.tenantName`}
                        control={control}
                        rules={{ required: true }}
                    />
                    <ErrorMessage
                        errors={errors}
                        name={`answerList.tenantName`}
                        render={() => <Text color={'red.500'}>入力は必須です</Text>}
                    />

                    <Box style={styles.inputSpace}>
                        <Box flexDirection="row" alignItems="center" width={'100%'}>
                            <Text width={50} color={Colors.mediumGray}>
                                業種
                            </Text>
                            <Controller
                                render={({ field }: any) => (
                                    <Select
                                        styles={jobTypesStyles}
                                        menuPortalTarget={document.body}
                                        options={selectJobTypesList}
                                        placeholder="選択してください"
                                        value={selectJobTypesList.find(
                                            (option: { value: number; label: string }) =>
                                                option.value == getValues(`answerList.jobTypeId`)
                                        )}
                                        onChange={(e: any) => {
                                            field.onChange(e?.value);
                                        }}
                                    />
                                )}
                                name={`answerList.jobTypeId`}
                                control={control}
                                rules={{ required: true }}
                            />
                        </Box>
                        <ErrorMessage
                            errors={errors}
                            name={`answerList.jobTypeId`}
                            render={() => <Text color={'red.500'}>入力は必須です</Text>}
                        />
                    </Box>

                    <>{createPostForm()}</>

                    <Box flexDirection={'column'} style={styles.inputSpace}>
                        <Text style={styles.questionTitle}>ハッシュタグ</Text>
                        <Box style={styles.tagBox}>
                            {post.tags.map((tag: any, index: number) => {
                                const ref = useRef<HTMLDivElement>(null);
                                return (
                                    <div key={index} ref={ref}>
                                        <View style={styles.tags}>
                                            <Text style={styles.tag}>#{tag.name}</Text>
                                            <Pressable
                                                style={styles.sendButton}
                                                onPress={() => {
                                                    removalNum.push(tag.id);
                                                    if (ref.current) {
                                                        ref.current.style.display = 'none';
                                                    }
                                                }}
                                            >
                                                <Text style={styles.sendButtonText}>削除</Text>
                                            </Pressable>
                                        </View>
                                    </div>
                                );
                            })}
                        </Box>
                        <Controller
                            render={() => (
                                <AsyncCreatableSelect
                                    styles={tagsStyles}
                                    menuPortalTarget={document.body}
                                    loadOptions={loadOptions}
                                    placeholder="入力してください"
                                    value={viewTagList}
                                    isMulti
                                    cacheOptions
                                    loadingMessage={() => 'ハッシュタグを検索中...'}
                                    formatCreateLabel={(input: string) =>
                                        '新規ハッシュタグ「' + input.replace(/#|＃/g, '') + '」を作成'
                                    }
                                    noOptionsMessage={() => '入力すると候補が表示されます'}
                                    inputValue={selectInputValue}
                                    onInputChange={(newInputValue) =>
                                        setSelectInputValue(newInputValue.replace(/#|＃/g, ''))
                                    }
                                    onChange={(e: any) => {
                                        setViewTagList(
                                            e.map((item: any) => ({
                                                ...item,
                                                label: '#' + item.label.replace(/#|＃/g, ''),
                                                value:
                                                    typeof item.value == 'number'
                                                        ? item.value
                                                        : item.value.replace(/#|＃/g, '')
                                            }))
                                        );
                                        const tagIdList: Array<number> = [];
                                        const newTagList: Array<string> = [];
                                        post.tagIds.map((id: any) => {
                                            tagIdList.push(id);
                                        });
                                        for (const selected of e) {
                                            if (typeof selected.value == 'number') tagIdList.push(selected.value);
                                            else if (typeof selected.value == 'string')
                                                newTagList.push(selected.value.replace(/#|＃/g, ''));
                                        }
                                        setValue(`answerList.tagIds`, tagIdList);
                                        setValue(`answerList.newTags`, newTagList);
                                    }}
                                />
                            )}
                            name={`answerList.tagIds`}
                            control={control}
                        />
                    </Box>
                </ScrollView>
                <Box style={styles.buttonBox}>
                    <Box style={styles.PostButton}>
                        <Pressable
                            style={styles.backButton}
                            onPress={() => {
                                props.setEditModal(false);
                                props.setEditModalPicPhoto(true);
                            }}
                        >
                            <Text style={styles.buttonText}>戻る</Text>
                        </Pressable>
                    </Box>
                    <Box style={styles.PostButton}>
                        <Pressable
                            disabled={isSending}
                            onPress={() => {
                                setRemovalNum(removalNum), handleSubmit(onSubmit)();
                            }}
                        >
                            <LinearGradient
                                colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                                start={[0, 0]}
                                end={[1, 0]}
                                style={styles.nextButton}
                            >
                                {isSending ? (
                                    <Spinner
                                        color={Colors.white}
                                        size="sm"
                                        width={'fit-content'}
                                        paddingRight={50}
                                        paddingLeft={50}
                                    />
                                ) : (
                                    <Text style={styles.buttonText}>編集する</Text>
                                )}
                            </LinearGradient>
                        </Pressable>
                    </Box>
                    <Box style={styles.PostButton}>
                        <Pressable
                            disabled={isSending}
                            onPress={() => {
                                props.setDeleteModal(true);
                                props.setModalError(false);
                                props.setPostErrorMessage('');
                            }}
                        >
                            <LinearGradient
                                colors={[Colors.white, Colors.white]}
                                start={[0, 0]}
                                end={[1, 0]}
                                style={styles.deleteButton}
                            >
                                {isSending ? (
                                    <Spinner
                                        color={Colors.white}
                                        size="sm"
                                        width={'fit-content'}
                                        paddingRight={50}
                                        paddingLeft={50}
                                    />
                                ) : (
                                    <Text style={styles.deleteButtonText}>削除する</Text>
                                )}
                            </LinearGradient>
                        </Pressable>
                    </Box>
                </Box>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    cross: {
        height: 24,
        width: 24,
        marginTop: 17,
        position: 'absolute',
        right: 10
    },
    modalPost: {
        flex: 1,
        backgroundColor: Colors.white,
        marginTop: 59,
        paddingTop: 47,
        paddingLeft: 28,
        paddingRight: 28,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    inputSpace: {
        marginTop: '15px'
    },
    questionTitle: {
        fontSize: 14,
        fontWeight: '700'
    },
    PostButton: {
        width: 'fit-content',
        paddingVertical: 20,
        bottom: 0,
        borderTopWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.lighterGray
    },
    buttonText: {
        color: Colors.white,
        fontWeight: '700',
        paddingHorizontal: 25
    },
    nextButton: {
        width: 'fit-content',
        borderRadius: 50,
        height: 48,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 5
    },
    tagBox: {
        paddingBottom: '10px',
        marginBottom: '10px',
        borderBottomWidth: 2,
        borderBottomColor: Colors.lighterGray
    },
    tags: {
        flex: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: '10px',
        marginTop: '10px',
        borderTopWidth: 2,
        borderTopColor: Colors.lighterGray
    },
    tag: {
        fontSize: FontSize.small,
        fontWeight: '300',
        fontFamily: 'Noto Sans JP',
        color: Colors.primary
    },
    buttonBox: {
        position: 'absolute',
        flex: 2,
        textAlign: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        bottom: 0
    },
    sendButtonText: {
        color: Colors.white,
        fontWeight: 'bold'
    },
    sendButton: {
        maxWidth: '112px',
        width: '100%',
        alignSelf: 'flex-end',
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.mediumGray
    },
    deleteButtonText: {
        color: Colors.primary,
        fontWeight: '700',
        paddingHorizontal: 25
    },
    deleteButton: {
        width: 'fit-content',
        borderRadius: 50,
        height: 48,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 5,
        borderColor: Colors.primary,
        borderWidth: 2
    },
    backButton: {
        width: 'fit-content',
        borderRadius: 50,
        height: 48,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.mediumGray,
        marginHorizontal: 5
    }
});
