import React, { useRef, useEffect, useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Center, Box, Text, Pressable, IconButton, ScrollView, Flex } from 'native-base';
import { FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import Modal from 'react-native-modal';
import _ from 'lodash';
import axios from '../axios/axios';
import Title from '../stories/components/ButtonNative/Title';
import RoundButtonImage from '../stories/components/ButtonNative/RoundButtonImage';
import ProgressBar from '../stories/components/Bar/ProgressBar';
import FontSize from '../constants/FontSize';
import { useThemeColors } from './Themed';
import { ColorNames } from '../types';
import { Consts } from '../constants/Consts';
import { isValidFileExtension } from '../utils/utils';

export default function PicPhotoModal(props: any) {
    const Colors: ColorNames = useThemeColors();

    const maxImagesUpload = 5; // 画像を最大5枚まで選択・アップロード
    const inputRef = useRef<HTMLInputElement>(null);
    const inputId = Math.random().toString(32).substring(2);
    const [jobTypesReturnArray, setJobTypesReturnArray] = useState<any[] | undefined>([]);
    const jobTypeGetUrl = `/jobtypes?_sort=id`; //業種取得API
    const [selectedPhoto, setSelectedPhoto] = useState(true); //画像・動画の有無を検知
    const [fileError, setFileError] = useState<string>(''); //画像・動画の選択におけるエラー

    interface jobTypes {
        id: number; //1,2,3,4
        name: string; //"衣料品","雑貨（服飾雑貨・ホビー含む）","飲食（食物販含む）","サービス（シネマ・アミューズメント含む）"
    }

    const onPress = () => {
        inputRef.current?.click();
    };

    function handleOnAddImage(e: React.ChangeEvent<HTMLInputElement>) {
        setFileError('');

        if (!e.target.files) {
            return;
        } else if (props.fileAccept == Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION) {
            if (!e.target.files[0].type.includes('image')) {
                setFileError(`画像ファイル以外は投稿できません。`);
                return;
            }
            if (e.target.files[0].size > Consts.ACCEPTABLE_IMAGE_FILE_SIZE) {
                setFileError(
                    `ファイルのサイズは${Consts.ACCEPTABLE_IMAGE_FILE_SIZE / (1024 * 1024)}MB以下にしてください。`
                );
                return;
            }
            props.setImages([...props.images, e.target.files[0]]);
            e.target.value = '';
        } else {
            if (!e.target.files[0].type.includes('video')) {
                setFileError(`動画ファイル以外は投稿できません。`);
                return;
            }
            if (!isValidFileExtension(e.target.files[0].name, Consts.ACCEPTABLE_VIDEO_FILE_EXTENSION)) {
                setFileError(`「${e.target.files[0].name}」の動画の拡張子は対応していません。`);
                return;
            }
            if (e.target.files[0].size > Consts.ACCEPTABLE_VIDEO_FILE_SIZE) {
                setFileError(
                    `ファイルのサイズは${Consts.ACCEPTABLE_VIDEO_FILE_SIZE / (1024 * 1024)}MB以下にしてください。`
                );
                return;
            }
            props.setVideo([...props.video, e.target.files[0]]);
            e.target.value = '';
        }
        setSelectedPhoto(true);
    }

    function handleOnAddFile() {
        function handleOnRevideImage(index: number) {
            const newImage = [...props.images];
            newImage.splice(index, 1);
            props.setImages(newImage);
        }

        //アップロードした画像を画面に表示
        if (props.fileAccept == Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION && props.images != null) {
            return (
                <ScrollView horizontal={true}>
                    <Flex flexDirection={'row'} alignItems="center" top={0} left={0}>
                        {props.images.map((image: File, i: number) => (
                            <Center
                                key={i}
                                style={{
                                    position: 'relative',
                                    height: 180,
                                    width: 180,
                                    margin: 20,
                                    backgroundColor: Colors.darkerGray,
                                    borderRadius: 10
                                }}
                            >
                                <IconButton
                                    _icon={{
                                        as: MaterialIcons,
                                        name: 'cancel',
                                        color: Colors.black
                                    }}
                                    borderRadius="full"
                                    bg={Colors.white}
                                    aria-label="delete image"
                                    style={{
                                        position: 'absolute',
                                        top: -16,
                                        right: -16,
                                        padding: 0
                                    }}
                                    onPress={() => handleOnRevideImage(i)}
                                />
                                <img
                                    src={URL.createObjectURL(image)}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%'
                                    }}
                                />
                            </Center>
                        ))}
                    </Flex>
                </ScrollView>
            );
        } else if (props.fileAccept == Consts.ACCEPTABLE_VIDEO_FILE_EXTENSION && props.video != null) {
            return (
                <Flex flexDirection={'row'} alignItems="center" top={0} left={0}>
                    {props.video.map((vid: File, i: number) => (
                        <Center
                            key={i}
                            style={{
                                position: 'relative',
                                height: 180,
                                width: 180,
                                margin: 20,
                                backgroundColor: Colors.darkerGray,
                                borderRadius: 10
                            }}
                        >
                            <video
                                src={URL.createObjectURL(vid)}
                                style={{
                                    height: 180,
                                    width: 180
                                }}
                            />
                            <IconButton
                                _icon={{
                                    as: MaterialIcons,
                                    name: 'cancel',
                                    color: Colors.black
                                }}
                                borderRadius="full"
                                bg={Colors.white}
                                aria-label="delete image"
                                style={{
                                    position: 'absolute',
                                    top: 5,
                                    left: 5
                                }}
                                onPress={() => props.setVideo([])}
                            />
                        </Center>
                    ))}
                </Flex>
            );
        }
    }

    function selectJobTypes() {
        const returnList: Array<any> = [];
        const jobTypesList: Array<jobTypes> | undefined = jobTypesReturnArray;
        if (jobTypesList != undefined) {
            for (const jobtype of jobTypesList) {
                returnList.push({ value: jobtype.id, label: jobtype.name });
            }
        } else {
            console.log('jobTypeList Undefined');
        }
        props.setJobTypeList(returnList);
    }

    useEffect(() => {
        axios
            .get(jobTypeGetUrl)
            .then((result) => {
                setJobTypesReturnArray(result.data);
                return result.data;
            })
            .catch((error) => {
                console.log('通信失敗');
                console.log(error);
                setJobTypesReturnArray(undefined);
            });
    }, []);

    if (!props.selectedContest) return <></>;

    const styles = StyleSheet.create({
        modalPost: {
            flex: 1,
            backgroundColor: Colors.white,
            marginTop: 59,
            paddingTop: 47,
            paddingHorizontal: '5%',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            textAlign: 'center'
        },
        modalTitle: {
            textAlign: 'center',
            fontSize: 20,
            fontWeight: '700'
        },
        selectedContestTitle: {
            textAlign: 'center',
            fontSize: 13,
            marginVertical: 5
        },
        cross: {
            height: 24,
            width: 24,
            marginTop: 17,
            position: 'absolute',
            right: 10
        },
        backButton: {
            width: 'fit-content',
            borderRadius: 50,
            height: 48,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors.mediumGray,
            marginHorizontal: 5
        },
        PostButton: {
            position: 'absolute',
            textAlign: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            alignSelf: 'center',
            width: '100%',
            paddingVertical: 20,
            bottom: 0,
            borderTopWidth: 1,
            borderStyle: 'solid',
            borderColor: Colors.lighterGray
        },
        buttonText: {
            color: Colors.white,
            fontWeight: '700',
            paddingHorizontal: 25
        },
        nextButton: {
            width: 'fit-content',
            borderRadius: 50,
            height: 48,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors.primary,
            marginHorizontal: 5
        },
        error: {
            color: 'red'
        },
        attention: {
            color: Colors.primary,
            fontSize: FontSize.small,
            textAlign: 'left',
            fontWeight: '700',
            marginLeft: 5
        }
    });

    return (
        <Modal
            isVisible={props.modalPicPhoto}
            animationIn="slideInRight"
            animationOut="slideOutRight"
            style={{ margin: 0, flex: 1, width: '100%', height: '100%' }}
        >
            <Pressable
                px="10px"
                onPress={() => {
                    props.setModalPicPhoto(false);
                }}
            >
                <Image style={styles.cross} source={require('../assets/images/cross.svg')} />
            </Pressable>
            <View style={styles.modalPost}>
                {ProgressBar({ progressWidth: 50 })}
                {Title({
                    text:
                        props.fileAccept == Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION
                            ? '画像を選ぶ(STEP2/3)'
                            : props.fileAccept == Consts.ACCEPTABLE_VIDEO_FILE_EXTENSION
                            ? '動画を選ぶ(STEP2/3)'
                            : '画像・動画を選ぶ',
                    fontSize: FontSize.large,
                    fontWeight: '600'
                })}
                <Text style={styles.selectedContestTitle}>{props.selectedContest?.title}</Text>
                <Box maxWidth="320px" textAlign="left" marginLeft="auto" marginRight="auto" marginBottom="5px">
                    {props.selectedContest.contestType == 0 ? (
                        <>
                            <Box flexDirection="row" textAlign="left" alignItems="center">
                                <FontAwesome5 name="check" size={FontSize.small} color={Colors.primary} />
                                <Text style={styles.attention}>1枚ずつ追加してください。</Text>
                            </Box>
                            <Box flexDirection="row" textAlign="left" alignItems="center">
                                <FontAwesome5 name="check" size={FontSize.small} color={Colors.primary} />
                                <Text style={styles.attention}>1枚目の画像がホーム画面に表示されます。</Text>
                            </Box>
                            <Box flexDirection="row" textAlign="left" alignItems="center">
                                <Box>
                                    <FontAwesome5 name="check" size={FontSize.small} color={Colors.primary} />
                                    <Text>{'\n'}</Text>
                                </Box>
                                <Text style={styles.attention}>
                                    ディスプレイ、POPのコンテストの場合は{'\n'}2枚目に店舗全景の画像を投稿して下さい。
                                </Text>
                            </Box>
                            <Box flexDirection="row" textAlign="left" alignItems="center">
                                <FontAwesome5 name="check" size={FontSize.small} color={Colors.primary} />
                                <Text style={styles.attention}>他アピールしたい写真を含めて最大5枚追加できます。</Text>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box flexDirection="row" textAlign="left" alignItems="center">
                                <FontAwesome5 name="check" size={FontSize.small} color={Colors.primary} />
                                <Text style={styles.attention}>１つ動画ファイルのみ投稿できます。</Text>
                            </Box>
                            <Box flexDirection="row" textAlign="left" alignItems="center">
                                <FontAwesome5 name="check" size={FontSize.small} color={Colors.primary} />
                                <Text style={styles.attention}>
                                    動画のサイズは最大{Consts.ACCEPTABLE_VIDEO_FILE_SIZE / (1024 * 1024)}MBです。
                                </Text>
                            </Box>
                        </>
                    )}
                </Box>
                {RoundButtonImage({
                    text: 'ファイルを選択',
                    borderColor: Colors.primary,
                    fontColor: Colors.primary,
                    onPress: onPress,
                    disabled: props.images.length >= maxImagesUpload || props.video.length > 0,
                    backGroundColor: Colors.white,
                    width: '100%',
                    maxWidth: '100%'
                })}
                <input
                    id={inputId}
                    ref={inputRef}
                    multiple={true}
                    type="file"
                    accept={props.fileAccept}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnAddImage(e)}
                    style={{ display: 'none' }}
                />
                <div>{handleOnAddFile()}</div>
                {selectedPhoto == false && (props.images.length == 0 || props.video.length == 0) ? (
                    <Box style={{ marginTop: 10, alignItems: 'center' }}>
                        {props.selectedContest.contestType == 0 ? (
                            <Text style={styles.error}>画像を選択して下さい</Text>
                        ) : (
                            <Text style={styles.error}>動画を選択して下さい</Text>
                        )}
                    </Box>
                ) : (
                    <></>
                )}
                {fileError ? (
                    <Box style={{ marginTop: 10, alignItems: 'center' }}>
                        <Text style={styles.error}>{fileError}</Text>
                    </Box>
                ) : null}
                <Box style={styles.PostButton}>
                    <Pressable
                        style={styles.backButton}
                        onPress={() => {
                            props.setModalPost(true);
                            props.setModalPicPhoto(false);
                        }}
                    >
                        <Text style={styles.buttonText}>戻る</Text>
                    </Pressable>
                    <Pressable
                        style={[
                            styles.nextButton,
                            props.images.length > 1 || props.video.length > 0
                                ? { backgroundColor: Colors.primary }
                                : { backgroundColor: Colors.lightGray }
                        ]}
                        onPress={
                            props.images.length > 1 || props.video.length > 0
                                ? () => {
                                      props.setModalDetail(true);
                                      props.setModalPicPhoto(false);
                                      selectJobTypes();
                                  }
                                : () => setSelectedPhoto(false)
                        }
                    >
                        <Text style={styles.buttonText}>次へすすむ</Text>
                    </Pressable>
                </Box>
            </View>
        </Modal>
    );
}
