import { Box, Center, HStack, Image, Spinner } from 'native-base';
import React from 'react';
import { Button, ScrollView, View, Text, StyleSheet, Pressable } from 'react-native';
import FontSize from '../../../constants/FontSize';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const ContestBar = (props: any) => {
    const { contestName, onPress, modalVisible, setModalVisible, ...rest } = props;

    return (
        <Pressable
            onPress={modalVisible ? setModalVisible : onPress}
            style={{
                width: '100%',
                alignItems: 'center'
            }}
        >
            <HStack
                style={{ flexDirection: 'row' }}
                paddingLeft="17px"
                paddingRight="17px"
                alignItems="center"
                height="44px"
                width="100%"
                backgroundColor={Colors.white}
                justifyContent="space-between"
                borderBottomWidth={1}
                borderBottomColor={modalVisible ? Colors.lightGray : Colors.lightestGray}
            >
                <Box flexDirection="row" alignItems="center">
                    <Text style={{ color: Colors.black, fontWeight: '700' }}>
                        {contestName ? (
                            contestName
                        ) : (
                            <Spinner
                                color={Colors.white}
                                size="sm"
                                width={'fit-content'}
                                paddingRight={50}
                                paddingLeft={50}
                            />
                        )}
                    </Text>
                </Box>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={styles.guidance}>{modalVisible ? '閉じる' : 'コンテストを切り替える'}</Text>
                    <Image
                        height={'15px'}
                        width={'8.83px'}
                        source={require('../../../assets/images/detail-gray.svg')}
                    />
                </View>
            </HStack>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    backText: {
        marginLeft: '15px',
        fontWeight: '400',
        fontSize: FontSize.medium,
        color: Colors.mediumGray
    },
    guidance: {
        marginRight: '6px',
        fontSize: FontSize.small,
        color: Colors.mediumGray
    }
});

export default ContestBar;
