import {
    BrowserRouter,
    Routes as WebRoutes,
    Route as WebRoute,
    Link as WebLink,
    Outlet as WebOutlet,
    useNavigate as WebUseNavigate,
    useLocation as WebUseLocation,
    useOutletContext as WebUseOutletContext,
    useParams as WebUseParams,
    useSearchParams as WebUseSearchParams
} from 'react-router-dom';

export const Router = BrowserRouter;
export const Routes = WebRoutes;
export const Route = WebRoute;
export const Link = WebLink;
export const Outlet = WebOutlet;
export const useNavigate = WebUseNavigate;
export const useLocation = WebUseLocation;
export const useOutletContext = WebUseOutletContext;
export const useParams = WebUseParams;
export const useSearchParams = WebUseSearchParams;
