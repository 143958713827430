import axios from '../axios/axios';
import { HStack, View } from 'native-base';
import { useEffect, useState, useContext } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { useNavigate } from '../router/react-router';
import DetailCard from '../stories/components/Cards/DetailCard';
import NewDetailCard from '../stories/components/Cards/NewDetailCard';
import { formatDate } from '../utils/utils';
import AreaSelect from '../components/AreaSelect';
import { GlobalContext } from '../contexts/Global';
import { AuthContext } from '../contexts/Auth';
import UserNameBar from '../stories/components/Bar/UserNameBar';
import { useThemedAjusts } from '../components/Themed';
import { ThemedAjustType } from '../types';

const Ajusts: ThemedAjustType = useThemedAjusts();

export default function Screen(props: any) {
    const navigate = useNavigate();
    const goToDetail = (contestId: number) => navigate(`${contestId}`);
    const dateFormat = (date: any) => {
        return `${formatDate(new Date(date), 'yyyy.MM.dd')}`;
    };
    const { areaId } = useContext(GlobalContext);
    const { currentUser } = useContext(AuthContext);

    const [contests, setContests] = useState([]);
    const [newContest, setNewContest] = useState<any>();

    const getList = () => {
        if (!currentUser) return console.log('can not read');

        let params = '?';
        let targetArea = areaId;
        if (targetArea == 0) {
            targetArea = currentUser.areaId;
        }
        if (targetArea > 0) {
            params += 'areaId=' + targetArea;
        }
        // ログインSC単独開催のコンテストも取得
        params += '&mallId=' + currentUser.id;
        // 審査済みのコンテストを取得
        params += '&is_completed=true';
        params += '&_order=DESC&_sort=applicationEnd';

        const apiUrl = `/contests${params}`;
        axios.get(apiUrl).then((res) => {
            setContests(res.data.slice(1));
            setNewContest(res.data[0]);
        });
    };
    useEffect(() => {
        getList();
    }, [areaId]);

    return (
        <View paddingBottom={10}>
            {currentUser ? <UserNameBar mallName={currentUser.mallName} /> : null}
            <AreaSelect />
            <>
                {newContest ? (
                    <View>
                        <Pressable style={{ paddingBottom: 30 }} onPress={() => goToDetail(newContest.id)}>
                            <NewDetailCard
                                title={newContest.title}
                                area={newContest.areaName + '地区'}
                                imageUrl={newContest.imageUrl}
                                description={
                                    dateFormat(newContest.applicationStart) +
                                    ' - ' +
                                    dateFormat(newContest.applicationEnd)
                                }
                            />
                        </Pressable>
                    </View>
                ) : (
                    <View>
                        <HStack flexDirection="column" display={Ajusts.Display}>
                            <Pressable onPress={() => window.open('https://aeondoyu.com/vp/2022/index.html', '_blank')}>
                                <NewDetailCard
                                    title={'2022年上期　結果発表'}
                                    area={'全国地区'}
                                    imageUrl={require('../assets/images/vp_contest.png')}
                                    description={'2022年上期'}
                                />
                            </Pressable>
                        </HStack>
                    </View>
                )}
                {contests.map((data: any, index: number) => {
                    return (
                        <View key={data.id}>
                            <HStack flexDirection="column">
                                <Pressable onPress={() => goToDetail(data.id)}>
                                    <DetailCard
                                        title={data.title}
                                        area={data.areaName + '地区'}
                                        description={
                                            dateFormat(data.applicationStart) + ' - ' + dateFormat(data.applicationEnd)
                                        }
                                    />
                                </Pressable>
                            </HStack>
                        </View>
                    );
                })}
                {newContest ? (
                    <View>
                        <HStack flexDirection="column" display={Ajusts.Display}>
                            <Pressable onPress={() => window.open('https://aeondoyu.com/vp/2022/index.html', '_blank')}>
                                <DetailCard
                                    title={'2022年上期　結果発表'}
                                    area={'全国地区'}
                                    description={'2022年上期'}
                                />
                            </Pressable>
                        </HStack>
                    </View>
                ) : (
                    <></>
                )}
            </>
        </View>
    );
}
const styles = StyleSheet.create({
    display: {
        display: Ajusts.Display
    }
});
