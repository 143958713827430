import React, { useRef, useState } from 'react';
import {
    Animated,
    InteractionManager,
    StyleSheet,
    Button,
    ScrollView,
    TouchableOpacity,
    View,
    Text,
    Pressable
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { IconButton } from 'native-base';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const LikeButton = (props: any) => {
    const { isLiked, onPress, ...rest } = props;
    const Colors: ColorNames = useThemeColors();

    return (
        <IconButton
            {...rest}
            padding={0}
            style={{
                justifyContent: 'center'
            }}
            _icon={{
                as: AntDesign,
                name: !isLiked ? 'hearto' : 'heart',
                // name: "heart",
                color: !isLiked ? Colors.black : Colors.primary,
                // color: Colors.primary,
                size: 'sm'
            }}
            _hover={{ backgroundColor: 'transparent' }}
            onPress={props.onPress}
        />
    );
};

export default LikeButton;
