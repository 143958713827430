import React from 'react';
import { Text } from 'react-native';
import { Box } from 'native-base';
import FontSize from '../../../constants/FontSize';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

export default function UserNameBar(props: any) {
    const { mallName, ...rest } = props;

    return (
        <Box backgroundColor={Colors.white}>
            <Box
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                paddingTop="9px"
                paddingBottom="9px"
                paddingLeft="20px"
                paddingRight="20px"
                minHeight={53}
            >
                {mallName ? (
                    <Text
                        style={{
                            color: Colors.black,
                            fontSize: FontSize.medium,
                            fontWeight: '800',
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        ようこそ {mallName} さん
                    </Text>
                ) : null}
            </Box>
        </Box>
    );
}
