import React from 'react';

const Ribbon = (props: any) => {
    const { text, bgColor, textColor, fontSize } = props;
    return (
        <p
            style={{
                backgroundColor: bgColor,
                padding: text ? '5px 10px' : 0,
                display: 'inline-block',
                color: textColor,
                fontSize: fontSize,
                margin: 0
            }}
        >
            {text}
        </p>
    );
};
export default Ribbon;
