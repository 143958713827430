export default {
    // light:本番, dark:未使用
    light: {
        primary: '#AF1D7B',
        primaryGradientStart: '#AF1D7B',
        primaryGradientEnd: '#FF60A3',
        error: '#F06060',
        black: '#020202',
        darkerGray: '#4c4c4c',
        darkGray: '#616161',
        mediumGray: '#999999',
        lightGray: '#CCCCCC',
        lighterGray: '#EEEEEE',
        lightestGray: '#F8F8F8',
        white: '#FFFFFF',
        transparentBlack: '#0000007d',
        text: '#000',
        background: '#fff'
    },
    dark: {
        primary: '#AF1D7B',
        primaryGradientStart: '#AF1D7B',
        primaryGradientEnd: '#FF60A3',
        error: '#F06060',
        black: '#020202',
        darkerGray: '#4c4c4c',
        darkGray: '#616161',
        mediumGray: '#999999',
        lightGray: '#CCCCCC',
        lighterGray: '#EEEEEE',
        lightestGray: '#F8F8F8',
        white: '#FFFFFF',
        transparentBlack: '#0000007d',
        text: '#fff',
        background: '#000'
    },
    disney: {
        primary: '#4ABAB2',
        primaryGradientStart: '#4ABAB2',
        primaryGradientEnd: '#4ABAB2',
        error: '#F06060',
        black: '#020202',
        darkerGray: '#4c4c4c',
        darkGray: '#616161',
        mediumGray: '#999999',
        lightGray: '#CCCCCC',
        lighterGray: '#EEEEEE',
        lightestGray: '#F8F8F8',
        white: '#FFFFFF',
        transparentBlack: '#0000007d',
        text: '#000',
        background: '#fff'
    }
};
