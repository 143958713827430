import React from 'react';
import { Center, Heading, Box, AspectRatio, Stack, Text, HStack, Image } from 'native-base';
import FontSize from '../../../constants/FontSize';
import { ImageBackground, TouchableOpacity, StyleSheet, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigate } from '../../../router/react-router';
import { formatDate } from '../../../utils/utils';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const dateFormat = (date: any) => {
    return `${formatDate(new Date(date), 'yyyy.MM.dd')}`;
};

export default function PickUpRecommendCard(props: any) {
    const recommendInfo = props.recommendInfo;
    const navigate = useNavigate();
    const goToDetail = () => {
        if (recommendInfo.youtubeUrl) {
            window.open(recommendInfo.youtubeUrl, '_blank');
        } else if (recommendInfo.url) {
            window.open(recommendInfo.url, '_blank');
        } else {
            navigate(`/recommend/${recommendInfo.id}`);
        }
    };

    return (
        <TouchableOpacity
            style={{
                width: '100%'
            }}
            onPress={goToDetail}
        >
            <View style={styles.container}>
                <ImageBackground
                    style={styles.image}
                    source={
                        recommendInfo.imageUrl
                            ? { uri: recommendInfo.imageUrl }
                            : require('../../../assets/images/topResult.svg')
                    }
                    resizeMode={'contain'}
                >
                    <Box
                        style={{
                            padding: '5px',
                            width: 'fit-content',
                            backgroundColor: Colors.transparentBlack
                        }}
                    >
                        <Box alignItems="center" flexDirection="row" marginBottom="7px">
                            <LinearGradient
                                colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                                start={[1, 0]}
                                end={[1, 0]}
                                style={{
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    marginRight: 11,
                                    height: 21,
                                    width: 60,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: FontSize.xsmall,
                                        color: Colors.white,
                                        fontWeight: '700'
                                    }}
                                >
                                    NEW!
                                </Text>
                            </LinearGradient>
                            <Text
                                style={{
                                    color: Colors.white,
                                    fontWeight: '700',
                                    fontSize: FontSize.xsmall
                                }}
                            >
                                {dateFormat(recommendInfo.publishStart)}
                            </Text>
                        </Box>
                        <Text
                            style={{
                                fontSize: FontSize.large,
                                color: Colors.white,
                                fontWeight: '700'
                            }}
                        >
                            {recommendInfo.title}
                        </Text>
                    </Box>
                </ImageBackground>
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        height: '250px',
        width: '100%',
        backgroundColor: Colors.black
    },
    image: {
        flex: 1,
        justifyContent: 'flex-end',
        resizeMode: 'cover',
        padding: '20px',
        backgroundColor: Colors.transparentBlack
    }
});
