import axios from '../axios/axios';
import { Box, Text, Spinner } from 'native-base';
import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, View } from 'react-native';
// import { useParams } from "react-router-dom";
import AreaSelect from '../components/AreaSelect';
import RankingContestSelect from '../components/RankingContestSelect';
import TagsBar from '../stories/components/Bar/TagsBar';
import RoundButtonGradient from '../stories/components/ButtonNative/RoundButtonGradient';
import RankingCard from '../stories/components/Cards/RankingCard';
import RankingArea from '../stories/components/Screen/RankingArea';
import { GlobalContext } from '../contexts/Global';
import UserNameBar from '../stories/components/Bar/UserNameBar';
import { AuthContext } from '../contexts/Auth';
import { useThemeColors } from '../components/Themed';
import { ColorNames } from '../types';
import FontSize from '../constants/FontSize';

const Colors: ColorNames = useThemeColors();

export default function RankingScreen() {
    const { areaId } = useContext(GlobalContext);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [rankingData, setRankingData] = useState<any[]>([]);
    const [contestNames, setContestNames] = useState<any[]>([]);
    const [selectedContestIndex, setSelectedContestIndex] = useState<number | null>(null);
    const { currentUser } = useContext(AuthContext);
    const [postedRate, setPostedRate] = useState<any[]>([]);

    //対象ランキング取得
    const getRanking = async () => {
        const apiUrl = `/rankings/${areaId}`;
        const contestNames: any[] = [];

        await axios.get(apiUrl).then((res) => {
            res.data.settings.forEach((e: any) => {
                contestNames.push({
                    id: e.filters.contestId,
                    title: e.filters.contestTitle,
                    aggregationPeriod: {
                        aggregationPeriodStart: new Date(e.aggregationPeriodStart).toISOString(),
                        aggregationPeriodEnd: new Date(e.aggregationPeriodEnd).toISOString()
                    }
                });
            });
        });

        // エリア切り替え時は一旦選択のコンテストを null にする
        setSelectedContestIndex(null);

        setContestNames(contestNames);
    };

    //対象コンテストに投稿しているモールIDを取得
    const getPost = async (contestId: number, aggregationPeriod: any) => {
        const apiUrl = `/posts?contestId=${contestId}&periodStart=${aggregationPeriod.aggregationPeriodStart}&periodEnd=${aggregationPeriod.aggregationPeriodEnd}`;
        const mallIds: any[] = [];

        await axios.get(apiUrl).then((res) => {
            res.data.forEach((e: any) => {
                mallIds.push(e.mallId);
            });
        });
        await getMall(mallIds);
    };

    //対象のモール名を取得
    const getMall = async (mallIds: Array<[]>) => {
        //重複したmallIdを削除
        const deduplicatesMallIds = Array.from(new Set(mallIds));
        const countMallNames: any = [];
        const postedRateMallNames: any = [];

        const getMallNames = async (item: any) => {
            const apiUrl = `/malls?id=${item}`;

            await axios.get(apiUrl).then((res) => {
                countMallNames.push({
                    id: res.data[0].id,
                    mallName: res.data[0].mallName
                });
                postedRateMallNames.push({
                    id: res.data[0].id,
                    mallName: res.data[0].mallName,
                    numberOfTenants: res.data[0].numberOfTenants
                });
            });
        };

        await Promise.all(deduplicatesMallIds.map((item) => getMallNames(item)));

        //モールごとの投稿数カウント
        const counts: any = {};

        for (let i = 0; i < mallIds.length; i++) {
            const elm: any = mallIds[i];
            counts[elm] = (counts[elm] || 0) + 1;
        }

        const mallCounts = Object.keys(counts).map(function (key) {
            return { id: Number(key), count: counts[key] };
        });

        mergeMallObject(countMallNames, mallCounts);
        postTenants(postedRateMallNames, mallCounts);
    };

    //投稿数を降順でsort
    function compare(a: any, b: any) {
        const countA = a.count;
        const countB = b.count;

        let comparison = 0;
        if (countA < countB) {
            comparison = 1;
        } else if (countA > countB) {
            comparison = -1;
        }
        return comparison;
    }

    // モールごとの投稿数とテナント数を割る
    const postTenants = (mallNames: any, mallCounts: any) => {
        for (let i = 0; i < mallNames.length; i++) {
            for (let j = 0; j < mallCounts.length; j++) {
                if (mallNames[i].id === mallCounts[j].id) {
                    mallNames[i].count = (mallCounts[j].count / mallNames[i].numberOfTenants) * 100;
                }
            }
        }

        mallNames.sort(compare);

        console.log('postedRateMallNames', mallNames);

        setPostedRate(mallNames);
        setIsLoading(true);
    };

    const mergeMallObject = (mallNames: any, mallCounts: any) => {
        for (let i = 0; i < mallNames.length; i++) {
            for (let j = 0; j < mallCounts.length; j++) {
                if (mallNames[i].id === mallCounts[j].id) {
                    mallNames[i].count = mallCounts[j].count;
                }
            }
        }

        mallNames.sort(compare);

        setRankingData(mallNames);
        setIsLoading(true);
    };

    useEffect(() => {
        if (!areaId) return;
        setIsLoading(false);
        getRanking();
        console.log('Start getRanking()');
    }, [areaId]);

    useEffect(() => {
        if (!(contestNames?.length > 0)) return;
        if (selectedContestIndex === null) return setSelectedContestIndex(0);
        console.log('Start GetPost() for rankings.');

        setIsLoading(false);
        //プルダウンで選択したコンテストの結果を表示
        getPost(contestNames[selectedContestIndex].id, contestNames[selectedContestIndex].aggregationPeriod);
    }, [contestNames, selectedContestIndex]);

    if (selectedContestIndex === null)
        return (
            <Box justifyContent={'center'} alignItems={'center'} height={'full'}>
                <Spinner color={Colors.primary} size="lg" />
            </Box>
        );

    return (
        <View style={styles.container}>
            {currentUser ? <UserNameBar mallName={currentUser.mallName} /> : null}
            <AreaSelect />
            {isLoading ? (
                <Box>
                    <RankingContestSelect
                        contestNames={contestNames}
                        selectedContestId={selectedContestIndex}
                        setSelectedContestId={setSelectedContestIndex}
                    />
                    <RankingArea
                        contestNames={contestNames}
                        selectedContestId={selectedContestIndex}
                        rankingTitle={'SC投稿数ランキング'}
                        rankingData={rankingData}
                    />
                    <RankingArea
                        contestNames={contestNames}
                        selectedContestId={selectedContestIndex}
                        rankingData={postedRate}
                        rankingTitle={
                            <>
                                <Text style={styles.postedRateTitle}>SC</Text>
                                <Text
                                    style={{
                                        fontSize: FontSize.xlarge,
                                        fontWeight: '700',
                                        color: Colors.white,
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    投稿率
                                </Text>
                                <Text
                                    style={{
                                        fontSize: FontSize.xsmall,
                                        fontWeight: '700',
                                        color: Colors.white
                                    }}
                                >
                                    ※1
                                </Text>
                                <Text style={styles.postedRateTitle}>ランキング</Text>
                            </>
                        }
                        annotation={
                            <>
                                <>{`*1 SC毎の、投稿数÷`}</>
                                <Text
                                    style={{
                                        fontSize: undefined,
                                        color: 'inherit',
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    同友店会員数
                                </Text>
                                <>{`(*2)×100 \n*2 コンテスト開始後の増減は含まず`}</>
                            </>
                        }
                    />
                </Box>
            ) : (
                <Box justifyContent={'center'} alignItems={'center'} height={'full'}>
                    <Spinner color={Colors.primary} size="lg" />
                </Box>
            )}

            {/* <View
        style={{
          width: "100%",
          borderBottomWidth: 1,
          borderBottomColor: Colors.lightGray,
          left: 0,
          marginTop: "30px",
        }}
      />
      <View style={{ margin: 20, backgroundColor: "transparent" }}>
        <TagsBar tagName="ハッシュタグ" />
        {ranking.map((number: any, index: number) => {
          return (
            <Box
              key={index}
              paddingBottom="10px"
              backgroundAttachment="transparent"
            >
              <RankingCard
                tagName="コンテスト名ABC"
                postImage={require("../assets/images/topResult.svg")}
                likes={200}
                postName="コンテスト"
                tenantName="イオンモール神戸北"
                number={number.number}
                numberColor={number.color}
              />
            </Box>
          );
        })}
      </View>
      <View
        style={{
          width: "100%",
          borderBottomWidth: 1,
          borderBottomColor: Colors.lightGray,
          left: 0,
        }}
      />
      <View style={{ margin: 20, backgroundColor: "transparent" }}>
        <TagsBar tagName="ハッシュタグ" />
        {ranking.map((number: any, index: number) => {
          return (
            <Box
              key={index}
              paddingBottom="10px"
              backgroundAttachment="transparent"
            >
              <RankingCard
                tagName="コンテスト名ABC"
                postImage={require("../assets/images/topResult.svg")}
                likes={200}
                postName="コンテスト"
                tenantName="イオンモール神戸北"
                number={number.number}
                numberColor={number.color}
              />
            </Box>
          );
        })}
      </View> */}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'transparent'
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%'
    },
    postedRateTitle: {
        fontSize: FontSize.xlarge,
        fontWeight: '700',
        color: Colors.white
    }
});
