import axios from '../axios/axios';
import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, View, Text, Image, Button } from 'react-native';
import { HStack, Pressable, Center, Input } from 'native-base';
import { useNavigate } from '../router/react-router';
import { GlobalContext } from '../contexts/Global';
import SearchArea from '../stories/components/Drawer/SearchArea';
import Zindex from '../constants/Zindex';
import { useThemeColors, useThemedAjusts } from './Themed';
import { ColorNames, ThemedAjustType } from '../types';

const Colors: ColorNames = useThemeColors();
const Ajusts: ThemedAjustType = useThemedAjusts();

export default function CustomSearchModal({ searchModalVisible, setSearchModalVisible }: any) {
    const [tags, setTags] = useState<any[]>([]);
    const navigate = useNavigate();
    const { keyword, setKeyword, inputWord, setInputWord, searchModalActive, setSearchModalActive } =
        useContext(GlobalContext);

    const onPressTag = (text: any) => {
        setSearchModalActive(false);
        setKeyword('#' + text);
        setInputWord('#' + text);
        navigate('/posts');
    };

    const onPressSearch = () => {
        setSearchModalActive(false);
        setKeyword(inputWord);
        setInputWord(inputWord);
        navigate('/posts');
    };

    const getList = (text: any) => {
        setInputWord(text);
        // データ取得URL
        if (text) {
            const apiUrl = `/tags?name_like=${text.replace('#', '')}`;
            axios.get(apiUrl).then((res) => {
                setTags(res.data);
            });
        } else {
            setTags([]);
        }
    };

    useEffect(() => {
        getList('');
        setInputWord(keyword);
        // setSearchModalActive(searchModalVisible);
    }, [searchModalVisible]);

    return (
        <>
            {searchModalVisible ? (
                <View style={styles.modalWapper}>
                    {/* backdrop */}
                    {searchModalActive ? (
                        <Pressable onPress={() => setSearchModalVisible(false)}>
                            <View style={styles.backdrop} />
                        </Pressable>
                    ) : null}
                    {/* modal contents */}
                    <SearchArea
                        suggestions={tags}
                        onPress={onPressTag}
                        onChangeText={getList}
                        value={inputWord}
                        onPressSearch={onPressSearch}
                        active={searchModalActive}
                        setActive={setSearchModalActive}
                    />
                </View>
            ) : null}
        </>
    );
}

const styles = StyleSheet.create({
    modalWapper: {
        top: Ajusts.DrawerTop,
        zIndex: Zindex.searchModal,
        position: 'absolute',
        flex: 1,
        width: '100%'
    },
    backdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: Colors.black,
        opacity: 0.5,
        flex: 1,
        width: '100vw',
        height: '100vh',
        zindex: Zindex.searchModalBackDrop
    }
});
