declare global {
    interface Window {
        dataLayer: Record<string, unknown>[];
    }
}

// GTMを使ってGA4へカスタムイベント（カスタム変数を使用）を送信
export const sendLoginUserInfo = (scId: string, scName: string): void => {
    window.dataLayer.push({
        event: 'login_user',
        sc_id: scId,
        sc_name: scName
    });
};
