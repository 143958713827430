import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { Pressable, Image, HStack, Center, Box } from 'native-base';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from '../router/react-router';
import BackBar from '../stories/components/Bar/BackBar';
import axios from '../axios/axios';
import { LinearGradient } from 'expo-linear-gradient';
import RoundButton from '../stories/components/ButtonNative/RoundButton';
import ResultPrize from '../components/ResultPrize';
import { formatDate } from '../utils/utils';
import { GlobalContext } from '../contexts/Global';
import { Platform } from 'react-native';
import UserNameBar from '../stories/components/Bar/UserNameBar';
import { AuthContext } from '../contexts/Auth';
import { useThemeColors, useThemedAjusts } from '../components/Themed';
import { ColorNames, ThemedAjustType } from '../types';

const Colors: ColorNames = useThemeColors();
const Ajusts: ThemedAjustType = useThemedAjusts();

const dateFormat = (date: any) => {
    return `${formatDate(new Date(date), 'yyyy.MM.dd')}`;
};

export default function ResultsDetailScreen(props: any) {
    const { id } = useParams();
    const { scrollViewRef } = useContext(GlobalContext);
    const { currentUser } = useContext(AuthContext);

    const navigate = useNavigate();
    const goBack = () => {
        navigate('/results');
    };
    const { width } = Dimensions.get('window');

    const GrandRef = useRef<View>(null);
    const excellenceRef = useRef<View>(null);
    const fightSpiritRef = useRef<View>(null);

    const goToSection = (argRef: React.RefObject<View>) => {
        argRef?.current?.measure((fx, fy, width, height, px, py) => {
            if (scrollViewRef.current) {
                scrollViewRef.current.scrollTo({ x: 0, y: fy, animated: true });
            }
        });
    };

    //対象コンテスト取得
    const [contest, setContest] = useState<any>();
    const getContest = () => {
        const apiUrl_contest = `/contests/${id}`;
        axios.get(apiUrl_contest).then((res) => {
            setContest(res.data);
        });
    };

    /**
     * grand:大賞
     * excellence:優秀賞
     * fightSpirit:敢闘賞
     */

    //大賞取得
    const apiUrl_prize = `/posts?contestId=${id}&noEditKey=1`;
    //const apiUrl_prize = `http://localhost:3003/posts?contestId=${id}&_expand=mall&_expand=area`;
    const apiUrl_grand = `${apiUrl_prize}&prize=3`;
    const [grand, setGrand] = useState([]);
    const getGrand = () => {
        axios.get(apiUrl_grand).then((res) => {
            setGrand(res.data);
        });
    };
    //優秀賞取得
    const apiUrl_excellence = `${apiUrl_prize}&prize=2`;
    const [excellence, setExcellence] = useState([]);
    const getExcellence = () => {
        axios.get(apiUrl_excellence).then((res) => {
            setExcellence(res.data);
        });
    };
    //敢闘賞取得
    const apiUrl_fightSpirit = `${apiUrl_prize}&prize=1`;
    const [fightSpirit, setFightSpirit] = useState([]);
    const getFightSpirit = () => {
        axios.get(apiUrl_fightSpirit).then((res) => {
            setFightSpirit(res.data);
        });
    };
    useEffect(() => {
        getContest();
        getGrand();
        getExcellence();
        getFightSpirit();
    }, []);
    return (
        <>
            {currentUser ? <UserNameBar mallName={currentUser.mallName} /> : null}
            <BackBar backgroundColor={Colors.white} onPress={goBack} />
            <View style={styles.container}>
                <LinearGradient
                    colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                    style={{ height: 145, width: '100%', justifyContent: 'center' }}
                >
                    {contest ? (
                        <Box paddingTop="25px" paddingRight="20px" paddingBottom="25px" paddingLeft="20px">
                            <Box marginBottom={15}>
                                <Text style={styles.contestTitle}>{contest.title}</Text>
                            </Box>
                            <Box justifyContent="center" alignItems="center" flexDirection="row">
                                <Image
                                    height={'20px'}
                                    width={'16px'}
                                    source={require('../assets/images/area.svg')}
                                    alt="area"
                                    display={Ajusts.Display}
                                />
                                <Text style={styles.subTextarea}>{contest.areaName}地区</Text>
                                <Text style={styles.subText}>
                                    {dateFormat(contest.applicationStart)} - {dateFormat(contest.applicationEnd)}
                                </Text>
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}
                </LinearGradient>
                <HStack
                    bg={Colors.white}
                    alignItems="center"
                    borderBottomWidth={2}
                    borderBottomColor={Colors.lightGray}
                    width="100%"
                    height="60px"
                >
                    <Pressable
                        {...Platform.select({
                            web: {
                                cursor: 'pointer'
                            }
                        })}
                        flex={1}
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            borderRightWidth: 1,
                            borderStyle: 'dotted',
                            borderColor: Colors.lightGray,
                            height: '40px'
                        }}
                        onPress={() => goToSection(GrandRef)}
                    >
                        <Center>
                            <Text style={styles.prizeMenu}>大賞</Text>
                        </Center>
                    </Pressable>
                    <Pressable
                        {...Platform.select({
                            web: {
                                cursor: 'pointer'
                            }
                        })}
                        flex={1}
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            borderRightWidth: 1,
                            borderStyle: 'dotted',
                            borderColor: Colors.lightGray,
                            height: '40px'
                        }}
                        onPress={() => goToSection(excellenceRef)}
                    >
                        <Center>
                            <Text style={styles.prizeMenu}>優秀賞</Text>
                        </Center>
                    </Pressable>
                    <Pressable
                        {...Platform.select({
                            web: {
                                cursor: 'pointer'
                            }
                        })}
                        flex={1}
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            height: '40px'
                        }}
                        onPress={() => goToSection(fightSpiritRef)}
                    >
                        <Center>
                            <Text style={styles.prizeMenu}>敢闘賞</Text>
                        </Center>
                    </Pressable>
                </HStack>
                {/* 大賞 */}
                <Box width={'100%'} ref={GrandRef} testID="gold-section">
                    <Image
                        height="26.91px"
                        width="54.88px"
                        marginTop="34.31px"
                        marginBottom="7px"
                        marginLeft={'auto'}
                        marginRight={'auto'}
                        source={require('../assets/images/award.svg')}
                        alt="grand"
                    />
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="row"
                        width="100%"
                        paddingLeft="10px"
                        paddingRight="10px"
                    >
                        <View
                            style={{
                                borderBottomWidth: 1,
                                width: '100%',
                                maxWidth: '140px'
                            }}
                        />
                        <Text style={styles.mainText}>大賞</Text>
                        <View
                            style={{
                                borderBottomWidth: 1,
                                width: '100%',
                                maxWidth: '140px'
                            }}
                        />
                    </Box>
                    {grand.map((data: any, index: number) => {
                        return <ResultPrize data={data} key={index} />;
                    })}
                </Box>
                {/* 優秀賞 */}
                <Box width={'100%'} ref={excellenceRef} testID="silver-section">
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="row"
                        width="100%"
                        marginLeft="10px"
                        marginRight="10px"
                    >
                        <View style={{ borderBottomWidth: 1, width: '100%', maxWidth: '122px' }} />
                        <Text style={styles.mainText}>優秀賞</Text>
                        <View style={{ borderBottomWidth: 1, width: '100%', maxWidth: '122px' }} />
                    </Box>
                    {excellence.map((data: any, index: number) => {
                        return <ResultPrize data={data} key={index} />;
                    })}
                </Box>
                {/* 敢闘賞 */}
                <Box width={'100%'} ref={fightSpiritRef} testID="bronze-section">
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="row"
                        width="100%"
                        marginLeft="10px"
                        marginRight="10px"
                    >
                        <View style={{ borderBottomWidth: 1, width: '100%', maxWidth: '122px' }} />
                        <Text style={[styles.mainText]}>敢闘賞</Text>
                        <View style={{ borderBottomWidth: 1, width: '100%', maxWidth: '122px' }} />
                    </Box>
                    {fightSpirit.map((data: any, index: number) => {
                        return <ResultPrize data={data} key={index} />;
                    })}
                </Box>
                <RoundButton
                    text="結果発表一覧に戻る"
                    backgroundColor={Colors.white}
                    borderColor={Colors.primary}
                    fontColor={Colors.primary}
                    onPress={goBack}
                />
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        marginBottom: '30px'
    },
    contestTitle: {
        color: Colors.white,
        fontWeight: '700',
        fontSize: 20,
        textAlign: 'center'
    },
    mainText: {
        fontWeight: '700',
        fontSize: 20,
        width: '100%',
        maxWidth: '60px',
        minWidth: '60px',
        textAlign: 'center',
        marginRight: '10px',
        marginLeft: '10px'
    },
    subText: {
        fontWeight: '400',
        fontSize: 14,
        color: Colors.white,
        marginLeft: '6px'
    },
    subTextarea: {
        fontWeight: '400',
        fontSize: 14,
        color: Colors.white,
        marginLeft: '6px',
        display: Ajusts.Display
    },
    prizeMenu: {
        color: Colors.primary,
        fontWeight: '400',
        fontSize: 20
    }
});
