import { Ionicons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { IconButton } from 'native-base';
import React from 'react';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const SearchButton = (props: any) => {
    const { backgroundColor, onPressSearch, active, ...rest } = props;
    return (
        <LinearGradient
            colors={
                active
                    ? [`${Colors.primaryGradientStart}`, `${Colors.primaryGradientEnd}`]
                    : [`${Colors.lightGray}`, `${Colors.lightGray}`]
            }
            start={[0, 0]}
            end={[1, 0]}
            style={{
                height: 36,
                width: 36,
                position: 'absolute',
                right: 3,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 5
            }}
        >
            <IconButton
                style={{
                    height: 36,
                    width: 36,
                    position: 'absolute',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                onPress={() => onPressSearch()}
                disabled={!active}
                _hover={{
                    bg: 'transparent'
                }}
                _icon={{
                    as: Ionicons,
                    name: 'search-sharp',
                    size: 'sm',
                    color: Colors.white
                }}
            />
        </LinearGradient>
    );
};
export default SearchButton;
