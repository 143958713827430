import { ColorSchemeName, useColorScheme as _useColorScheme } from 'react-native';
import ENV from 'expo-constants';

// The useColorScheme value is always either light or dark, but the built-in
// type suggests that it can be null. This will not happen in practice, so this
// makes it a bit easier to work with.
export default function useColorScheme(): NonNullable<ColorSchemeName> {
    // return _useColorScheme() as NonNullable<ColorSchemeName>;

    // サブディレクトリ名を取得
    const directoryName = ENV.manifest!.extra!.ROUTER_BASENAME.replace('/', '');

    return directoryName ? directoryName : ('light' as NonNullable<ColorSchemeName>);
}
