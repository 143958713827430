import { Box } from 'native-base';
import React from 'react';
import { Button, ScrollView, TouchableOpacity, View, StyleSheet, Text, Pressable } from 'react-native';
import FontSize from '../../../constants/FontSize';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const RoundNumber = (props: any) => {
    const { text, ...rest } = props;
    return (
        <Box
            borderWidth={1}
            borderColor={Colors.white}
            borderRadius={50}
            paddingTop="3px"
            paddingRight="9px"
            paddingBottom="3px"
            paddingLeft="9px"
            width="51px"
            height="24px"
            alignItems="center"
            justifyContent="center"
        >
            <Text style={{ fontWeight: 'bold', color: Colors.white, fontSize: FontSize.medium }}>{props.text}</Text>
        </Box>
    );
};
export default RoundNumber;
