import React, { useEffect, useState, useContext } from 'react';
import axios from '../axios/axios';
import { StyleSheet, View } from 'react-native';

import AreaSelect from '../components/AreaSelect';
import PickUpRecommendCard from '../stories/components/Cards/PickUpRecommendCard';
import RecommendCard from '../stories/components/Cards/RecommendCard';
import { GlobalContext } from '../contexts/Global';
import UserNameBar from '../stories/components/Bar/UserNameBar';
import { AuthContext } from '../contexts/Auth';

export default function RecommendScreen() {
    const [recommends, setRecommends] = useState<any[]>([]);
    const { areaId } = useContext(GlobalContext);
    const { currentUser } = useContext(AuthContext);
    let recommendNewExis: any;

    // 全てのおすすめのコンテンツを取得
    const getRecommends = () => {
        const apiUrl = `/recommends?areaId=${areaId}`;
        axios.get(apiUrl).then((res) => {
            // recommend.id 降順にソート
            setRecommends(
                res.data.sort(function (a: any, b: any) {
                    if (a.id > b.id) return -1;
                    if (b.id > a.id) return 1;
                    return 0;
                })
            );
        });
    };

    useEffect(() => {
        getRecommends();
    }, [areaId]);

    return (
        <View>
            {currentUser ? <UserNameBar mallName={currentUser.mallName} /> : null}
            <AreaSelect />
            <View style={styles.container}>
                <View style={styles.recommend}>
                    {recommends.map((data: any, index: any) => {
                        if (
                            data.publishFlag &&
                            new Date() <= new Date(data.publishEnd) &&
                            new Date() >= new Date(data.publishStart)
                        ) {
                            if (!recommendNewExis) {
                                recommendNewExis = true;
                                return <PickUpRecommendCard key={index} recommendInfo={data} />;
                            } else {
                                return <RecommendCard key={index} recommendInfo={data} />;
                            }
                        }
                    })}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    recommend: {
        width: '100%'
    }
});
