import React, { useState } from 'react';
import { TextInput } from 'react-native';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const TextArea = (props: any) => {
    const Colors: ColorNames = useThemeColors();

    const { placeholder, multiline, ref, value, onChange, maxlength, keyboardType, ...rest } = props;
    let height;
    if (multiline == true) height = 120;
    else height = 40;

    let max_length;
    if (maxlength > 0) max_length = maxlength;
    else max_length = 0; //invalidation

    return (
        <TextInput
            style={{
                height: height,
                width: '100%',
                borderRadius: 4,
                borderColor: Colors.lightGray,
                borderWidth: 1,
                padding: 8,
                fontSize: 14,
                lineHeight: 22.4
            }}
            ref={ref}
            placeholder={placeholder}
            multiline={multiline}
            maxLength={maxlength}
            onChange={onChange}
            value={value}
            keyboardType={keyboardType ? keyboardType : 'default'}
        />
    );
};

export default TextArea;
