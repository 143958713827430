import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import { IconButton, Pressable } from 'native-base';
import { Platform } from 'react-native';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

type Props = {
    onPress: (event: any) => void;
    // icon?: {
    //     as: any,
    //     name: string,
    // }
    // backgroundColor?: string
};

export default function SearchCloseButton({ onPress }: Props) {
    return (
        <IconButton
            style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                ...Platform.select({
                    web: {
                        cursor: 'pointer'
                    }
                })
            })}
            onPress={onPress}
            _hover={{
                bg: 'transparent'
            }}
            _pressed={{
                bg: 'transparent'
            }}
            _icon={{
                as: Ionicons,
                name: 'close-circle',
                size: 54,
                color: Colors.lightGray
            }}
        />
    );
}
