import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Text, Pressable, Box, Spinner } from 'native-base';
import Modal from 'react-native-modal';
import _ from 'lodash';
import PostCompleted from '../stories/components/Modal/PostCompleted';
import ProgressBar from '../stories/components/Bar/ProgressBar';
import Title from '../stories/components/ButtonNative/Title';
import FontSize from '../constants/FontSize';
import { LinearGradient } from 'expo-linear-gradient';
import axios from '../axios/axios';
import { useThemeColors, useThemeImages } from './Themed';
import { ColorNames, ImageNames } from '../types';

const Colors: ColorNames = useThemeColors();
const Images: ImageNames = useThemeImages();

export default function PostDeleteCheckModals(props: any) {
    function deletePost() {
        axios.delete(props.apiUrl);
    }

    return (
        <>
            <Modal
                isVisible={props.deleteModal}
                animationIn="zoomIn"
                animationOut="slideOutDown"
                style={{ margin: 0, flex: 1, width: '100%', height: '100%' }}
            >
                <View style={styles.modalPost}>
                    {ProgressBar({ progressWidth: 100 })}
                    {Title({
                        text: '投稿削除確認',
                        fontSize: FontSize.large,
                        fontWeight: '600'
                    })}
                    {PostCompleted({
                        source: Images.postCompleted.uri,
                        text: 'この投稿を本当に削除してよろしいでしょうか。',
                        button: false
                    })}
                    <Box style={styles.buttonBox}>
                        <Box style={styles.PostButton}>
                            <Pressable
                                disabled={props.isSending}
                                onPress={() => {
                                    props.setDeleteModalComplete(true);
                                    props.setDeleteModal(false);
                                    props.setModalError(false);
                                    deletePost();
                                }}
                            >
                                <LinearGradient
                                    colors={[Colors.white, Colors.white]}
                                    start={[0, 0]}
                                    end={[1, 0]}
                                    style={styles.deleteButton}
                                >
                                    {props.isSending ? (
                                        <Spinner
                                            color={Colors.white}
                                            size="sm"
                                            width={'fit-content'}
                                            paddingRight={50}
                                            paddingLeft={50}
                                        />
                                    ) : (
                                        <Text style={styles.deleteButtonText}>削除する</Text>
                                    )}
                                </LinearGradient>
                            </Pressable>
                        </Box>
                        <Box style={styles.PostButton}>
                            <Pressable
                                disabled={props.isSending}
                                onPress={() => {
                                    props.setDeleteModal(false);
                                    props.setModalError(false);
                                }}
                            >
                                <LinearGradient
                                    colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                                    start={[0, 0]}
                                    end={[1, 0]}
                                    style={styles.nextButton}
                                >
                                    {props.isSending ? (
                                        <Spinner
                                            color={Colors.white}
                                            size="sm"
                                            width={'fit-content'}
                                            paddingRight={50}
                                            paddingLeft={50}
                                        />
                                    ) : (
                                        <Text style={styles.buttonText}>削除しない</Text>
                                    )}
                                </LinearGradient>
                            </Pressable>
                        </Box>
                    </Box>
                </View>
            </Modal>
            <Modal
                isVisible={props.modalError}
                animationIn="slideInUp"
                animationOut="slideOutDown"
                style={{
                    justifyContent: 'flex-end',
                    margin: 0
                }}
                onBackdropPress={() => props.setModalError(false)}
            >
                <View
                    style={{
                        backgroundColor: Colors.white,
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20
                    }}
                >
                    <Text style={{ margin: 20, paddingHorizontal: 10 }}>{props.postErrorMessage}</Text>
                </View>
            </Modal>
        </>
    );
}

const styles = StyleSheet.create({
    modalPost: {
        flex: 1,
        backgroundColor: Colors.white,
        marginTop: 59,
        paddingTop: 47,
        paddingHorizontal: '5%',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    modalComplete: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    PostButton: {
        width: 'fit-content',
        paddingVertical: 20,
        borderTopWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.lighterGray
    },
    buttonText: {
        color: Colors.white,
        fontWeight: '700',
        paddingHorizontal: 25
    },
    buttonBox: {
        position: 'absolute',
        flex: 2,
        textAlign: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        bottom: 0
    },
    nextButton: {
        width: 'fit-content',
        borderRadius: 50,
        height: 48,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 5
    },
    deleteButtonText: {
        color: Colors.primary,
        fontWeight: '700',
        paddingHorizontal: 25
    },
    deleteButton: {
        width: 'fit-content',
        borderRadius: 50,
        height: 48,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 5,
        borderColor: Colors.primary,
        borderWidth: 2
    }
});
