import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useThemeColors } from './Themed';
import { ColorNames } from '../types';

function TalkBubble() {
    const Colors: ColorNames = useThemeColors();

    const styles = StyleSheet.create({
        talkBubble: {
            backgroundColor: 'transparent'
        },
        talkBubbleSquare: {
            width: 41,
            height: 19,
            backgroundColor: Colors.black,
            borderRadius: 10,
            content: '投稿'
        },
        talkBubbleTriangle: {
            position: 'absolute',
            right: 12,
            top: 10,
            bottom: 0,
            width: 0,
            height: 0,
            borderTopWidth: 15,
            borderTopColor: Colors.black,
            borderRightWidth: 8,
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent',
            borderLeftWidth: 8,
            borderLeftColor: 'transparent'
        },
        text: {
            color: Colors.white,
            fontSize: 12,
            fontWeight: 'bold',
            position: 'absolute',
            right: 8
        }
    });

    return (
        <View style={styles.talkBubble}>
            <View style={styles.talkBubbleSquare} />
            <View style={styles.talkBubbleTriangle} />
            <Text style={styles.text}>投稿</Text>
        </View>
    );
}

export default TalkBubble;
