import { Box, HStack, Image } from 'native-base';
import React, { useContext } from 'react';
import { Pressable, Text } from 'react-native';
import FontSize from '../../../constants/FontSize';
import { AuthContext } from '../../../contexts/Auth';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const DrawerMenu = (props: any) => {
    const { menus, onPress, currentTabName, menuModalVisible, setMenuModalVisible, logout, ...rest } = props;
    const { currentUser } = useContext(AuthContext);
    const Colors: ColorNames = useThemeColors();

    return (
        <>
            <HStack
                bg={Colors.lightestGray}
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                flexDirection="column"
            >
                <Box
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    paddingTop="9px"
                    paddingBottom="9px"
                    paddingLeft="20px"
                    paddingRight="20px"
                    minHeight={53}
                >
                    {currentUser ? (
                        <Text
                            style={{
                                color: Colors.black,
                                fontSize: FontSize.medium,
                                fontWeight: '800',
                                width: '100%',
                                textAlign: 'center'
                            }}
                        >
                            ようこそ {currentUser.mallName} さん
                        </Text>
                    ) : null}
                </Box>
                {menus.map((data: any, index: number) => {
                    return (
                        <Pressable
                            style={{
                                width: '100%',
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderStyle: 'solid',
                                borderColor: Colors.lighterGray,
                                display: data.style
                            }}
                            onPress={() => {
                                setMenuModalVisible(!menuModalVisible);
                                onPress(data.path);
                            }}
                            key={index}
                        >
                            <Box
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                                paddingTop="9px"
                                paddingBottom="9px"
                                paddingLeft="20px"
                                paddingRight="20px"
                                minHeight={53}
                            >
                                <Text
                                    style={{
                                        color: currentTabName == data.path ? Colors.black : Colors.primary,
                                        fontSize: FontSize.medium,
                                        fontWeight: currentTabName == data.path ? '700' : '400'
                                    }}
                                >
                                    {data.title}
                                </Text>
                                <Image
                                    alt=">"
                                    height="13.33px"
                                    width="7.85px"
                                    source={require('../../../assets/images/detail-gray.svg')}
                                />
                            </Box>
                        </Pressable>
                    );
                })}
                <Pressable
                    style={{
                        width: '100%',
                        alignItems: 'center'
                    }}
                    onPress={logout}
                >
                    <Box
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        paddingTop="9px"
                        paddingBottom="9px"
                        paddingLeft="20px"
                        paddingRight="20px"
                        minHeight={53}
                    >
                        <Text
                            style={{
                                color: Colors.primary,
                                fontSize: FontSize.medium,
                                fontWeight: '400'
                            }}
                        >
                            ログアウト
                        </Text>
                        <Image
                            alt=">"
                            height="13.33px"
                            width="7.85px"
                            source={require('../../../assets/images/detail-gray.svg')}
                        />
                    </Box>
                </Pressable>
            </HStack>
        </>
    );
};

export default DrawerMenu;
