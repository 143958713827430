import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Link, useNavigate, useLocation } from '../router/react-router';
import { StyleSheet, StyleProp, Image } from 'react-native';
import { Center, Box, HStack, Pressable, ScrollView, Text } from 'native-base';
import _ from 'lodash';
import HeaderSearchButton from '../components/HeaderSearch';
import TopBar from '../stories/components/Bar/TopBar';
import CustomSearchModal from '../components/CustomSearchModal';
import Zindex from '../constants/Zindex';
import { GlobalContext } from '../contexts/Global';
import CustomMenuModal from '../components/CustomMenuModal';
import { getCurrentTabNameFromLocation } from '../utils/utils';
import { useThemeColors, useThemeImages, useThemedComponents, useThemedAjusts, View } from '../components/Themed';
import { ColorNames, ImageNames, ThemedComponentType, ThemedAjustType } from '../types';
import Ribbon from '../stories/components/Ribbon/Ribbon';

export default function TabSceen() {
    const Colors: ColorNames = useThemeColors();
    const ThemedComponent: ThemedComponentType = useThemedComponents();
    const Ajusts: ThemedAjustType = useThemedAjusts();

    const headerHeight = Ajusts.HeaderHeight;
    const searchHeaderHeight = Ajusts.SearchHeaderHeight;
    const footerHeight = '60px';
    const location = useLocation();
    const [headerHeightPadding, setHeaderHeightPadding] = useState<number>(70);
    const {
        setIsScrollEnd,
        scrollViewRef,
        searchModalVisible,
        setSearchModalVisible,
        searchModalActive,
        setSearchModalActive
    } = useContext(GlobalContext);

    // トップへスクロール
    const goToTop = () => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: false });
        }
    };

    // 現在のスクロール位置から相対的にY軸方向にスクロールする
    const goScrollBy = (y: number, animated = false) => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({ x: 0, y: scrollViewRef.current.scrollTop + y, animated: animated });
        }
    };

    // scrollPostion取得
    const _scrollPosition = (e: any) => {
        const offsetY = e.nativeEvent.contentOffset.y;
        // スクロール距離
        const contentSizeHeight = e.nativeEvent.contentSize.height; // scrollView contentSizeの高さ
        const scrollViewHeight = e.nativeEvent.layoutMeasurement.height; // scrollViewの高さ
        if (contentSizeHeight >= 40 && offsetY + scrollViewHeight + 1 >= contentSizeHeight) {
            setIsScrollEnd(true);
        }
    };

    // メニューモーダル表示切り替え用
    const [menuModalVisible, setMenuModalVisible] = useState<boolean>(false);

    useEffect(() => {
        searchModalVisible
            ? setHeaderHeightPadding(headerHeight + searchHeaderHeight)
            : setHeaderHeightPadding(headerHeight);
    }, [searchModalVisible]);

    return (
        <Box flex={1} bg={Colors.lighterGray} width="100%">
            {/* Header */}
            <TopBar menuModalVisible={menuModalVisible} setMenuModalVisible={setMenuModalVisible}>
                <HeaderSearchButton
                    searchModalVisible={searchModalVisible}
                    setSearchModalVisible={setSearchModalVisible}
                    searchModalActive={searchModalActive}
                    setSearchModalActive={setSearchModalActive}
                />
            </TopBar>
            {/* 検索用モーダル */}
            <CustomSearchModal searchModalVisible={searchModalVisible} setSearchModalVisible={setSearchModalVisible} />
            {/* メニュー用モーダル */}
            <CustomMenuModal
                menuModalVisible={menuModalVisible}
                setMenuModalVisible={setMenuModalVisible}
                currentTabName={getCurrentTabNameFromLocation(location.pathname)}
            />
            {/* コンテンツ表示部分 */}
            <ScrollView
                ref={scrollViewRef}
                style={{
                    width: '100%',
                    backgroundColor: Colors.lightestGray
                }}
                flex={1}
                paddingTop={headerHeightPadding}
                paddingBottom={footerHeight}
                onScroll={getCurrentTabNameFromLocation(location.pathname) === 'posts' ? _scrollPosition : undefined} // 投稿一覧の場合のみスクロールイベントを取得する
                scrollEventThrottle={1000}
            >
                <Ribbon text={Ajusts.HeaderTxt} bgColor={Colors.white} textColor={Colors.primary} fontSize="11px" />
                <Outlet context={{ goToTop, goScrollBy }} />
            </ScrollView>
            <HStack
                bg={Colors.white}
                alignItems="center"
                shadow={6}
                position="fixed" // webのみ（nativeはabsolute）
                bottom={0}
                left={0}
                width="100%"
                height={footerHeight}
                zIndex={Zindex.footer}
            >
                <ThemedComponent.Footer />
            </HStack>
        </Box>
    );
}
