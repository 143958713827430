import { createIconSetFromFontello } from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
import { Platform } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import { GlobalProvider } from './contexts/Global';
import { AuthProvider } from './contexts/Auth';
import Routing from './router';
import ResizeObserver from 'resize-observer-polyfill';
import { NativeBaseProvider } from 'native-base';

export default function App() {
    const isLoadingComplete = useCachedResources();
    const colorScheme = useColorScheme();

    if (!isLoadingComplete) {
        return null;
    } else {
        return (
            <AuthProvider>
                <GlobalProvider>
                    <NativeBaseProvider>
                        <SafeAreaProvider>
                            <Routing />
                            <StatusBar />
                        </SafeAreaProvider>
                    </NativeBaseProvider>
                </GlobalProvider>
            </AuthProvider>
        );
    }
}

// Web用全体設定（index.html、jsのpolyfil等、GTMなどのはweb/index.htmlで設定）
if (Platform.OS === 'web' && document) {
    // React Native Web用のデフォルトスタイル挿入
    const styleElement: HTMLStyleElement = document.createElement('style');
    styleElement.textContent = `html{height:100%; position:fixed;} body{height:100%; overflow:hidden;} #root{display:flex; height:100%;}`;
    document.head.appendChild(styleElement);
    // safari mobileのpolyfil対応（onLayoutを有効にする）
    window.ResizeObserver = ResizeObserver;
}

// localhostのsafariでログインエラーが発生する場合以下コメントアウトを外す
// window.indexedDB.deleteDatabase("firebaseLocalStorageDb");
