import { Box, HStack, Image } from 'native-base';
import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import FontSize from '../../../constants/FontSize';
import { useThemeColors, useThemedAjusts } from '../../../components/Themed';
import { ColorNames, ThemedAjustType } from '../../../types';

const AreaBar = (props: any) => {
    const { areaName, onPress, modalVisible, setModalVisible, ...rest } = props;
    const Colors: ColorNames = useThemeColors();
    const Ajusts: ThemedAjustType = useThemedAjusts();

    const styles = StyleSheet.create({
        backText: {
            marginLeft: '15px',
            fontWeight: '400',
            fontSize: FontSize.medium,
            color: Colors.mediumGray
        },
        guidance: {
            marginRight: '6px',
            fontSize: FontSize.small,
            color: Colors.mediumGray
        }
    });

    return (
        <Pressable
            onPress={modalVisible ? setModalVisible : onPress}
            style={{
                width: '100%',
                alignItems: 'center'
            }}
        >
            <HStack
                style={{ flexDirection: 'row' }}
                paddingLeft="17px"
                paddingRight="17px"
                alignItems="center"
                height="44px"
                width="100%"
                backgroundColor={Colors.white}
                justifyContent="space-between"
                borderBottomWidth={1}
                borderBottomColor={modalVisible ? Colors.lightGray : Colors.lightestGray}
                display={Ajusts.Display}
            >
                <Box flexDirection="row" alignItems="center">
                    <Image
                        height={'20px'}
                        width={'16px'}
                        marginRight={'6px'}
                        source={require('../../../assets/images/area-black.svg')}
                    />
                    <Text style={{ color: Colors.black, fontWeight: '700' }}>{areaName}地区</Text>
                </Box>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={styles.guidance}>{modalVisible ? '閉じる' : '表示地区を切り替える'}</Text>
                    <Image
                        height={'15px'}
                        width={'8.83px'}
                        source={require('../../../assets/images/detail-gray.svg')}
                    />
                </View>
            </HStack>
        </Pressable>
    );
};

export default AreaBar;
