import ENV from 'expo-constants';

export const Consts = {
    CONTEST_TYPES: {
        IMAGE: 0,
        VIDEO: 1
    },
    ACCEPTABLE_IMAGE_FILE_EXTENSION: 'image/*',
    ACCEPTABLE_VIDEO_FILE_EXTENSION: '.mov, .mp4, .avi, .m4v, .wmv, .3gp, .qt',
    ACCEPTABLE_IMAGE_FILE_SIZE: 1024 * 1024 * 100,
    ACCEPTABLE_VIDEO_FILE_SIZE: 1024 * 1024 * 500,
    AUTH_TIMEOUT: 1000 * 60 * 60 * 3,
    LOGIN_MAIL_DOMAIN_USER:
        ENV.manifest!.extra!.FIREBASE_CONFIG_PROJECT_ID === 'am-vp-contest-site-staging'
            ? 'am-vp-contest-site-user-staging.com'
            : ENV.manifest!.extra!.FIREBASE_CONFIG_PROJECT_ID + '.web.app'
};
