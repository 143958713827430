import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { HStack, Pressable, ScrollView, Spinner } from 'native-base';
import Modal from 'react-native-modal';
import _ from 'lodash';
import ProgressBar from '../stories/components/Bar/ProgressBar';
import Title from '../stories/components/ButtonNative/Title';
import FontSize from '../constants/FontSize';
import { useThemeColors } from './Themed';
import { ColorNames } from '../types';

export default function SelectContestModal(props: {
    isLoading: boolean;
    modalPost: boolean | undefined;
    setModalPost: (arg0: boolean) => void;
    boxList: any;
}) {
    const Colors: ColorNames = useThemeColors();

    const styles = StyleSheet.create({
        modalPost: {
            flex: 1,
            backgroundColor: Colors.white,
            marginTop: 59,
            paddingTop: 47,
            paddingHorizontal: '5%',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20
        },
        cross: {
            height: 24,
            width: 24,
            marginTop: 17,
            position: 'absolute',
            right: 10
        }
    });

    function SpinnerComponent() {
        if (props.isLoading) {
            return (
                <HStack height={100} justifyContent="center" alignItems="center">
                    <Spinner color={Colors.primary} size="lg" />
                </HStack>
            );
        }
        return null;
    }

    return (
        <Modal
            isVisible={props.modalPost}
            animationIn="slideInRight"
            animationOut="slideOutRight"
            style={{ margin: 0, flex: 1, width: '100%', height: '100%' }}
        >
            <Pressable
                px="10px"
                onPress={() => {
                    props.setModalPost(false);
                }}
            >
                <Image style={styles.cross} source={require('../assets/images/cross.svg')} />
            </Pressable>
            <View style={styles.modalPost}>
                {ProgressBar({ progressWidth: 25 })}
                {Title({
                    text: 'コンテストを選ぶ(STEP1/3)',
                    fontSize: FontSize.large,
                    fontWeight: '600'
                })}
                <ScrollView>{props.isLoading ? <SpinnerComponent /> : props.boxList}</ScrollView>
            </View>
        </Modal>
    );
}
