/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import { Text as DefaultText, View as DefaultView } from 'react-native';

import Colors from '../constants/Colors';
import Images from '../constants/Images';
import Components from '../constants/Components';
import Ajusts from '../constants/Ajusts';
import useColorScheme from '../hooks/useColorScheme';

export const useThemeColors = () => {
    const colorScheme = useColorScheme();
    const colors = Colors[colorScheme];

    return colors;
};

export const useThemeImages = () => {
    const colorScheme = useColorScheme();
    const images: any = Images[colorScheme];

    return images;
};

export const useThemedComponents = () => {
    const componentsScheme = useColorScheme();
    const components: any = Components[componentsScheme];

    return components;
};

export const useThemedAjusts = () => {
    const ajustsScheme = useColorScheme();
    const ajusts: any = Ajusts[ajustsScheme];

    return ajusts;
};

export function useThemeColor(
    props: { light?: string; dark?: string },
    colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
    const theme = useColorScheme();
    const colorFromProps = props[theme];

    if (colorFromProps) {
        return colorFromProps;
    } else {
        return Colors[theme][colorName];
    }
}

type ThemeProps = {
    lightColor?: string;
    darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText['props'];
export type ViewProps = ThemeProps & DefaultView['props'];

export function Text(props: TextProps) {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

    return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

    return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}
