import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, HStack, Spinner, ScrollView } from 'native-base';
import { GlobalContext } from '../contexts/Global';
import Modal from 'react-native-modal';
import AreaContestBar from '../stories/components/Bar/AreaContestBar';
import AreaSwitch from '../stories/components/Drawer/AreaSwitch';
import { useThemeColors } from './Themed';
import { ColorNames } from '../types';
import axios from '../axios/axios';
import ContestList from './ContestList';
import BackBar from '../stories/components/Bar/BackBar';
import { useNavigate } from '../router/react-router';
import { TouchableOpacity } from 'react-native';

export default function AreaContestSelect() {
    const { areaId, setAreaId, areaName, setAreaName, areas } = useContext(GlobalContext);
    const Colors: ColorNames = useThemeColors();
    const navigate = useNavigate();

    const [isAreaSelectModalVisible, setIsAreaSelectModalVisible] = useState<boolean>(false);
    const [isContestAreaSelectModalVisible, setIsContestAreaSelectModalVisible] = useState<boolean>(false);
    const [isContestSelectModalVisible, setIsContestSelectModalVisible] = useState<boolean>(false);
    const [areaIdForContest, setAreaIdForContest] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contests, setContests] = useState<any[] | undefined>();

    // 地区選択
    function selectArea(id: any) {
        setAreaId(id);
        setAreaName(areas.find((area: any) => area.id == id).areaName);
        setIsAreaSelectModalVisible(false);
    }

    // 地区選択（コンテスト選択用）
    function selectAreaForContestSelect(id: number) {
        setAreaIdForContest(id);
        setIsContestAreaSelectModalVisible(false);
        setIsContestSelectModalVisible(true);
    }

    // コンテスト選択から地区選択へ戻る
    function backToContestAreaSelectModal() {
        setAreaIdForContest(0);
        setIsContestSelectModalVisible(false);
        setIsContestAreaSelectModalVisible(true);
    }

    // コンテスト選択し、検索結果に反映
    function selectContest(contestData: any) {
        setAreaIdForContest(0);
        setIsContestSelectModalVisible(false);
        navigate(`/posts?contestName=${contestData.title}`);
    }

    useEffect(() => {
        if (!isContestSelectModalVisible) return;
        if (!areaIdForContest) return;
        setIsLoading(true);
        const sort = '_sort=applicationEnd';
        const nowDateString: string = new Date().toISOString();
        const now: string = '&applicationEnd_gte=' + nowDateString;
        axios
            .get(`/contests?` + 'areaId=' + areaIdForContest + now)
            .then((result) => {
                console.log(`/contests?` + sort + 'areaId=' + areaId + now);
                console.log('returnArrayListlength:' + result.data.length);
                setIsLoading(false);
                setContests(result.data);
            })
            .catch((error) => {
                console.log('通信失敗');
                console.log(error);
                setIsLoading(false);
                setContests([]);
            });
    }, [isContestSelectModalVisible]);

    if (!areaId || !areaName || !areas)
        return (
            <Box>
                <Spinner color={Colors.primary} />
            </Box>
        );

    return (
        <Box>
            <AreaContestBar
                areaName={areaName}
                setIsAreaSelectModalVisible={setIsAreaSelectModalVisible}
                setIsContestAreaSelectModalVisible={setIsContestAreaSelectModalVisible}
            />
            <Modal
                isVisible={isAreaSelectModalVisible}
                animationIn="slideInLeft"
                animationOut="slideOutLeft"
                style={{ margin: 0 }}
            >
                <AreaSwitch
                    modalVisible={isAreaSelectModalVisible}
                    setModalVisible={() => setIsAreaSelectModalVisible(false)}
                    areas={areas}
                    selectArea={selectArea}
                    areaId={areaId}
                    areaName={areaName}
                ></AreaSwitch>
            </Modal>
            <Modal
                isVisible={isContestAreaSelectModalVisible}
                animationIn="slideInRight"
                animationOut="slideOutRight"
                style={{ margin: 0 }}
            >
                <AreaSwitch
                    modalVisible={isContestAreaSelectModalVisible}
                    setModalVisible={() => setIsContestAreaSelectModalVisible(false)}
                    areas={areas}
                    selectArea={selectAreaForContestSelect}
                    // areaId={areaId}
                    areaName={areaName}
                ></AreaSwitch>
            </Modal>
            <Modal
                isVisible={isContestSelectModalVisible}
                animationIn="slideInRight"
                animationOut="slideOutRight"
                style={{ margin: 0 }}
            >
                <HStack
                    bg={Colors.white}
                    alignItems="center"
                    justifyContent="flex-start"
                    width="100%"
                    height="100%"
                    flexDirection="column"
                >
                    <BackBar isRight={true} backgroundColor={Colors.white} onPress={backToContestAreaSelectModal} />
                    <Box paddingX="15px" width="100%" flex={1} alignItems={'center'} justifyContent={'center'}>
                        {isLoading ? (
                            <Spinner color={Colors.primary} size={'lg'} />
                        ) : (
                            <ScrollView width="100%" flex={1}>
                                {/* 
                                    PostDetailScreen.tsxのモーダル表示と同じ問題の対処
                                    Scrollable Modalを問題なく動作させるためにTouchableOpacityでスクロールするコンテンツをwrapする必要あり
                                    React-native-modal with nested ScrollView
                                    https://stackoverflow.com/questions/48466314/swipeable-react-native-modal-with-nested-scrollview
                                */}
                                <TouchableOpacity>
                                    <ContestList contests={contests} onPress={selectContest} />
                                </TouchableOpacity>
                            </ScrollView>
                        )}
                    </Box>
                </HStack>
            </Modal>
        </Box>
    );
}
