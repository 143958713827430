import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/Auth';
import { Outlet, useNavigate } from '../router/react-router';
import { AxiosError, AxiosResponse } from 'axios';
import axios from './axios';

/**
 * Reactのアプリ内（React Router内）でaxiosのレスポンスに対してhookを使用するためのProvider
 */
const AxiosClientProvider = () => {
    const { isInitialAuthLoading, setIsInitialAuthLoading, isAuth, setIsAuth, currentUser, setCurrentUser, logout } =
        useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        // レスポンス インターセプター
        const responseInterceptor = axios.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            (error: AxiosError) => {
                switch (error?.response?.status) {
                    case 400:
                        console.error('not found');
                        break;
                    case 401:
                        console.error('unauthorized');
                        setIsAuth(false);
                        setCurrentUser(null);
                        break;
                    default:
                        break;
                }
                return Promise.reject(error);
            }
        );

        // クリーンアップ
        return () => {
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    return <Outlet />;
};

export default AxiosClientProvider;
