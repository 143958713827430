import { Box, HStack, IconButton, Image } from 'native-base';
import React, { useState } from 'react';
import { Pressable, View, Text } from 'react-native';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const PostCompleted = (props: any) => {
    const Colors: ColorNames = useThemeColors();

    const { source, text, button, ...rest } = props;

    return (
        <View style={{ flex: 1, alignItems: 'center' }}>
            <Image height={91.52} width={91.52} source={source} />
            <Text style={{ fontSize: 16, textAlign: 'center', padding: 20, lineHeight: 25.6 }}>{text}</Text>
            {button ? (
                <LinearGradient
                    colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                    start={[0, 0]}
                    end={[1, 0]}
                    style={{
                        width: '100%',
                        borderRadius: 50,
                        height: 50,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Text style={{ color: Colors.white, fontWeight: 'bold' }}>閉じる</Text>
                </LinearGradient>
            ) : null}
        </View>
    );
};

export default PostCompleted;
