/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Link, useNavigate, useLocation } from '../router/react-router';

import { StyleSheet, StyleProp, Image } from 'react-native';
import { Center, Box, HStack, Pressable, ScrollView } from 'native-base';
import _ from 'lodash';
import { GlobalContext } from '../contexts/Global';
import Zindex from '../constants/Zindex';
import { getCurrentTabNameFromLocation } from '../utils/utils';
import PostModals from '../components/PostModals';
import { Platform } from 'react-native';
import { useThemeColors, useThemeImages } from '../components/Themed';
import { ColorNames, ImageNames } from '../types';

export default function LoginImage(props: any) {
    const Images: ImageNames = useThemeImages();

    const { width, height } = props;

    const styles = StyleSheet.create({
        image: {
            marginBottom: 52,
            width: width,
            height: height
        }
    });

    return <Image style={styles.image} source={Images.logo.uri} />;
}
