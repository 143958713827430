import React from 'react';
import { Center, Heading, Box, AspectRatio, Image, Stack, Text, HStack } from 'native-base';
import { useThemeColors, useThemedAjusts } from '../../../components/Themed';
import { ColorNames, ThemedAjustType } from '../../../types';

const Colors: ColorNames = useThemeColors();
const Ajusts: ThemedAjustType = useThemedAjusts();

export default function DetailCard(props: any) {
    return (
        <Box
            alignItems="center"
            width="100%"
            overflow="hidden"
            borderBottomColor={Colors.lightGray}
            borderBottomWidth="1"
            _dark={{
                backgroundColor: Colors.white
            }}
            _web={{
                borderTopWidth: 0
            }}
            _light={{
                backgroundColor: Colors.white
            }}
        >
            <Stack
                padding="20px"
                width="100%"
                p="4"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack width="90%" space={2}>
                    <Heading size="md" ml="-1">
                        {props.title}
                    </Heading>
                    <Box>
                        <Text display={Ajusts.Display} fontWeight="400" color={Colors.mediumGray}>
                            {props.area}
                        </Text>
                        <Text fontWeight="400" color={Colors.mediumGray}>
                            {props.description}
                        </Text>
                    </Box>
                </Stack>
                <Stack width="10%" alignItems="center">
                    <Image
                        height={13.33}
                        width={7.85}
                        source={require('../../../assets/images/detail.svg')}
                        alt="詳細"
                    />
                </Stack>
            </Stack>
        </Box>
    );
}
