import React from 'react';
import { Text } from 'native-base';
import List from '../stories/components/Drawer/List';

const ContestList = (props: { contests: any[] | undefined; onPress: (props: any) => void }) => {
    const { contests, onPress } = props;
    if (contests == undefined) {
        return (
            <Text marginTop={'20px'} textAlign={'center'}>
                {'コンテスト一覧の取得に失敗しました。\n時間をおいて再度アクセスしてください。'}
            </Text>
        );
    } else if (contests.length <= 0) {
        return (
            <Text marginTop={'20px'} textAlign={'center'}>
                {'開催中のコンテストはありません'}
            </Text>
        );
    }

    return <List data={contests} onPress={onPress} />;
};

export default ContestList;
