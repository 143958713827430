import { Box, Image } from 'native-base';
import React from 'react';
import { Button, ScrollView, View, Text } from 'react-native';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const Label = (props: any) => {
    const { width, height, source, text, ...rest } = props;
    return (
        <Box
            height={35}
            borderWidth={1}
            borderColor={Colors.lightGray}
            paddingTop="7px"
            paddingBottom="7px"
            paddingRight="13px"
            paddingLeft="13px"
            borderRadius={50}
            flexDirection="row"
            alignItems="center"
            marginRight="7px"
        >
            <Image width={props.width} height={props.height} marginRight="7.33px" source={props.source} alt="icon" />
            <Text style={{ color: Colors.mediumGray, fontSize: 14 }}>{props.text}</Text>
        </Box>
    );
};
export default Label;
