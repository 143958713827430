import { HStack } from 'native-base';
import React from 'react';
import { Text } from 'react-native';
import FontSize from '../../../constants/FontSize';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const TableData = (props: any) => {
    const { backgroundColor, kinds, name, point, ...rest } = props;
    return (
        <HStack
            style={{
                height: '50px',
                marginBottom: 4,
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: Colors.white,
                flexDirection: 'row',
                borderRadius: 8
            }}
        >
            <Text
                style={{
                    fontSize: FontSize.small,
                    fontWeight: '400',
                    color: Colors.black,
                    textAlign: 'center',
                    width: '20%'
                }}
            >
                {kinds}
            </Text>
            <Text
                style={{
                    fontSize: FontSize.small,
                    fontWeight: '400',
                    color: Colors.black,
                    textAlign: 'left',
                    width: '60%'
                }}
            >
                {name}
            </Text>
            <Text
                style={{
                    fontSize: FontSize.small,
                    fontWeight: '400',
                    color: Colors.black,
                    textAlign: 'center',
                    width: '20%'
                }}
            >
                {point}
            </Text>
        </HStack>
    );
};
export default TableData;
