import React, { useState, useEffect, useContext } from 'react';
import { Box, Spinner } from 'native-base';
import { GlobalContext } from '../contexts/Global';
import Modal from 'react-native-modal';
import AreaBar from '../stories/components/Bar/AreaBar';
import AreaSwitch from '../stories/components/Drawer/AreaSwitch';
import { useThemeColors } from './Themed';
import { ColorNames } from '../types';

export default function AreaSelect(props: any) {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const { areaId, setAreaId, areaName, setAreaName, areas } = useContext(GlobalContext);
    const Colors: ColorNames = useThemeColors();

    // 地区選択
    function selectArea(id: any) {
        setAreaId(id);
        setAreaName(areas.find((area: any) => area.id == id).areaName);
        setModalVisible(false);
    }

    if (!areaId || !areaName || !areas)
        return (
            <Box>
                <Spinner color={Colors.primary} />
            </Box>
        );

    return (
        <Box>
            <AreaBar
                areaName={areaName}
                modalVisible={modalVisible}
                onPress={() => setModalVisible(true)}
                areaId={areaId}
            />
            <Modal isVisible={modalVisible} animationIn="slideInLeft" animationOut="slideOutLeft" style={{ margin: 0 }}>
                <AreaSwitch
                    modalVisible={modalVisible}
                    setModalVisible={() => setModalVisible(false)}
                    areas={areas}
                    selectArea={selectArea}
                    areaId={areaId}
                    areaName={areaName}
                ></AreaSwitch>
            </Modal>
        </Box>
    );
}
