import { StyleSheet, TouchableOpacity } from 'react-native';

import { Text, View } from '../components/Themed';
import { useNavigate } from '../router/react-router';
import { useThemeColors } from '../components/Themed';
import { ColorNames } from '../types';

const Colors: ColorNames = useThemeColors();

export default function NotFoundScreen() {
    const navigate = useNavigate();

    return (
        <View style={styles.container}>
            <Text style={styles.title}>{"This screen doesn't exist."}</Text>
            <TouchableOpacity onPress={() => navigate('/posts')} style={styles.link}>
                <Text style={styles.linkText}>Go to home screen!</Text>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    link: {
        marginTop: 15,
        paddingVertical: 15
    },
    linkText: {
        fontSize: 14,
        color: Colors.primary
    }
});
