import { Box, Icon } from 'native-base';
import React from 'react';
import { Button, ScrollView, TouchableOpacity, View, StyleSheet, Text, Pressable, Image } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const RoundButtonImage = (props: any) => {
    const Colors: ColorNames = useThemeColors();

    const {
        text,
        color,
        borderColor,
        fontColor,
        onPress,
        disabled,
        backgroundColor,
        width,
        maxWidth,
        source,
        ...rest
    } = props;
    return (
        <Pressable
            style={{
                paddingTop: 13,
                paddingBottom: 13,
                paddingRight: 24,
                paddingLeft: 24,
                alignItems: 'center',
                borderRadius: 50,
                height: 50,
                width: width,
                maxWidth: maxWidth,
                borderWidth: 2,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
                flexDirection: 'row',
                justifyContent: 'center'
            }}
            disabled={disabled}
            onPress={onPress}
        >
            <Icon
                style={{
                    justifyContent: 'center'
                }}
                marginRight="12px"
                as={MaterialIcons}
                name="drive-folder-upload"
                color={Colors.primary}
                size="sm"
            />
            <Text style={{ color: fontColor, fontWeight: 'bold' }}>{props.text}</Text>
        </Pressable>
    );
};
export default RoundButtonImage;
