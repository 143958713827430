import React, { useEffect, useState, useContext } from 'react';
import axios from '../axios/axios';
import { StyleSheet, TouchableOpacity, ActivityIndicator, Text, View } from 'react-native';
import { Center, Heading, Box, AspectRatio, Stack, HStack, Image } from 'native-base';
import { useLocation, useOutletContext } from 'react-router-dom';
import FontSize from '../constants/FontSize';
import RecommendDetailCard from '../stories/components/Cards/RecommendDetailCard';
import RoundButton from '../stories/components/ButtonNative/RoundButton';
import { useNavigate } from '../router/react-router';
import { formatDate } from '../utils/utils';
import { AuthContext } from '../contexts/Auth';
import UserNameBar from '../stories/components/Bar/UserNameBar';
import { useThemeColors } from '../components/Themed';
import { ColorNames } from '../types';

const Colors: ColorNames = useThemeColors();

const retrieveUrlLastSection = (url: string): string => {
    const result = url.match(/([^/.]+)/g);
    return result && result[result.length - 1] ? result[result.length - 1] : '';
};

const dateFormat = (date: any) => {
    return `${formatDate(new Date(date), 'yyyy.MM.dd')}`;
};

export default function RecommendScreen() {
    const [recommend, setRecommend] = useState<any>();
    const [recommends, setRecommends] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isAuth, currentUser } = useContext(AuthContext);
    const location = useLocation();
    const recommendId = retrieveUrlLastSection(location.pathname);
    const navigate = useNavigate();
    const goToList = () => navigate(`/recommend`);

    //クリックしたおすすめのコンテンツを取得
    const getRecommend = () => {
        const apiUrl = `/recommends/${recommendId}`;
        axios.get(apiUrl).then((res) => {
            setRecommend(res.data);
            return res.data;
        });
    };

    //関連するおすすめコンテンツを取得
    const getRecommends = () => {
        const apiUrl = `/recommends?areaId=${currentUser?.areaId}&_start=0&_end=5`;
        axios.get(apiUrl).then((res) => {
            setRecommends(res.data);
        });

        setIsLoading(false);
    };

    useEffect(() => {
        getRecommend();
        getRecommends();
    }, [location]);

    if (!recommend || isLoading || !currentUser) return <></>;

    return (
        <View>
            {currentUser ? <UserNameBar mallName={currentUser.mallName} /> : null}
            <Box
                width="100%"
                borderBottomWidth={1}
                borderBottomColor={Colors.lightGray}
                borderBottomStyle="inherit"
                paddingBottom={'30px'}
            >
                <Image
                    height="249.67px"
                    width="100%"
                    resizeMode="cover"
                    marginLeft="auto"
                    marginRight="auto"
                    source={
                        recommend.imageUrl ? { uri: recommend.imageUrl } : require('../assets/images/topResult.svg')
                    }
                />

                <Box width="90%" marginLeft="auto" marginRight="auto">
                    <Box paddingTop="20px">
                        <Text
                            style={{
                                fontWeight: '700',
                                fontSize: 24,
                                lineHeight: 33
                            }}
                        >
                            {recommend.title}
                        </Text>
                    </Box>
                    <Box paddingTop="15px" paddingBottom="15px">
                        <Text style={styles.date}>{dateFormat(recommend.publishStart)}</Text>
                    </Box>

                    <Box paddingBottom="30px">
                        <div className="recommend_content" dangerouslySetInnerHTML={{ __html: recommend.content }} />
                        <style>{`
              .recommend_content blockquote {
                background-color:lightgray;
              }
              .recommend_content iframe {
                width: 100%;
                height: 230px;
              }
              .recommend_content img {
                width: 100%;
              }
            `}</style>
                    </Box>

                    <Box marginLeft="auto" marginRight="auto">
                        <RoundButton
                            width="fit-content"
                            text="オススメ一覧に戻る"
                            backgroundColor={Colors.white}
                            borderColor={Colors.primary}
                            fontColor={Colors.primary}
                            onPress={goToList}
                        />
                    </Box>
                </Box>
            </Box>
            <Box width="90%" paddingTop="25px" marginLeft="auto" marginRight="auto">
                <Text
                    style={{
                        fontWeight: '700',
                        fontSize: 20
                    }}
                >
                    関連する記事 & 動画
                </Text>
                <Box style={styles.containerFlex} overflowX="scroll">
                    <View style={styles.recommend}>
                        {recommends.map((data: any, index: any) => {
                            if (
                                data.publishFlag &&
                                new Date() <= new Date(data.publishEnd) &&
                                new Date() >= new Date(data.publishStart) &&
                                recommend.id !== data.id
                            ) {
                                return <RecommendDetailCard key={index} recommendInfo={data} />;
                            }
                        })}
                    </View>
                </Box>
            </Box>
        </View>
    );
}

const styles = StyleSheet.create({
    containerFlex: {
        flex: 2,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    recommend: {
        width: '100%',
        flexDirection: 'row'
    },
    date: {
        color: Colors.mediumGray,
        fontSize: 14
    }
});
