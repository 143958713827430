import { View, Text, Spinner } from 'native-base';
import { useEffect, useState } from 'react';
import { useThemeColors } from './Themed';

const VideoViewer = (props: any) => {
    const { videoUrl, isAutoPlay } = props;
    const Colors = useThemeColors();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        checkVideoUrl(videoUrl)
            .then((exists) => {
                if (exists) {
                    setLoading(false);
                } else {
                    setLoading(false);
                    setError(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    }, [videoUrl]);

    if (loading)
        return (
            <View
                height={'375px'}
                backgroundColor={Colors.lightestGray}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Spinner color={Colors.primary} size="lg" />
            </View>
        );

    if (error)
        return (
            <View
                height={'375px'}
                backgroundColor={Colors.lightestGray}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Text>動画をアップロード中です。</Text>
                <Text>しばらく経ってから再度ご確認ください。</Text>
            </View>
        );

    return (
        <View height={'375px'} backgroundColor={Colors.darkerGray}>
            <video src={videoUrl} width="100%" height="100%" controls autoPlay={isAutoPlay} />
        </View>
    );
};

async function checkVideoUrl(url: string): Promise<boolean> {
    return new Promise((resolve) => {
        const video = document.createElement('video');
        video.onloadedmetadata = () => {
            resolve(true);
        };
        video.onerror = () => {
            resolve(false);
        };
        video.src = url;
    });
}

export default VideoViewer;
