import { Box, HStack, Image } from 'native-base';
import React, { useState } from 'react';
import { Pressable, View, Text } from 'react-native';
import ContestBar from '../Bar/ContestBar';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const AreaSwitch = (props: any) => {
    const { selectedContestId, selectContest, contests, contestName, modalVisible, setModalVisible, areaId, ...rest } =
        props;

    return (
        <>
            <HStack
                bg={Colors.white}
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                height="100%"
                flexDirection="column"
            >
                <ContestBar
                    contestName={contestName}
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    onPress={() => null}
                />
                {contests.map((data: any, index: number) => {
                    return (
                        <Pressable
                            style={{
                                width: '100%',
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderStyle: 'solid',
                                borderColor: Colors.lighterGray
                            }}
                            onPress={() => selectContest(index)}
                            key={index}
                            disabled={index == selectedContestId}
                        >
                            <Box
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                                paddingTop="9px"
                                paddingBottom="9px"
                                paddingLeft="20px"
                                paddingRight="20px"
                                minHeight={53}
                            >
                                {index == selectedContestId ? (
                                    <Text
                                        style={{
                                            color: Colors.black,
                                            fontSize: 16,
                                            fontWeight: '700'
                                        }}
                                    >
                                        {data.title}
                                    </Text>
                                ) : (
                                    <Text
                                        style={{
                                            color: Colors.primary,
                                            fontSize: 16,
                                            fontWeight: '400'
                                        }}
                                    >
                                        {data.title}
                                    </Text>
                                )}
                            </Box>
                        </Pressable>
                    );
                })}
            </HStack>
        </>
    );
};

export default AreaSwitch;
