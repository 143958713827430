import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, View, Text, Image, Button } from 'react-native';
import { HStack, Pressable, Center } from 'native-base';
import { useNavigate } from '../router/react-router';
import { GlobalContext } from '../contexts/Global';
import Modal from 'react-native-modal';
import { autoTitle } from '@storybook/store';
import SearchCloseButton from '../stories/components/ButtonNative/SearchCloseButton';
import { useThemeImages } from './Themed';
import { ImageNames } from '../types';

const Images: ImageNames = useThemeImages();

export default function HeaderSearchButton({
    searchModalVisible,
    setSearchModalVisible,
    searchModalActive,
    setSearchModalActive
}: any) {
    return (
        <>
            {!searchModalVisible ? (
                <Pressable
                    px="10px"
                    position={'absolute'}
                    onPress={() => {
                        setSearchModalActive(true);
                        setSearchModalVisible(true);
                    }}
                    style={({ pressed }) => ({
                        opacity: pressed ? 0.5 : 1
                    })}
                >
                    <Center>
                        <Image style={{ width: 50, height: 50 }} source={Images.search.uri} />
                    </Center>
                </Pressable>
            ) : (
                <SearchCloseButton
                    onPress={() => {
                        setSearchModalActive(false);
                        setSearchModalVisible(false);
                    }}
                />
            )}
        </>
    );
}
