import { Box, HStack, Image, Pressable, Text } from 'native-base';
import React from 'react';
import FontSize from '../../../constants/FontSize';
import { useThemeColors, useThemeImages, useThemedAjusts } from '../../../components/Themed';
import { ColorNames, ImageNames, ThemedAjustType } from '../../../types';

const AreaContestBar = (props: any) => {
    const { areaName, setIsAreaSelectModalVisible, setIsContestAreaSelectModalVisible, ...rest } = props;
    const Colors: ColorNames = useThemeColors();
    const Ajusts: ThemedAjustType = useThemedAjusts();

    return (
        <Box
            style={{
                width: '100%',
                alignItems: 'center'
            }}
        >
            <HStack
                style={{ flexDirection: 'row' }}
                paddingLeft="17px"
                paddingRight="17px"
                alignItems="center"
                height="44px"
                width="100%"
                backgroundColor={Colors.white}
                justifyContent="space-between"
                borderBottomWidth={1}
                borderBottomColor={Colors.lightestGray}
                display={Ajusts.Display}
            >
                <Pressable flexDirection="row" alignItems="center" onPress={() => setIsAreaSelectModalVisible(true)}>
                    <Image
                        height={'20px'}
                        width={'16px'}
                        marginRight={'6px'}
                        source={require('../../../assets/images/area-black.svg')}
                    />
                    <Text color={Colors.black} fontWeight={'700'} fontSize={FontSize.medium}>
                        {areaName}地区
                    </Text>
                    <Box marginLeft={'10px'}>
                        <Text color={Colors.mediumGray} fontSize={FontSize.xsmall}>
                            切り替え
                        </Text>
                    </Box>
                    <Image
                        height={'14px'}
                        width={'14px'}
                        marginLeft={'2px'}
                        source={require('../../../assets/images/icon-change.svg')}
                    />
                </Pressable>
                <Pressable
                    flexDirection="row"
                    alignItems="center"
                    onPress={() => setIsContestAreaSelectModalVisible(true)}
                >
                    <Image height={'10px'} width={'5px'} source={require('../../../assets/images/icon-arrow.svg')} />
                    <Text marginLeft={'3px'} fontSize={FontSize.xsmall} color={Colors.primary}>
                        {'開催中のコンテスト'}
                    </Text>
                </Pressable>
            </HStack>
        </Box>
    );
};

export default AreaContestBar;
