import { Box, HStack, Image, Pressable } from 'native-base';
import React from 'react';
import { View, Text } from 'react-native';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';
import { formatDate } from '../../../utils/utils';

const List = (props: { data: any[]; onPress: (prop: any) => void }) => {
    const Colors: ColorNames = useThemeColors();

    const { data, onPress, ...rest } = props;

    return (
        <HStack bg={Colors.white} alignItems="center" justifyContent="center" width="100%" flexDirection="column">
            {data.map((data: any, index: number) => {
                return (
                    <Pressable
                        key={index}
                        style={{
                            width: '100%',
                            alignItems: 'center'
                        }}
                        onPress={() => onPress(data)}
                    >
                        <Box
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                            paddingTop="9px"
                            paddingBottom="9px"
                            paddingRight="20px"
                        >
                            <Box flexDirection="column" width="100%">
                                <Text
                                    style={{
                                        color: Colors.primary,
                                        fontSize: 16,
                                        fontWeight: '400'
                                    }}
                                >
                                    {data.title}
                                </Text>
                                <Text
                                    style={{
                                        color: Colors.mediumGray,
                                        fontSize: 12,
                                        fontWeight: '400'
                                    }}
                                >
                                    期限：{formatDate(new Date(data.applicationEnd), 'yyyy/MM/dd')}
                                </Text>
                            </Box>
                            <Image
                                height="13.33px"
                                width="7.85px"
                                source={require('../../../assets/images/detail-gray.svg')}
                            />
                        </Box>
                        <View
                            style={{
                                borderBottomWidth: 1,
                                borderStyle: 'solid',
                                width: '100%',
                                borderColor: Colors.lighterGray
                            }}
                        />
                    </Pressable>
                );
            })}
        </HStack>
    );
};

export default List;
