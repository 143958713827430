import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import { ScrollView, Box, HStack, Spinner } from 'native-base';
import { GlobalContext } from '../contexts/Global';
import { AuthContext } from '../contexts/Auth';
import Modal from 'react-native-modal';
import Zindex from '../constants/Zindex';
import ContestBar from '../stories/components/Bar/ContestBar';
import ContestSwitch from '../stories/components/Drawer/ContestSwitch';
import { useThemeColors } from './Themed';
import { ColorNames } from '../types';

const Colors: ColorNames = useThemeColors();

export default function RankingContestSelect(props: any) {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    // const [ranking, setRanking] = useState<any[]>([]);
    const { areaId, setAreaId, areaName, setAreaName } = useContext(GlobalContext);
    const { currentUser } = useContext(AuthContext);

    const { contestNames, selectedContestId, setSelectedContestId } = props;
    let contestName = contestNames[selectedContestId].title;
    if (contestName.length > 13) {
        contestName = contestName.slice(0, 13) + '...';
        console.log('contestName.length', contestName.length);
        console.log('contestName', contestName);
    }

    // コンテスト選択
    function selectContest(id: any) {
        setSelectedContestId(id);
        setModalVisible(false);
    }

    return (
        <Box>
            {contestName ? (
                <ContestBar
                    contestName={contestName}
                    modalVisible={modalVisible}
                    onPress={() => setModalVisible(true)}
                />
            ) : (
                <Spinner color={Colors.white} size="sm" width={'fit-content'} paddingRight={50} paddingLeft={50} />
            )}
            <Modal isVisible={modalVisible} animationIn="slideInLeft" animationOut="slideOutLeft" style={{ margin: 0 }}>
                <ContestSwitch
                    modalVisible={modalVisible}
                    setModalVisible={() => setModalVisible(false)}
                    contests={contestNames}
                    selectContest={selectContest}
                    areaId={areaId}
                    selectedContestId={selectedContestId}
                    contestName={contestName}
                ></ContestSwitch>
            </Modal>
        </Box>
    );
}
