import _axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { auth, initFirebaseAuth } from '../firebase/firebase';
import { User, signOut } from 'firebase/auth';
import ENV from 'expo-constants';
import { Outlet, useNavigate } from '../router/react-router';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/Auth';
import { GlobalContext } from '../contexts/Global';
import { Consts } from '../constants/Consts';

const baseApiUrl = ENV.manifest!.extra!.apiUrl;

/**
 * デフォルト config の設定
 */
const axios = _axios.create({
    baseURL: baseApiUrl,
    // timeout: 3000,
    headers: {
        'Content-Type': 'application/json'
    }
});

/**
 * リクエストインターセプター
 */
axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (config.headers !== undefined) {
        const currentFirebaseAuthUser: User = await initFirebaseAuth();
        let idToken = await currentFirebaseAuthUser.getIdToken();

        // 最終ログインから３時間後はtokenを無効。ただし投稿の登録・編集APIリクエスト時のみ無視。
        const lastLoginAt = (currentFirebaseAuthUser.toJSON() as { lastLoginAt: string }).lastLoginAt;
        if (
            !(config.method == 'POST' || config.method == 'PATCH') &&
            new Date().getTime() - Number(lastLoginAt) > Consts.AUTH_TIMEOUT
        ) {
            console.error('auth timeout.');
            idToken = '';
        }

        // ヘッダにアクセストークンを埋める
        config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
});

/**
 * レスポンスインターセプター
 */
// axios.interceptors.response.use(
//     (response: AxiosResponse) => {
//         return response;
//     },
//     (error: AxiosError) => {
//         switch (error?.response?.status) {
//             case 400:
//                 console.error('not found');
//                 break;
//             case 401:
//                 console.error('unauthorized');
//                 break;
//             default:
//                 break;
//         }
//         return Promise.reject(error);
//     }
// );

export default axios;
