// contexts/auth.js
import React, { Component, createContext, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
// import navigation from '../navigation';
import { User } from '../types';
import { getBrowserUid } from '../utils/utils';
import {
    browserLocalPersistence,
    inMemoryPersistence,
    setPersistence,
    signInWithEmailAndPassword,
    signOut
} from 'firebase/auth';
import { auth } from '../firebase/firebase';
import axios from '../axios/axios';
import { Consts } from '../constants/Consts';
import { sendLoginUserInfo } from '../utils/gtm';

type AuthContextProps = {
    currentUser: User | null | undefined;
};
// Contextの生成
export const AuthContext = createContext<any>({ currentUser: undefined });
// ContextにはProviderとConsumerというコンポーネントが含まれる
export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = ({ children }: any) => {
    const [isAuth, setIsAuth] = useState(false);
    const [isInitialAuthLoading, setIsInitialAuthLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>('');

    useEffect(() => {
        console.log('Start Initial Login Check.');
        setIsInitialAuthLoading(true);
        // 初回ログインチェック。ログインユーザーのセット。
        axios
            .post(`/getIdentityMall`)
            .then((res) => {
                if (res.data.status == 'error') {
                    setIsAuth(false);
                    setCurrentUser(null);
                } else {
                    console.log('Start Initial Login Check. Complete: ', res.data);
                    setIsAuth(true);
                    setCurrentUser(res.data);
                }
                setIsInitialAuthLoading(false); // 初回認証チェック完了
            })
            .catch((error: any) => {
                setIsAuth(false);
                setCurrentUser(null);
                setIsInitialAuthLoading(false); // 初回認証チェック完了
            });
    }, []);

    const login = async (loginId: string) => {
        console.log('excute login method.');
        // 初期化
        setLoginErrorMessage('');

        try {
            // Firebase Authのメールアドレス（管理側とは異なることに注意）
            const email = loginId + '@' + Consts.LOGIN_MAIL_DOMAIN_USER;
            // await setPersistence(auth, inMemoryPersistence);
            const userCredential = await setPersistence(auth, browserLocalPersistence).then(() => {
                // [TODO] passwordは全ユーザー固定のため環境変数にする
                return signInWithEmailAndPassword(auth, email, '12345678');
            });
            // const userCredential = await signInWithEmailAndPassword(auth, email, password);

            const { data } = await axios.get(`/csrf-token`);
            const token = await userCredential.user.getIdToken(true);
            const result = await axios.post(`/sessionLogin`, {
                idToken: token,
                csrfToken: data.csrfToken
            });

            if (result.status == 200) {
                console.log('Login Seccess!');
                setIsAuth(true);
                setCurrentUser(result.data);
                // GTMを使ってイベントをGA4へ送信
                sendLoginUserInfo(result.data.id, result.data.mallName);
                // 使用ブラウザに対するユニークなIDを保存
                localStorage.getItem('browserId') ? null : localStorage.setItem('browserId', getBrowserUid());
            } else {
                console.log('Login Failed!');
                setLoginErrorMessage('ログインに失敗しました');
                setIsAuth(false);
                setCurrentUser(null);
            }
        } catch (error) {
            console.error('Login Error Occurred!', error);
            setLoginErrorMessage('正しいログインIDを入力してください');
            setIsAuth(false);
            setCurrentUser(null);
        }
    };

    const logout = async () => {
        console.log('excute logout method.');
        // await axios.post(`/sessionLogout`).then(()=> {
        setIsAuth(false);
        setCurrentUser(null);
        signOut(auth);
        // }).catch((error: any)=>{
        //   setIsAuth(false);
        //   setCurrentUser(null);
        // });
    };

    return (
        <AuthContext.Provider
            value={{
                isInitialAuthLoading,
                isAuth,
                setIsAuth,
                currentUser,
                setCurrentUser,
                login,
                logout,
                loginErrorMessage
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
