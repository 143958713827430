import { Box, HStack, Image } from 'native-base';
import React, { useState } from 'react';
import { Pressable, View, Text } from 'react-native';
import AreaBar from '../Bar/AreaBar';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const AreaSwitch = (props: {
    areas: any[];
    selectArea: (id: number) => void;
    areaName: string;
    modalVisible: boolean;
    setModalVisible: () => void;
    areaId?: number;
}) => {
    const { selectArea, areas, areaName, modalVisible, setModalVisible, areaId } = props;

    return (
        <>
            <HStack
                bg={Colors.white}
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                height="100%"
                flexDirection="column"
            >
                <AreaBar
                    areaName={areaName}
                    onPress={() => null}
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                />
                {areas.map((data: any, index: number) => {
                    return (
                        <Pressable
                            style={{
                                width: '100%',
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderStyle: 'solid',
                                borderColor: Colors.lighterGray
                            }}
                            onPress={() => selectArea(data.id)}
                            key={data.id}
                            disabled={data.id == areaId}
                        >
                            <Box
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                                paddingTop="9px"
                                paddingBottom="9px"
                                paddingLeft="20px"
                                paddingRight="20px"
                                minHeight={53}
                            >
                                {data.id == areaId ? (
                                    <Text
                                        style={{
                                            color: Colors.black,
                                            fontSize: 16,
                                            fontWeight: '700'
                                        }}
                                    >
                                        {data.areaName}地区
                                    </Text>
                                ) : (
                                    <Text
                                        style={{
                                            color: Colors.primary,
                                            fontSize: 16,
                                            fontWeight: '400'
                                        }}
                                    >
                                        {data.areaName}地区
                                    </Text>
                                )}

                                <Image
                                    height="13.33px"
                                    width="7.85px"
                                    source={require('../../../assets/images/detail-gray.svg')}
                                />
                            </Box>
                        </Pressable>
                    );
                })}
            </HStack>
        </>
    );
};

export default AreaSwitch;
