import { Box } from 'native-base';
import React from 'react';
import { Button, ScrollView, TouchableOpacity, View, StyleSheet, Text, Pressable } from 'react-native';

const RoundButton = (props: any) => {
    const {
        text,
        color,
        borderColor,
        fontColor,
        alignItems,
        onPress,
        disabled,
        backgroundColor,
        width,
        maxWidth,
        ...rest
    } = props;
    return (
        <Pressable
            style={{
                paddingTop: 13,
                paddingBottom: 13,
                paddingRight: 24,
                paddingLeft: 24,
                margin: 10,
                alignItems: alignItems,
                borderRadius: 50,
                height: 50,
                width: width,
                maxWidth: maxWidth,
                borderWidth: 2,
                borderColor: borderColor,
                backgroundColor: backgroundColor
            }}
            disabled={disabled}
            onPress={onPress}
        >
            <Text style={{ color: fontColor, fontWeight: 'bold' }}>{props.text}</Text>
        </Pressable>
    );
};
export default RoundButton;
