import axios from '../axios/axios';
import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, ActivityIndicator, Dimensions } from 'react-native';
import ImageMain from './ImageMain';
import { HStack, ScrollView, Spinner, useMediaQuery } from 'native-base';
import { GlobalContext } from '../contexts/Global';
import { usePrevious } from '../utils/utils';
import { useOutletContext } from '../router/react-router';
import { useThemeColors } from './Themed';
import { ColorNames } from '../types';

const Colors: ColorNames = useThemeColors();

export default function ImageList(props: any) {
    const { searchSettings, selected, ...rest } = props;
    const { height } = Dimensions.get('window');
    const { goScrollBy } = useOutletContext<any>();
    const [start, setStart] = useState<number>(0);
    const [startAfterPostId, setStartAfterPostId] = useState('');
    const count = 12;
    const [posts, setPosts] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const { keyword, isScrollEnd, setIsScrollEnd, postComplete, setPostComplete } = useContext(GlobalContext);
    const prevIsScrollEnd = usePrevious(isScrollEnd);
    const prevSelected = usePrevious(selected);
    const prevKeyword = usePrevious(keyword);
    const prevSearchSettings: any = usePrevious(searchSettings);

    function ListFooterComponent() {
        if (loading && !isFinished) {
            return (
                <HStack height={100} justifyContent="center" alignItems="center">
                    <Spinner color={Colors.primary} size="lg" />
                </HStack>
            );
        }
        return null;
    }

    const getList = async (init: boolean) => {
        console.log({ isFinished, loading, init, height });

        if (!searchSettings) return console.log('There is no searchSettings');
        if (loading && !init) return console.log('While loading');
        if (isFinished && !init) return console.log('Can not read more');

        console.log('Start getList()');

        setLoading(true);
        setIsFinished(false);

        let param_start = start;
        let param_startAfterPostId = startAfterPostId;

        if (init) {
            param_start = 0;
            param_startAfterPostId = '';
            setPosts([]);
        }

        let params = '?';
        params += '_start=' + param_start + '&_end=' + (param_start + count) + '&noEditKey=1';
        if (param_startAfterPostId) params += '&startAfterPostId=' + param_startAfterPostId;

        //タブの検索条件で検索
        const searchTabSettings = searchSettings?.tabSettings;
        const selectedSearchTabSetting = searchTabSettings ? searchTabSettings[selected] : null;
        if (selectedSearchTabSetting?.jobTypeId) {
            params += `&jobTypeId=${selectedSearchTabSetting.jobTypeId}`;
        }
        if (selectedSearchTabSetting?.contestId) {
            params += `&contestId=${selectedSearchTabSetting.contestId}`;
        }
        if (selectedSearchTabSetting?.areaId) {
            params += `&areaId=${selectedSearchTabSetting.areaId}`;
        }
        if (selectedSearchTabSetting?.contestType) {
            params += `&contestType=${selectedSearchTabSetting.contestType}`;
        }
        if (selectedSearchTabSetting?.hasPrize) {
            params += `&prize=${selectedSearchTabSetting.hasPrize}`;
        }
        if (selectedSearchTabSetting?.tagId) {
            params += `&tagId=${selectedSearchTabSetting.tagId}`;
        }
        if (selectedSearchTabSetting?._order) {
            params += `&_order=${selectedSearchTabSetting._order}`;
        }
        if (selectedSearchTabSetting?._sort) {
            params += `&_sort=${selectedSearchTabSetting._sort}`;
        }
        // "表示順番の基準"と"表示順番"のプルダウンをコメントアウトしたため、設定ない場合はID降順で表示
        if (!selectedSearchTabSetting?._order) {
            params += '&_order=DESC';
        }
        if (!selectedSearchTabSetting?._sort) {
            params += '&_sort=id';
        }
        if (selectedSearchTabSetting?.q) {
            params += '&q=' + encodeURIComponent(selectedSearchTabSetting.q);
        }

        // フリーワード検索時は、全施設横断検索。タブは非アクティブにする。
        if (keyword) {
            params =
                '?_start=' +
                param_start +
                '&_end=' +
                (param_start + count) +
                '&q=' +
                encodeURIComponent(keyword) +
                '&_order=DESC&_sort=id';
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));

        // データ取得URL
        const apiUrl = `/posts${params}`;

        await axios
            .get(apiUrl)
            .then((res) => {
                // 初回（変更時）取得の場合、データを追加する直前で取得リストをリセットしないとバグが発生する
                if (init) {
                    setPosts([]);
                }

                // データ追加
                res.data.forEach((element: any) => {
                    setPosts((posts) => [...posts, element]);
                });

                // 開始位置更新
                setStart(param_start + res.data.length);
                setStartAfterPostId(res.data[res.data.length - 1].id);

                // これ以上取得できない場合は、isFinishedフラグを立てて、リロードできないようにする
                setIsFinished(res.data.length < count);

                // 少し上へスクロールしておく（次回更新スクロールイベント取得のため）
                goScrollBy(-2, false);

                // 読み込み完了
                setLoading(false);
                setIsScrollEnd(false);
            })
            .catch((error) => {
                console.log('Cannot catch any posts');
                setIsFinished(false);
                setLoading(false);
                setIsScrollEnd(false);
            });
    };

    useEffect(() => {
        // searchSettings取得・変更後
        if ((!prevSearchSettings && searchSettings) || prevSearchSettings?.id !== searchSettings?.id) {
            console.log('New searchSettings');
            getList(true);
            return;
        }
        // タブ選択変更後
        if (prevSelected !== selected) {
            console.log('New selected posts tab');
            getList(true);
            return;
        }
        // フリー検索ワード変更時
        if (prevKeyword !== keyword) {
            console.log('New keyword: ', keyword);
            getList(true);
            return;
        }
        // 投稿完了後
        if (postComplete) {
            console.log('Post Completed');
            setPostComplete(false);
            getList(true);
            return;
        }

        // スクロール初期値→初回取得時はリターン
        if (prevIsScrollEnd === '' && !isScrollEnd) return;

        if (!prevIsScrollEnd && isScrollEnd) {
            // スクロールリロード実行
            console.log('Do scroll loading');
            getList(false);
        } else if (prevIsScrollEnd && !isScrollEnd) {
            // スクロールリロード完了
            console.log('Done scroll loading');
        } else {
            getList(true);
        }
    }, [searchSettings, selected, keyword, isScrollEnd, postComplete]);

    if (!searchSettings) return <ListFooterComponent />;

    return (
        <View
            style={{
                backgroundColor: Colors.white,
                paddingTop: 11,
                // スクロールイベントを取得するためにScrollView直下のコンテンツがScrollViewより高さを持っている必要がある。
                // 以前は"100vh"を指定していたが、Dimensions.get('window')で取得したものを利用するように変更
                minHeight: height
            }}
        >
            <View style={styles.wrapperStyle}>
                {posts.map((data: any, index: any) => {
                    return <ImageMain key={index} imageInfo={data} />;
                })}
            </View>
            <ListFooterComponent />
        </View>
    );
}

const styles = StyleSheet.create({
    wrapperStyle: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    },
    loading: {
        maxHeight: 40
    }
});
