import React, { useContext, useEffect } from 'react';
import { Router, Routes, Route, Link, useLocation } from './react-router';

import { AuthContext } from '../contexts/Auth';
import { Outlet, useNavigate } from './react-router';

import TabScreen from '../screens/TabScreen';
import PostsScreen from '../screens/PostsScreen';
import FormsScreen from '../screens/FormsScreen';
import FormScreen from '../screens/FormScreen';
import ResultsScreen from '../screens/ResultsScreen';
import ResultsDetailScreen from '../screens/ResultsDetailScreen';
import RankingScreen from '../screens/RankingScreen';
import RecommendScreen from '../screens/RecommendScreen';
import RecommendDetailScreen from '../screens/RecommendDetailScreen';
import LoginScreen from '../screens/LoginScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import ENV from 'expo-constants';
import { User } from 'firebase/auth';
import { initFirebaseAuth } from '../firebase/firebase';
import AxiosClientProvider from '../axios/AxiosClientProvider';
import { Consts } from '../constants/Consts';

export default function Routing() {
    return (
        // サブディレクトリ用にbasenameを設定
        <Router basename={ENV.manifest!.extra!.ROUTER_BASENAME}>
            <Routes>
                <Route element={<AxiosClientProvider />}>
                    <Route path="/" element={<PrivateRoute />}>
                        <Route element={<TabScreen />}>
                            <Route path="/" element={<RedirectToTop />} />
                            <Route path="posts" element={<PostsScreen />} />
                            <Route path="/posts/:id" element={<PostsScreen />} />
                            <Route path="forms" element={<FormsScreen />} />
                            <Route path="results" element={<ResultsScreen />} />
                            <Route path="/results/:id" element={<ResultsDetailScreen />} />
                            <Route path="form" element={<FormScreen />} />
                            <Route path="rank" element={<RankingScreen />} />
                            <Route path="recommend" element={<RecommendScreen />} />
                            <Route path="/recommend/:id" element={<RecommendDetailScreen />} />
                        </Route>
                    </Route>
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/*" element={<NotFoundScreen />} />
                </Route>
            </Routes>
        </Router>
    );
}

const PrivateRoute = () => {
    const { isInitialAuthLoading, isAuth, setIsAuth, currentUser, setCurrentUser, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    // routingの遷移ごとのログインチェック
    useEffect(() => {
        (async () => {
            console.log('Location', location);
            const currentFirebaseAuthUser: User = await initFirebaseAuth();
            // 最終ログインから３時間後ならログアウト扱いにする。
            const lastLoginAt = (currentFirebaseAuthUser.toJSON() as { lastLoginAt: string }).lastLoginAt;
            if (new Date().getTime() - Number(lastLoginAt) > Consts.AUTH_TIMEOUT) {
                console.log('Session Timeout Logout');
                setIsAuth(false);
                setCurrentUser(null);
            }
        })();
    }, [location]);

    // If not, return element that will navigate to login page
    useEffect(() => {
        if (isInitialAuthLoading) return;
        console.log('Login Check', isAuth);
        console.log('currentUser', currentUser);
        if (!isAuth) {
            navigate('/login');
            logout();
        }
    }, [isInitialAuthLoading, isAuth]);

    // If authorized, return an outlet that will render child elements
    return <Outlet />;
};

const RedirectToTop = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/posts');
    }, []);

    return <PostsScreen />;
};
