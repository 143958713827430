import { Box, Center, HStack, Image, Pressable } from 'native-base';
import React from 'react';
import { Button, ScrollView, View, Text, StyleSheet } from 'react-native';
import symbolicateStackTrace from 'react-native/Libraries/Core/Devtools/symbolicateStackTrace';
import { useNavigate } from 'react-router-native';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';
import FontSize from '../../../constants/FontSize';

const Colors: ColorNames = useThemeColors();

const BackBar = (props: any) => {
    const { backgroundColor, title, age, url, back, onPress, isRight, ...rest } = props;

    return (
        <HStack
            width="100%"
            height="44px"
            backgroundColor={backgroundColor}
            alignItems="center"
            paddingLeft="17px"
            paddingRight="17px"
            borderBottomWidth={1}
            borderBottomColor={Colors.lightGray}
            flexDirection={isRight ? 'row-reverse' : 'row'}
        >
            <Pressable
                onPress={onPress}
                alignItems="center"
                justifyContent="center"
                flexDirection={isRight ? 'row-reverse' : 'row'}
            >
                <Image
                    style={isRight ? { transform: [{ rotate: '180deg' }] } : null}
                    height={'10px'}
                    width={'18px'}
                    source={require('../../../assets/images/back.svg')}
                />
                <Text
                    style={{
                        marginLeft: '15px',
                        marginRight: '15px',
                        fontWeight: '400',
                        fontSize: FontSize.medium,
                        color: Colors.mediumGray
                    }}
                >
                    戻る
                </Text>
            </Pressable>
            <HStack
                width="100%"
                space={1}
                left={0}
                position="absolute"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Center>
                    <Text>{title}</Text>
                </Center>
            </HStack>
        </HStack>
    );
};

export default BackBar;
