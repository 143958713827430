import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { IconButton, Pressable, Image, HStack, Center, Box, ScrollView, useMediaQuery, Spinner } from 'native-base';
import Swiper from '../components/customSwiper';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { useNavigate } from '../router/react-router';
import axios from '../axios/axios';
import { LinearGradient } from 'expo-linear-gradient';
import Label from '../stories/components/Label/Label';
import ImageMain from '../components/ImageMain';
import FontSize from '../constants/FontSize';
import LikeButton from '../stories/components/ButtonNative/LikeButton';
import { GlobalContext } from '../contexts/Global';
import { map } from '@firebase/util';
import { formatDate } from '../utils/utils';
import EditModal from '../components/EditModal';
import { AuthContext } from '../contexts/Auth';
import PicPhotoEditModal from '../components/PicPhotoEditModal';
import PostEditResultModals from '../components/PostEditResultModals';
import PostDeleteCheckModals from '../components/PostDeleteModals';
import PostDeleteResultModals from '../components/PostDeleteResultModals';
import UserNameBar from '../stories/components/Bar/UserNameBar';
import { useThemeColors } from '../components/Themed';
import { ColorNames } from '../types';
import VideoViewer from '../components/VideoViewer';
import { Consts } from '../constants/Consts';
import { useForm } from 'react-hook-form';

interface scoring {
    text: string;
    description: string;
    allocation: number;
}
interface question {
    text: string;
    type: number;
    options: {
        optionText: string;
    }[];
}

interface Contest {
    id: number; // contestId
    title: string; //contest name
    subtitle: string;
    contestType: number; // コンテストの種類
    description: string; //contest description
    imageUrl: string; //contest image
    publishStart: string;
    publishEnd: string;
    applicationStart: string;
    applicationEnd: string;
    areaId: number; // 所属地区
    maxSelectionNumber: number; // 最大審査次数  2 or 3
    selectionStatus: number; // 審査状況
    questions: Array<question>; // 設問配列
    scoringItems: Array<scoring>; // 採点項目配列
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    delFlag: number;
}

interface file {
    url: string;
    fileType: string;
}

interface Post {
    id: number; // postId
    areaId: number; // areaId
    mallId: number; // mallId
    contestId: number; //contestId
    tenantName: string; //店名
    jobTypeId: number | undefined; //業種ID
    files: Array<file>; //複数ファイルurl配列
    answers: Array<Answers>; //回答
    tagIds: Array<number>;
    newTags: Array<string>; //新しく追加するタグ
    editKey: string; //faker.internet.password(6, false, /[0-9a-z]/)
}

interface Answers {
    questionNo: number;
    answer: any;
}

interface PostEditModalsProps {
    contest: Contest;
    post: Post;
    setModalEnd: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PostEditModals(props: PostEditModalsProps) {
    const { contest, post, setModalEnd } = props;

    const Colors: ColorNames = useThemeColors();

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalComplete, setDeleteModalComplete] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [editModalPicPhoto, setEditModalPicPhoto] = useState(false);
    const [modalComplete, setModalComplete] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [postErrorMessage, setPostErrorMessage] = useState('');

    const [fileAccept, setFileAccept] = useState<string>();
    const [images, setImages] = useState<File[] | { url: string }[]>([]); //選択画像リスト
    const [video, setVideo] = useState<File[] | { url: string }[]>([]); //選択動画
    const targetPostApiUrl = `/posts/${post.id}`;

    //編集・削除キーのチェック
    const [editKey, setEditKey] = useState('');
    const [editModal, setEditModal] = useState(false);

    const checkEditKey = (value: string) => {
        const apiUrl_checkEdit = `/posts?id=${post.id}&noEditKey=1`;
        axios.get(apiUrl_checkEdit).then((res: any) => {
            if (res.data[0].editKey == value) {
                setEditModalPicPhoto(true);
                setErrorMsg('');
            } else {
                setErrorMsg('編集・削除キーが正しくありません。');
            }
        });
    };

    const getAllEditInfo = () => {
        if (contest.contestType == Consts.CONTEST_TYPES.IMAGE) {
            setFileAccept(Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION);
            setImages(post.files);
        } else if (contest.contestType == Consts.CONTEST_TYPES.VIDEO) {
            setFileAccept(Consts.ACCEPTABLE_VIDEO_FILE_EXTENSION);
            setVideo(post.files);
        }
    };

    useEffect(() => {
        getAllEditInfo();
    }, []);

    return (
        <Box
            style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '13px',
                paddingBottom: '13px'
            }}
        >
            <TextInput
                keyboardType="numeric"
                placeholder="編集・削除キーを入力して下さい。"
                textContentType="password"
                secureTextEntry={true}
                style={{
                    width: '100%',
                    maxWidth: '240px',
                    height: '46px',
                    backgroundColor: Colors.lightestGray,
                    borderColor: Colors.lightGray,
                    borderRadius: 4,
                    borderWidth: 1,
                    padding: 10
                }}
                maxLength={4}
                value={editKey}
                onChangeText={(editKey) => setEditKey(editKey)}
            />
            <Pressable
                style={[
                    {
                        width: 'fit-content',
                        borderRadius: 50,
                        height: 48,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: Colors.primary,
                        marginHorizontal: 5,
                        marginTop: '13px'
                    },
                    editKey.length == 4 ? { backgroundColor: Colors.primary } : { backgroundColor: Colors.lightGray }
                ]}
                onPress={editKey.length == 4 ? () => checkEditKey(editKey) : null}
            >
                <Text
                    style={{
                        color: Colors.white,
                        fontWeight: '700',
                        paddingHorizontal: 25
                    }}
                >
                    次へすすむ
                </Text>
            </Pressable>
            {errorMsg ? (
                <Text
                    style={{
                        color: Colors.error,
                        marginTop: 10
                    }}
                >
                    {errorMsg}
                </Text>
            ) : null}
            <PicPhotoEditModal
                editModalPicPhoto={editModalPicPhoto}
                setEditModalPicPhoto={setEditModalPicPhoto}
                editModal={editModal}
                setEditModal={setEditModal}
                post={post}
                selectedContest={contest}
                setModalComplete={setModalComplete}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                setModalError={setModalError}
                setPostErrorMessage={setPostErrorMessage}
                images={images}
                setImages={setImages}
                video={video}
                setVideo={setVideo}
                fileAccept={fileAccept}
            />
            <EditModal
                editModalPicPhoto={editModalPicPhoto}
                setEditModalPicPhoto={setEditModalPicPhoto}
                editModal={editModal}
                setEditModal={setEditModal}
                post={post}
                images={images}
                setImages={setImages}
                video={video}
                setVideo={setVideo}
                apiUrl={targetPostApiUrl}
                editKey={editKey}
                areaId={post.areaId}
                contest={contest}
                setModalComplete={setModalComplete}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                setModalError={setModalError}
                setPostErrorMessage={setPostErrorMessage}
            />
            <PostEditResultModals
                editModal={editModal}
                setEditModal={setEditModal}
                modalComplete={modalComplete}
                setModalComplete={setModalComplete}
                modalError={modalError}
                setModalError={setModalError}
                postErrorMessage={postErrorMessage}
                setModalEnd={setModalEnd}
            />
            <PostDeleteCheckModals
                apiUrl={targetPostApiUrl}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteModalComplete={deleteModalComplete}
                setDeleteModalComplete={setDeleteModalComplete}
                modalError={modalError}
                setModalError={setModalError}
                postErrorMessage={postErrorMessage}
            />
            <PostDeleteResultModals
                editModal={editModal}
                setEditModal={setEditModal}
                deleteModalComplete={deleteModalComplete}
                setDeleteModal={setDeleteModal}
                setDeleteModalComplete={setDeleteModalComplete}
                modalError={modalError}
                setModalError={setModalError}
                postErrorMessage={postErrorMessage}
            />
        </Box>
    );
}
