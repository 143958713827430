import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { IconButton, Box, Image, Pressable, HStack } from 'native-base';
import Swiper from '../components/customSwiper';
import { MaterialIcons } from '@expo/vector-icons';
import axios from '../axios/axios';
import { useThemeColors } from './Themed';
import { ColorNames } from '../types';
import VideoViewer from './VideoViewer';

const Colors: ColorNames = useThemeColors();

export default function ResultPrize(props: any) {
    const data = props.data;

    const [mallName, setMallName] = useState('');

    useEffect(() => {
        axios
            .get(`/malls/${data.mallId}`)
            .then((result: any) => {
                setMallName(result.data.mallName);
            })
            .catch((error: any) => {
                setMallName('');
            });
    }, []);

    return (
        <View
            style={{
                width: '100%',
                marginTop: '18px',
                marginBottom: '30px',
                backgroundColor: Colors.white
            }}
        >
            <Box style={styles.titleBox}>
                <Text style={styles.title}>{data.tenantName}</Text>
                <Text style={styles.subTitle}>{mallName}</Text>
            </Box>
            {data.files[0].fileType === 'images' ? (
                <Swiper
                    vertical={false}
                    innerContainerStyle={{
                        height: '375px',
                        backgroundColor: Colors.darkerGray
                    }}
                    controlsProps={{
                        dotsTouchable: true,
                        prevPos: false,
                        nextPos: false,
                        dotActiveStyle: { backgroundColor: Colors.primary }
                    }}
                >
                    {data.files.map((file: any, index: number) => {
                        return (
                            <View key={index} style={[styles.slideContainer]}>
                                <Image resizeMode={'contain'} size="full" source={{ uri: file.url }} alt="photo" />
                            </View>
                        );
                    })}
                </Swiper>
            ) : (
                <VideoViewer
                    isAutoPlay={false}
                    videoUrl={data.files[0].url.split('?')[0] + '__compressed.mp4?alt=media'}
                />
            )}
            <Box style={styles.commentBox}>
                <HStack maxWidth="1000px" width="100%" flexDirection={'column'}>
                    <Text style={styles.commentTitle}>審査員コメント</Text>
                    {data.scores.map((score: any, index: number) => {
                        return (
                            <Box
                                key={index}
                                width="100%"
                                borderTopWidth={1}
                                borderTopColor={index > 0 ? Colors.lightGray : 'transparent'}
                                borderTopStyle="dashed"
                                paddingTop={'10px'}
                                paddingBottom={'10px'}
                            >
                                <Text style={styles.commentArea}>{score.comment}</Text>
                            </Box>
                        );
                    })}
                </HStack>
            </Box>
        </View>
    );
}

const styles = StyleSheet.create({
    titleBox: {
        alignItems: 'center',
        margin: '12px'
    },
    title: {
        fontWeight: '700',
        fontSize: 20,
        marginBottom: '3px'
    },
    subTitle: {
        fontWeight: '400',
        fontSize: 12,
        color: Colors.mediumGray
    },
    slideContainer: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    commentBox: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 13,
        marginRight: 14,
        marginBottom: 20,
        marginLeft: 14
    },
    commentTitle: {
        fontWeight: '400',
        color: Colors.darkGray,
        fontSize: 12,
        marginBottom: '8px'
    },
    commentArea: {
        fontWeight: '300',
        fontSize: 16
    }
});
