import React from 'react';
import { Center, Heading, Box, AspectRatio, Stack, Text, HStack, Image } from 'native-base';
import { StyleSheet, View, TouchableOpacity, ActivityIndicator } from 'react-native';
import FontSize from '../../../constants/FontSize';
import { useNavigate } from '../../../router/react-router';
import { formatDate } from '../../../utils/utils';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const dateFormat = (date: any) => {
    return `${formatDate(new Date(date), 'yyyy.MM.dd')}`;
};

export default function RecommendDetailCard(props: any) {
    const recommendInfo = props.recommendInfo;
    const navigate = useNavigate();
    const goToDetail = () => {
        if (recommendInfo.youtubeUrl) {
            window.open(recommendInfo.youtubeUrl, '_blank');
        } else if (recommendInfo.url) {
            window.open(recommendInfo.url, '_blank');
        } else {
            navigate(`/recommend/${recommendInfo.id}`);
        }
    };

    return (
        <TouchableOpacity
            style={{
                width: '200px'
            }}
            onPress={goToDetail}
        >
            <Box width="100%" paddingTop="29px" paddingRight="20px" paddingBottom="20px">
                <Image
                    height="112.22px"
                    width="100%"
                    resizeMode="cover"
                    marginLeft="auto"
                    marginRight="auto"
                    borderRadius="8px"
                    source={
                        recommendInfo.imageUrl
                            ? { uri: recommendInfo.imageUrl }
                            : require('../../../assets/images/topResult.svg')
                    }
                />
                <Text
                    paddingTop="8.77px"
                    style={{
                        fontWeight: '400',
                        fontSize: 16,
                        lineHeight: 23.17
                    }}
                >
                    {recommendInfo.title}
                </Text>
                <Box flexDirection="row">
                    <Text
                        paddingTop="3px"
                        style={{
                            fontWeight: '400',
                            fontSize: FontSize.xsmall,
                            color: Colors.mediumGray,
                            lineHeight: 23.17
                        }}
                    >
                        {dateFormat(recommendInfo.publishStart)}
                    </Text>
                </Box>
            </Box>
        </TouchableOpacity>
    );
}
