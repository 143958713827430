import { LinearGradient } from 'expo-linear-gradient';
import { Box, Image } from 'native-base';
import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import FontSize from '../../../constants/FontSize';
import RoundButton from '../ButtonNative/RoundButton';
import RoundNumber from '../ButtonNative/RoundNumber';
import TableData from '../ButtonNative/TableData';
import TableHeader from '../ButtonNative/TableHeader';
import Title from '../ButtonNative/Title';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const RankingArea = (props: any) => {
    const { rankingData, contestNames, selectedContestId, rankingTitle, annotation } = props;
    const [isActive, setActive] = useState(false);
    const backgroundHeight = '430px';

    const rankingData4_10 = rankingData.slice(3, 10);
    let rankingData_rank4 = 3;
    const rankingData11 = rankingData.slice(10);
    let rankingData_rank11 = 10;

    const contestName = contestNames[selectedContestId].title;

    const toggleClass = () => {
        setActive(!isActive);
    };

    if (rankingData.length === 0) return <></>;
    return (
        <View
            style={{
                paddingBottom: '30px',
                marginBottom: '1px',
                backgroundColor: 'transparent',
                position: 'relative',
                minHeight: backgroundHeight
            }}
        >
            <LinearGradient
                colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                start={[0, 0]}
                end={[1, 0]}
                style={{
                    width: '100%',
                    height: '100%',
                    maxHeight: backgroundHeight,
                    position: 'absolute'
                }}
            ></LinearGradient>
            <Box
                style={{
                    paddingTop: 22,
                    paddingLeft: 20,
                    paddingRight: 20
                }}
            >
                <Text
                    style={{
                        fontSize: FontSize.xlarge,
                        fontWeight: '700',
                        color: Colors.white,
                        height: 40,
                        marginRight: 'auto',
                        marginLeft: 'auto'
                    }}
                >
                    {rankingTitle}
                </Text>
                <Text
                    style={{
                        fontSize: FontSize.medium,
                        color: Colors.white,
                        height: 20,
                        marginRight: 'auto',
                        marginLeft: 'auto'
                    }}
                >
                    {contestName}
                </Text>
                <Box
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        maxWidth: '375px',
                        minHeight: '275px',
                        maxHeight: '275px',
                        justifyContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '5px',
                        marginBottom: '15px',
                        paddingTop: '20px'
                    }}
                >
                    {rankingData.length > 1 ? (
                        <Box alignSelf="flex-end" width={128} alignItems="center">
                            <Text style={styles.rankNumber}>2</Text>
                            <Text style={styles.mallName}>{rankingData[1].mallName}</Text>
                            <RoundNumber text={Math.ceil(rankingData[1].count)} />
                            <LinearGradient
                                colors={[Colors.white, 'transparent']}
                                style={{
                                    width: '60px',
                                    height: '54px',
                                    opacity: 0.36,
                                    marginTop: '8px'
                                }}
                            />
                        </Box>
                    ) : null}

                    {rankingData.length > 0 ? (
                        <Box alignSelf="flex-end" width={128} alignItems="center">
                            <Text
                                style={{
                                    position: 'absolute',
                                    color: Colors.white,
                                    fontFamily: 'roboto',
                                    fontSize: FontSize.xlarge,
                                    fontStyle: 'italic',
                                    fontWeight: '600',
                                    textAlign: 'right',
                                    marginLeft: 95,
                                    top: -20,
                                    left: 0
                                }}
                            >
                                1
                            </Text>
                            <Image
                                height={34}
                                width={58}
                                marginBottom="7px"
                                source={require('../../../assets/images/rankTop.svg')}
                            />
                            <Text
                                style={{
                                    color: Colors.white,
                                    fontSize: FontSize.medium,
                                    fontWeight: '700'
                                }}
                            >
                                {rankingData[0].mallName}
                            </Text>
                            <RoundNumber text={Math.ceil(rankingData[0].count)} />
                            <LinearGradient
                                colors={[Colors.white, 'transparent']}
                                style={{
                                    width: '60px',
                                    height: '122px',
                                    opacity: 0.36,
                                    marginTop: '19px'
                                }}
                            />
                        </Box>
                    ) : null}

                    {rankingData.length > 2 ? (
                        <Box alignSelf="flex-end" width={128} alignItems="center">
                            <Text style={styles.rankNumber}>3</Text>
                            <Text style={styles.mallName}>{rankingData[2].mallName}</Text>
                            <RoundNumber text={Math.ceil(rankingData[2].count)} />
                            <LinearGradient
                                colors={[Colors.white, 'transparent']}
                                style={{
                                    width: '60px',
                                    height: '31px',
                                    opacity: 0.36,
                                    marginTop: '8px'
                                }}
                            />
                        </Box>
                    ) : null}
                </Box>

                {annotation ? (
                    <Box
                        style={{
                            position: 'absolute',
                            top: 320,
                            right: 0,
                            left: 0,
                            margin: '0 auto',
                            alignItems: 'center'
                        }}
                    >
                        <Text
                            style={{
                                color: Colors.white,
                                fontSize: 12
                            }}
                        >
                            {annotation}
                        </Text>
                    </Box>
                ) : null}

                {rankingData.length > 3 ? <TableHeader kinds="ランク" name="名前" point="ポイント" /> : null}

                {rankingData4_10.map((rankingData: any, index: number) => {
                    rankingData_rank4 += 1;
                    return (
                        <TableData
                            key={index}
                            kinds={rankingData_rank4}
                            name={rankingData.mallName}
                            point={Math.ceil(rankingData.count)}
                        />
                    );
                })}

                {isActive ? (
                    <Box style={{ paddingBottom: '80px' }}>
                        {rankingData11.map((rankingData: any, index: number) => {
                            rankingData_rank11 += 1;
                            return (
                                <TableData
                                    key={index}
                                    kinds={rankingData_rank11}
                                    name={rankingData.mallName}
                                    point={Math.ceil(rankingData.count)}
                                />
                            );
                        })}
                    </Box>
                ) : null}

                {!isActive && rankingData.length > 10 ? (
                    <TouchableOpacity onPress={() => toggleClass()}>
                        <LinearGradient
                            colors={[Colors.primaryGradientStart, Colors.primaryGradientEnd]}
                            start={[0, 0]}
                            end={[1, 0]}
                            style={styles.loginButton}
                        >
                            <Text style={styles.loginButtonText}>もっと見る</Text>
                        </LinearGradient>
                    </TouchableOpacity>
                ) : null}
            </Box>
        </View>
    );
};

const styles = StyleSheet.create({
    aeonmall: {
        color: Colors.white,
        fontSize: FontSize.small,
        fontWeight: '400'
    },
    mallName: {
        color: Colors.white,
        fontSize: FontSize.small,
        fontWeight: '700'
    },
    rankNumber: {
        color: Colors.white,
        fontFamily: 'roboto',
        fontSize: FontSize.xlarge,
        fontStyle: 'italic',
        fontWeight: '600'
    },
    loginButtonText: {
        color: Colors.white,
        fontWeight: 'bold'
    },
    loginButton: {
        width: 128,
        marginTop: '15px',
        marginRight: 'auto',
        marginLeft: 'auto',
        borderRadius: 50,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export default RankingArea;
