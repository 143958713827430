import { Ionicons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { Box, IconButton } from 'native-base';
import React, { useState } from 'react';
import { TextInput } from 'react-native';
import SearchButton from '../ButtonNative/SearchButton';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';

const Colors: ColorNames = useThemeColors();

const SearchInput = (props: any) => {
    const { value, onChangeText, onPressSearch, active, setActive, ...rest } = props;

    return (
        <Box
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
            height="42px"
            width="100%"
            marginTop="17px"
            marginBottom="17px"
        >
            <TextInput
                style={{
                    height: '42px',
                    width: '100%',
                    padding: '10px',
                    backgroundColor: `${Colors.white}`,
                    alignItems: 'center',
                    borderRadius: 6,
                    borderColor: `${Colors.lightGray}`,
                    borderWidth: 1,
                    fontSize: 16
                }}
                placeholder="キーワードを入力してください"
                value={value}
                onChangeText={(val) => onChangeText(val)}
                onFocus={() => setActive(true)}
            />
            <SearchButton onPressSearch={onPressSearch} active={active} />
        </Box>
    );
};

export default SearchInput;
