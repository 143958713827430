import React, { useContext, useEffect, useState } from 'react';
import Footer from '../components/Footer';
import FooterDisney from '../components/FooterDisney';
import LoginImage from '../components/LoginImage';
import { View } from '../components/Themed';

export default {
    // light:本番, dark:未使用
    light: {
        LoginImage: (props: any) => <LoginImage width={275} height={96} {...props} />,
        Footer: (props: any) => <Footer {...props} />
    },
    dark: {
        LoginImage: (props: any) => <LoginImage width={275} height={96} {...props} />,
        Footer: (props: any) => <Footer {...props} />
    },
    disney: {
        LoginImage: (props: any) => <LoginImage width={290} height={145} {...props} />,
        Footer: (props: any) => <FooterDisney {...props} />
    }
};
