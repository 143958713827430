enum Zindex {
    lowest,
    footer,
    searchModalBackDrop,
    searchModal,
    sidebar,
    header,
    modalBackDrop,
    modal
}

export default Zindex;
