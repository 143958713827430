// Import the functions you need from the SDKs you need
import ENV from 'expo-constants';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, createUserWithEmailAndPassword, connectAuthEmulator, User } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import firebase from 'firebase/app';
import 'firebase/auth';
import { getFirestore, collection, getDocs, Firestore } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: ENV.manifest!.extra!.FIREBASE_CONFIG_API_KEY,
    authDomain: ENV.manifest!.extra!.FIREBASE_CONFIG_AUTH_DOMAIN,
    projectId: ENV.manifest!.extra!.FIREBASE_CONFIG_PROJECT_ID,
    storageBucket: ENV.manifest!.extra!.FIREBASE_CONFIG_STORAGE_BUCKET,
    messagingSenderId: ENV.manifest!.extra!.FIREBASE_CONFIG_MESSAGING_SENDER_ID,
    appId: ENV.manifest!.extra!.FIREBASE_CONFIG_APP_ID,
    measurementId: ENV.manifest!.extra!.FIREBASE_CONFIG_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

// firebase authの初期化待ち関数
export const initFirebaseAuth = (): Promise<User> => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
            if (user) {
                // user オブジェクトを resolve
                resolve(user);
            } else {
                // user オブジェクトを resolve
                reject();
            }

            // 登録解除
            unsubscribe();
        });
    });
};

if (window.location.hostname === 'localhost' && ENV.manifest!.extra!.IS_EMULATOR_USE) {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectStorageEmulator(storage, 'localhost', 9199);
    // Firebaseのエミュレータ使用時に出てくる要素を非表示にする
    const cssText = `
        .firebase-emulator-warning {
            display: none;
        }
    `;
    // スタイル要素を作成して、CSSを設定する
    const styleElement = document.createElement('style');
    styleElement.textContent = cssText;
    // bodyの閉じタグの直前にスタイル要素を挿入する
    document.body.insertAdjacentElement('beforeend', styleElement);
}

export { auth, storage, db };
